import React, { useState } from "react";
import UniversalTable from "../Table/UniversalTable";
import {
    useAddPropertyDetailMutation,
    useClientPropertyManagementQuery,
    useDeletePropertyMutation,
    useGetPropertiesOnRiskQuery,
    usePropertyManagementQuery,
    useDeactivePropertyMutation,
    useDistressClientQuery,
    useNotOnboardedPropertyQuery,
} from "../../redux/slices/dashboard";
import PopupModal from '../Modal/PopupModal'
import {
    useChangeAssignRoleMutation,
    useGetClientsListQuery
} from "../../redux/slices/Setting";
import Skeleton from "../Skeleton";
import ReusableInput from "../InputField/ReusableInput";
import { FormProvider, useForm } from "react-hook-form";
import { FaArrowLeft } from "react-icons/fa";
import Button from "../button";
import Cookies from "js-cookie";
import AssigneeForm from "../AssigneeForm";
import { toast } from "react-toastify";
import NormalDropDown from '../DropDown/NormalDropDown'
import UniversalLoader from "../Loader/UniversalLoader";
import { useLocation } from "react-router-dom";
const NotOnboardedProperty = ({
    propertyforClient = false,
    hotelId,
    showAddProperty,


}) => {
    const [pageNumber, setPageNumber] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedPropertyId, setSelectedPropertyId] = useState(null);
    const [showDeactivateModal, setShowDeactivateModal] = useState(false);
    const [showActivateModel, setShowActivateModal] = useState(false)
    const [deactiveProperty] = useDeactivePropertyMutation();
    const [deleteProperty] = useDeletePropertyMutation();
    const { data, isLoading, isFetching, refetch } = useNotOnboardedPropertyQuery();
    const {
        data: clientManagementData,
        isLoading: clientManagementIsLoading,
        isFetching: clientManagementIsFetching,
        refetch: clientManagementRefetch,
    } = useClientPropertyManagementQuery(
        {
            pageNumber: pageNumber,
            clientId: hotelId,
        },
        { skip: propertyforClient ? false : true }
    );
    const totalPages =
        data?.data?.totalPages || clientManagementData?.data?.totalPages;
    const handlePrevious = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
        }
    };
    const handleNext = () => {
        if (pageNumber < totalPages) {
            setPageNumber(pageNumber + 1);
        }
    };
    const openModal = (propertyId, action) => {

        setSelectedPropertyId(propertyId);
        if (action == 'Delete') {

            setIsModalOpen(true);
        } else if (action === 'Deactivate') {
            setShowDeactivateModal(true)
        } else if (action === 'Activate') {
            setShowActivateModal(true)
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setShowDeactivateModal(false);
        setShowActivateModal(false)
        setSelectedPropertyId(null);
    };
    const handleDeactivateConfirm = () => {
        if (selectedPropertyId) {
            deactiveProperty({ hId: selectedPropertyId, isActive: false })
                .unwrap()
                .then((res) => {
                    toast.success(res?.message, { position: "bottom-right" });
                    refetch();
                    closeModal();
                })
                .catch((err) => {
                    toast.error(err?.data?.message, { position: "bottom-right" });
                });
        }
    }
    const handleActivateConfirm = () => {
        if (selectedPropertyId) {
            deactiveProperty({ hId: selectedPropertyId, isActive: true })
                .unwrap()
                .then((res) => {
                    toast.success(res?.message, { position: "bottom-right" });
                    refetch();
                    closeModal();
                })
                .catch((err) => {
                    toast.error(err?.data?.message, { position: "bottom-right" });
                });
        }
    }
    const handleDeleteConfirm = () => {
        if (selectedPropertyId) {
            deleteProperty({ hId: selectedPropertyId })
                .unwrap()
                .then((res) => {
                    closeModal();
                    toast.success(res?.message, { position: "bottom-right" });
                    refetch();
                })
                .catch((err) => {
                    toast.error(err?.data?.message, { position: "bottom-right" });
                });
        }
    };
    const location = useLocation();
    const { heading } = location.state || {};
    return (
        <div className="bg-lightThemeGradient min-h-[400px] rounded-lg dark:bg-darkThemeGradient text-darkThemeFontColor  p-4">
            {isLoading ||
                isFetching ||
                clientManagementIsLoading ||
                clientManagementIsFetching ? (
                <div className="w-full h-[400px]">
                    {" "}
                    <Skeleton />{" "}
                </div>
            ) : (
                <div className='min-h-[200px]  '>
                    <div className="flex h-full  justify-between">
                        <div>
                            <h1 className="text-2xl"> {heading ? heading : 'Properties'}  </h1>
                            <h2 className="text-sm">
                                {data?.data?.totalCount ||
                                    clientManagementData?.data?.totalCount}{" "}
                                properties
                            </h2>
                        </div>
                        {/* <h1
                            onClick={() => setShowAddPropertyForm(true)}
                            className="p-2 cursor-pointer bg-white rounded-lg h-fit  text-black text-sm"
                        >
                            Add Property
                        </h1> */}
                    </div>
                    <div className='min-h-full overflow-x-hidden '>
                        <UniversalTable
                            showActions={"properties"}
                            dataArray={
                                data?.data?.userDetail || clientManagementData?.data?.userDetail
                            }
                            refetch={refetch || clientManagementRefetch}
                            handleFunction={openModal} // Open modal on deactivate click
                        />

                        {totalPages > 1 && (
                            <div className="flex justify-center items-center space-x-4 mt-6">
                                <button
                                    onClick={handlePrevious}
                                    className={`px-4 py-2 text-white rounded ${pageNumber === 1
                                        ? "bg-gray-400 cursor-not-allowed"
                                        : "bg-blue-500 hover:bg-blue-700"
                                        }`}
                                    disabled={pageNumber === 1}
                                >
                                    Previous
                                </button>

                                <span className="text-lg text-white font-medium">
                                    Page {pageNumber} of {totalPages}
                                </span>

                                <button
                                    onClick={handleNext}
                                    className={`px-4 py-2 text-white rounded ${pageNumber === totalPages
                                        ? "bg-gray-400 cursor-not-allowed"
                                        : "bg-blue-500 hover:bg-blue-700"
                                        }`}
                                    disabled={pageNumber === totalPages}
                                >
                                    Next
                                </button>
                            </div>
                        )}
                        {isModalOpen && (
                            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                                <div className="bg-lightThemeGradient dark:bg-darkThemeGradient rounded-lg">
                                    <div className="bg-lightThemeGradient dark:bg-darkThemeGradient text-darkThemeFontColor  p-6 rounded-lg shadow-lg">
                                        <h2 className="text-lg font-semibold mb-4">Confirm Deactivation</h2>
                                        <p className="mb-4">Do you really want to deactivate this property?</p>
                                        <div className="flex justify-end space-x-4">
                                            <button
                                                onClick={closeModal}
                                                className="px-4 py-2 bg-gray-400 text-white rounded hover:bg-gray-500"
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                onClick={handleDeleteConfirm}
                                                className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
                                            >
                                                Yes, Deactivate
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}


                        {showDeactivateModal && <PopupModal closeModal={closeModal} handleFunction={handleDeactivateConfirm} heading='Comfirm Deactivate' subHeading={'Do you really want to Deactivate Property'} />}
                        {showActivateModel && <PopupModal closeModal={closeModal} handleFunction={handleActivateConfirm} heading='Comfirm Activate' subHeading={'Do you really want to Activate Property'} />}

                    </div>
                </div>
            )}
        </div>
    );
};

export default NotOnboardedProperty;
