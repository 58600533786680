import React from "react";
import Skeleton from "../Skeleton";
import { useClientPropertyRevenueQuery } from "../../redux/slices/dashboard";
import Chart from 'react-apexcharts';
import dataNotFound from '../../assets/icons/404/4.svg';

const PropertyRevenueBreakdown = (clientId) => {
    // Use the hook to fetch data from the API
    const { data: clientRevenueData, isLoading } = useClientPropertyRevenueQuery({clientId: clientId.clientId});

    // Parse the data
    const sourceData = clientRevenueData?.data?.properties || [];
    const totalSum = clientRevenueData?.data?.totalRevenue || 0;

    const series = sourceData.map(item => item.revenue);
    const labels = sourceData.map(item => item.propertyName);

    const chartOptions = {
        series: series,
        chart: {
            type: 'donut',
            width: '100%',
            height: '500px', // Adjust as needed
        },
        labels: labels,
        colors: ['#26c6da', '#80deea', '#004d66', '#b2ebf2', '#26a69a'], // Updated color scheme
        legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            formatter: function (val, opts) {
                return `<div className='text-white' style="width: 100%; color: #FFFFFF; text-align: left;">${val}: ${opts.w.globals.series[opts.seriesIndex]}</div>`;
            },
        },
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: true,
                        total: {
                            show: true,
                            label: 'Total',
                            formatter: function () {
                                return totalSum; // Make sure 'totalSum' is defined and accessible here
                            },
                            style: {
                                fontSize: '6px', // Adjust font size as needed
                                color: '#FFFFFF', // Ensure text color is white
                                fontWeight: 'bold', // Optional for emphasis
                            },
                        },
                    },
                },
            },
        },
    };
    
    
    

    return (
        <>
            {!isLoading ? (
                <div className="w-full h-full rounded-lg p-4 bg-lightThemeGradient dark:bg-darkThemeGradient flex flex-col items-center">
                    <div className="w-full flex justify-between mb-4">
                        <h1 className="font-semibold text-darkThemeFontColor ">Property Revenue Breakdown</h1>
                    </div>
                    <div className="w-full flex h-full items-center text-white justify-center">
                        {sourceData?.length > 0 ? (
                            <Chart
                                options={chartOptions}
                                series={chartOptions.series}
                                type="donut"
                                width={400}
                            />
                        ) : (
                            <div className="w-full flex bg-[#7c8ba130] flex-col items-center justify-start h-[90%] p-4 border-2 border-[#7C8BA1] rounded-lg m-2">
                                <img src={dataNotFound} className="h-60 w-60" alt="No Data" />
                                <div className="w-full flex justify-center">
                                    <p className="text-xl font-medium">No recent activities</p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <Skeleton />
            )}
        </>
    );
};

export default PropertyRevenueBreakdown;
