import React, { useEffect, useRef, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDrop, useDrag } from "react-dnd";
import {
  AiOutlineHolder,
  AiOutlineClockCircle,
  AiOutlineDollar,
} from "react-icons/ai";
import { RiDragDropLine } from "react-icons/ri";
import TrueFalseModal from "../Modal/TrueFalseModal";
import { formatCurrency } from "../../utils/FormatCurrency";
import { CiFilter, CiGrid42, CiSearch } from "react-icons/ci";
import { FaListUl } from "react-icons/fa6";
import NormalDropDown from "../DropDown/NormalDropDown";
import { Tooltip } from "react-tooltip";
import {
  useGetTaskFilterQuery,
  useGetTaskQuery,
  usePatchTaskStatusMutation,
} from "../../redux/slices/canabel";
import AddTask from "../Modal/AddTask";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { BiSort } from "react-icons/bi";
import { IoIosSearch } from "react-icons/io";
import DateTimePicker from "react-datetime-picker";

const KanabelContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { propTaskId, propModel} = location.state ||{};
  const [mode, setMode] = useState("Task Mode");
  const { data: taskFilter, refetch:getTaskFilterRefetch } = useGetTaskFilterQuery();
  const [searchInput, setSearchInput] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [assigneeList, setAssigneeList] = useState([]);
  const { data: getTask, refetch: getTaskRefetch } = useGetTaskQuery({
    searchType: searchInput,
    filterTypeStartDate: startDate,
    filterTypeEndDate: endDate,
    searchUserIds: assigneeList,
  });
  const [value, onChange] = useState();
  const [patchTaskStatus] = usePatchTaskStatusMutation();
  const [showFilter, setShowFilter] = useState(false);
  const [showAddTask, setShowAddTask] = useState();
  const [taskScreen, setTaskScreen] = useState();
  const refetchAllTask =()=>{
    getTaskFilterRefetch();
    getTaskRefetch();
  }
  //   const [metrics, setMetrics] = useState([
  //     {
  //       id: 1,
  //       name: "Plumbing Services",
  //       address: "345 Park Ave S",
  //       amount: 860.0,
  //       time: "1m",
  //     },
  //     {
  //       id: 2,
  //       name: "Roofing Repairs",
  //       address: "4517 Washington Ave",
  //       amount: 1520.0,
  //       time: "30m",
  //     },
  //     {
  //       id: 3,
  //       name: "Paint & Drywall Repairs",
  //       address: "255 Columbus Cir",
  //       amount: 2480.0,
  //       time: "2h",
  //     },
  //   ]);

  //   const [columnA, setColumnA] = useState(metrics);
  //   const [columnATotal, setColumnATotal] = useState();
  //   const [columnB, setColumnB] = useState([]);
  //   const [columnBTotal, setColumnBTotal] = useState();

  //   const [columnC, setColumnC] = useState([]);
  //   const [columnCTotal, setColumnCTotal] = useState();

  const [showModal, setShowModal] = useState(propModel || false);
  const [universalObject, setUniversalObject] = useState({});
  const [functionCall, setFunctionCall] = useState(null);
  const [taskId, setTaskId] = useState(propTaskId || null);
  let functionRunAgain = true;
  useEffect(() => {
    if (mode == "Task Mode") {
      setTaskScreen(2);
    } else if (mode == "Sub Task Mode") {
      setTaskScreen(3);
    } else {
      setTaskScreen(1);
    }
  }, [mode]);
  //   // for Caolculation of each column
  //   useEffect(() => {
  //     setColumnATotal(columnA.reduce((sum, metric) => sum + metric.amount, 0));
  //   }, [columnA]);
  //   useEffect(() => {
  //     setColumnBTotal(columnB.reduce((sum, metric) => sum + metric.amount, 0));
  //   }, [columnB]);
  //   useEffect(() => {
  //     setColumnCTotal(columnC.reduce((sum, metric) => sum + metric.amount, 0));
  //   }, [columnC]);

  const handleDrop = (item, targetColumn) => {
    // let sourceColumn;
    // let setSourceColumn;
    // let setTargetColumn;
    // if (item.columnId === "A") {
    //   sourceColumn = columnA;
    //   setSourceColumn = setColumnA;
    // } else if (item.columnId === "B") {
    //   sourceColumn = columnB;
    //   setSourceColumn = setColumnB;
    // } else if (item.columnId === "C") {
    //   sourceColumn = columnC;
    //   setSourceColumn = setColumnC;
    // }
    if (mode == "Task Mode" ) {
        
        item?.isSpecialTask && setTaskScreen(3)
      setTaskId(item?.taskId);
    } else if (mode === "Property Mode") {
      setTaskId(item?.hId);
      item?.isSpecialTask && setTaskScreen(3)
    } else {
      setTaskId(item?.taskId);
    }
    // if (targetColumn === "A") {
    //     setShowModal(true);
    //   setTargetColumn = setColumnA;
    // } else if (targetColumn === "B") {
    //   setShowModal(true);

    //   setTargetColumn = setColumnB;

    // } else if (targetColumn == "C") {
    //   setTargetColumn = setColumnC;
    // }

    setShowModal(true);

    setFunctionCall(() => () => {
      // Set functionCall as a function
      patchTaskStatus({ taskId: item.taskId, taskStatus: targetColumn })
        .unwrap()
        .then((res) => {
          getTaskRefetch();
          toast.success(res?.message, { position: "bottom-right" });
        })
        .catch((err) => {
          toast.error(err?.data?.message, { position: "bottom-right" });
        });
    });
  };
  useEffect(() => {
    // Function to group tasks by taskStatus
    function transformData(data) {
      const universalObject = {
        taskList: {},
        subTaskList: {},
        userList: {},
        propertyList: {},
        taskStatusList: {},
      };

      // Group taskList by taskStatus
      data?.taskList?.forEach((task) => {
        const status = task.taskStatus;

        // If the taskStatus key doesn't exist, create it
        if (!universalObject.taskList[status]) {
          universalObject.taskList[status] = [];
        }

        // Add task under the respective status group
        universalObject.taskList[status].push({
          taskId: task.taskId,
          title: task.title,
          taskStatus: task.taskStatus,
        });
      });

      // Group subTaskList by taskId
      data?.subTaskList?.forEach((subTask) => {
        const taskId = subTask.taskId;

        // If the taskId doesn't exist in subTaskList, create an entry for it
        if (!universalObject.subTaskList[taskId]) {
          universalObject.subTaskList[taskId] = {
            taskId: taskId,
            subTasks: [],
          };
        }

        // Add the subtask to the respective taskId's subTasks array
        universalObject.subTaskList[taskId].subTasks.push({
          title: subTask.title,
          taskStatus: subTask.taskStatus,
        });
      });

      // Copy userList directly to the universalObject (no grouping required)
      data.userList.forEach((user) => {
        universalObject.userList[user.userId] = user;
      });

      // Copy propertyList directly to the universalObject (no grouping required)
      data.propertyList.forEach((property) => {
        universalObject.propertyList[property.hId] = property;
      });

      // Copy taskStatusList directly to the universalObject (no grouping required)
      data.taskStatusList.forEach((status) => {
        universalObject.taskStatusList[status.statusId] = status;
      });

      return universalObject;
    }

    // Convert the data to the desired universalObject
    if (taskFilter?.data && functionRunAgain) {
      functionRunAgain = false;
      setUniversalObject(transformData(taskFilter?.data));
      //   debugger
      //   let finalobj=createUniversalObject(TaskDetail?.data)
    }

    // Output the result
  }, [taskFilter?.data]);
  
 const timeMap = new Map(getTask?.data.map((item)=>[item?.taskStatus,item?.expectedTime]))
const dropdownRef=useRef()
 const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowFilter(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div className="flex w-[90%] justify-center h-screen  flex-col p-4">
      {showAddTask && (
        <AddTask refetch={refetchAllTask}  setShowAddTask={setShowAddTask} />
      )} 
      <div className="w-full h-16 flex mt-20  items-center justify-between border-y-2 dark:border-white border-black">
        <div className="left flex gap-2 items-center">
          <button
            className="flex px-2 py-1 text-sm items-center gap-2  text-black bg-white rounded-lg"
            onClick={() => {
              navigate("/task-management/kanban");
            }}
          >
            <CiGrid42 /> Tasks{" "}
          </button>
          <div className="flex p-2 text-sm items-center gap-2 text-lightThemeFontColor dark:text-darkThemeFontColor">
            Total Task : {getTask?.totalTask ? getTask?.totalTask : "N/A"}
          </div>
          <div className="flex p-2 text-sm items-center gap-2 text-lightThemeFontColor dark:text-darkThemeFontColor">
            Total Pending Task :{" "}
            {getTask?.pendingTask ? getTask?.pendingTask : "N/A"}
          </div>
        </div>

        <div className="right flex gap-2 items-center">
          <div
            className="text-xs px-2 py-1 bg-white text-black rounded-md cursor-pointer"
            onClick={() => setShowAddTask(true)}
          >
            Add Task
          </div>
          <div className="flex p-2 w-32 items-center gap-2 text-darkThemeFontColor dark:text-lightThemeFontColor">
            <NormalDropDown
              label={"Select Mode"}
              selectedValue={mode}
              setSelectedValue={setMode}
              options={["Property Mode", "Task Mode", "Sub Task Mode"]}
              showBorder={false}
              customTextColor="text-black dark:text-white"
            />
          </div>

          <div ref={dropdownRef} className="relative">
            <div
              onClick={() => setShowFilter(!showFilter)}
              className="flex p-2 text-sm h-fit items-center filter dark:text-darkThemeFontColor text-black gap-2 rounded-lg cursor-pointer"
            >
              <CiFilter />
              Filter
            </div>
            {showFilter && (
              <div className="absolute top-10 right-0 bg-white text-lightThemeFontColor dark:text-darkThemeFontColor min-w-[400px] dark:bg-gray-800 shadow-lg rounded-lg p-4 z-10">
                <p>Date Range</p>
                <div className="flex gap-2 w-full ">
                  <div className="col-span-1 w-1/2">
                    <p>Start Date</p>
                    <div className="h-12 border border-black   text-black  dark:text-white dark:border-white flex mt-2 items-center rounded-lg ">
                      <DateTimePicker
                        onChange={setStartDate}
                        value={startDate}
                        format="y-MM-dd"
                        className="w-full relative rounded-lg p-2 "
                      />
                    </div>
                  </div>
                  <div className="col-span-1  w-1/2">
                    <p>Due Date</p>
                    <div className="h-12 border border-black  flex mt-2 items-center text-black dark:border-white dark:text-white rounded-lg ">
                      <DateTimePicker
                        onChange={setEndDate}
                        value={endDate}
                        format="y-MM-dd "
                        className="w-full relative rounded-lg  p-2 "
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-2">
                  <h1>Asignee</h1>
                  <div className="flex gap-2 ">
                    {taskFilter?.data?.userList?.map((item, key) => (
                      <div
                        onClick={() => {
                          setAssigneeList((prev) => [...prev, item?.userId]);
                        }}
                        className="h-6 w-6 flex items-start justify-start border cursor-pointer rounded-full overflow-hidden"
                      >
                        <img
                          src={item?.profileImg}
                          data-tooltip-id={`${item?.fullName}+${key}`}
                          data-tooltip-content={`${item?.fullName}`}
                          className="object-cover  rounded-full  h-full w-full"
                        />

                        <Tooltip id={`${item?.fullName}+${key}`} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="flex p-2 items-center gap-2 text-lightThemeFontColor dark:text-darkThemeFontColor">
            <IoIosSearch />
            <input
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              placeholder="Search Task"
              className="focus:outline-none w-[80px] text-sm bg-transparent dark:placeholder:text-white"
            />
          </div>
        </div>
      </div>

      <div className="flex w-full h-full overflow-x-scroll">
        <DndProvider backend={HTML5Backend}>
          {showModal && (
            <TrueFalseModal
              taskScreen={taskScreen}
              setTaskScreen={setTaskScreen}
              taskId={taskId}
              functionCall={functionCall}
              setShowModal={setShowModal}
              mode={mode}
              setTaskId={setTaskId}
              getTaskRefetch={getTaskRefetch}
              getTaskFilterRefetch={getTaskFilterRefetch}
            />
            // <AddTask setShowModal={setShowModal}/>
          )}

          {taskFilter?.data?.taskStatusList?.map((task, key) => {
            // If getTask or its data is undefined, set taskDetail to an empty array or undefined
            const taskDetail = getTask?.data
              ? getTask.data
                  .filter((item) => item.taskStatusId === task.statusId)
                  .map((item) => item.task)
              : [];

            // Use a loading state when getTask or taskDetail data is unavailable
            const isLoading = !getTask || !Array.isArray(taskDetail);
       
            return (
              <div className="max-h-[90%]  min-w-[300px]" key={task.statusId}>
                {isLoading ? (
                  <div className="animate-pulse h-[90%] flex space-x-4">
                    <div className="rounded-lg bg-gray-300 dark:bg-gray-700 h-full w-48"></div>
                  </div>
                ) : (
                  <Column
                    title={task?.status}
                    amount={timeMap.get(task.status)  ||'-'}
                    deals={task?.count}
                    items={taskDetail}
                    onDropItem={(item) => handleDrop(item, task?.status)}
                    columnId={task.statusId}
                    borderColor="#89FC00"
                    taskFilter={taskFilter}
                    mode={mode}
                  />
                )}
              </div>
            );
          })}
        </DndProvider>
      </div>
    </div>
  );
};

const Column = ({
  title,
  amount,
  deals,
  items,
  onDropItem,
  columnId,
  borderColor,
  taskFilter,
  mode,
}) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ItemType.ITEM,
    drop: (item) => {
      onDropItem(item, columnId);
    },
    canDrop: (item) => item.columnId !== columnId,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  const [timeString,setTimeString]=useState();

  useEffect(() => {

    if(isNaN(amount)){
setTimeString('--')
    }else{
    if (amount < 60) {
      // Seconds
      setTimeString(`${Math.round(amount)} seconds`);
    } else if (amount < 3600) {
      // Minutes
      const minutes = Math.round(amount / 60);
      setTimeString(`${minutes} ${minutes === 1 ? "minute" : "minutes"}`);
    } else {
      // Hours
      const hours = Math.round(amount / 3600);
      setTimeString(`${hours} ${hours === 1 ? "hour" : "hours"}`);
    }}
  }, [amount]);
  const isLoading = typeof items !== "object";

  return (
    <div
      ref={drop}
      style={{
        flex: 1,
        margin: "8px",
        height: "100%",
        // backgroundColor: "white",
        // border: `1px solid ${borderColor}`,
        borderRadius: "8px",
        padding: "8px",
      }}
      className="rounded-md   dark:bg-darkBgColor bg-black/30 dark:bg-[#FFFFFF30]"
    >
      <div className="border-b h-[15%] pb-4 mb-4 text-darkThemeFontColor">
        <h2 className="font-medium text-lg">{title}</h2>
        <div className="flex gap-2 items-end">
          <div className=" text-sm">{timeString}</div>
          <div className=" text-xs">{deals}</div>
        </div>
      </div>
      <div className="flex h-[80%] overflow-y-auto flex-col gap-3">
        {isLoading ? (
          <div className="animate-pulse flex space-x-4">
            <div className="rounded-lg bg-gray-300 dark:bg-gray-700 h-6 w-48"></div>
          </div>
        ) : (
          mode === "Sub Task Mode" &&
          items
            ?.flatMap((subArray) => subArray)
              .filter((item) => item.isSubTask || item?.isSpecialTask) // Only include items where isSubTask is true
            .map((item) => {
              return (
                <DragItem
                  key={item.taskId} // Use taskId as the unique key
                  item={item}
                  columnId={columnId}
                  onDropItem={onDropItem}
                  borderColor={borderColor}
                />
              );
            })
        )}

        {mode === "Task Mode" &&
          taskFilter?.data?.taskList?.map((taskData) => {
            // Filter items that belong to the current taskData

            const relevantItems = items
              ?.flatMap((subArray) => subArray)
              .filter(
                (item) =>
                  item.taskId === taskData.taskId ||
                  item.parentTaskId === taskData.taskId
              );
            if (relevantItems?.length === 0) return null;
            return (
              <div
                // style={{ minHeight: `${relevantItems?.length * 90}px` }}
                className=""
                key={taskData.taskId}
              >
                {/* Render Task Title */}
                <div className="color-white">{taskData.title}</div>

                {/* Render total count of DragItems */}
                {/* <div>Total Drag Items: {relevantItems.length}</div> */}

                {/* Render all associated DragItems */}
                {relevantItems?.map((item) => (
                  <DragItem
                    key={item.taskTypeId || item.taskId}
                    item={item}
                    onDropItem={onDropItem}
                    columnId={columnId}
                    borderColor={borderColor}
                  />
                ))}
              </div>
            );
          })}

        {mode === "Property Mode" &&
          taskFilter?.data?.propertyList?.map((property) => {
            const relevantItems = items
              ?.flatMap((subArray) => subArray)
              .filter((item) => (item.hId === property.hId && !item?.isSubTask));

            if (relevantItems?.length === 0) return null;

            return (
              <div key={property.hId}>
                {/* Render Property Name */}
                <div className="color-white">{property.hotelName}</div>

                {/* Render total count of DragItems */}
                {/* <div>Total Drag Items: {relevantItems.length}</div> */}

                {/* Render all associated DragItems */}
                {relevantItems?.map((item) => (
                  <DragItem
                    key={item.taskTypeId || item.taskId}
                    item={item}
                    onDropItem={onDropItem}
                    columnId={columnId}
                    borderColor={borderColor}
                  />
                ))}
              </div>
            );
          })}
      </div>
      {isOver && canDrop && (
        <div className="w-full relative top-0 inset-0 h-full flex justify-center items-center bg-gray-50">
          <div className="flex gap-2 text-gray-600 items-center text-sm">
            <RiDragDropLine size={20} /> Drop inside {title}
          </div>
        </div>
      )}
    </div>
  );
};

const DragItem = ({ item, columnId, borderColor, onDropItem, taskHeading }) => {
  const [{ isDragging }, drag] = useDrag({
    type: ItemType.ITEM,
    item: { ...item, columnId },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  return (
    <div
      onClick={() => onDropItem(item, columnId)}
      ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
        padding: "8px",
        marginBottom: "8px",
        cursor: "move",
        // backgroundColor: "#fff",
        // boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.4)",
        borderRadius: "8px",
        // border: `1px solid #E0E0E0`,
      }}
      className="rounded-md bg-lightThemeGradient dark:bg-darkThemeGradient   text-darkThemeFontColor text-sm "
    >
      <div className="flex justify-between gap-2 items-center mb-2">
        <div className="font-medium w-1/2  text-base">{item.title}</div>
        <div className=" w-1/2 text-end text-xs">{item.deadlineDate}</div>
      </div>
      <div className="text-xs mb-2">{item.owner}</div>
      <div className="flex items-center justify-between">
        <div className="flex gap-2 items-center text-xs ">
          <AiOutlineHolder size={18} />
          <AiOutlineDollar size={18} />
          {item?.taskComments}
          <AiOutlineClockCircle size={18} />
          {item?.attachments}
        </div>
        <div className="font-medium ">{item.amount}</div>
      </div>
    </div>
  );
};

const ItemType = {
  ITEM: "ITEM",
};

export default KanabelContainer;
