import React, { useState, useEffect } from "react";
import { BsPlus } from "react-icons/bs";
import { Tooltip } from "react-tooltip";
import { FaUserLarge } from "react-icons/fa6";
const OtherUserComponent = ({
  data,
  selectedIndex,
  index,
  setSelectedIndex,
  otherUsersearchText,
  handleAssignToggle,
  item,
  handleAssignSave,
  isLoadingAssigining,
  setOtherUserSearchText,
}) => {
  const [filteredUsers, setFilteredUsers] = useState(data);
  
  // Effect to filter users based on the search text
  useEffect(() => {
    const filtered = data?.filter(user =>
      user?.fullName?.toLowerCase().includes(otherUsersearchText?.toLowerCase())
    );
    setFilteredUsers(filtered);
  }, [data, otherUsersearchText]);

  const handleInputChange = (e) => {
    setOtherUserSearchText(e.target.value);
  };

  return (
    <div className="w-32 flex relative items-center justify-center">
      {filteredUsers?.slice(0, 2).map((mapValue, key) => (
        <React.Fragment key={mapValue.userId}>
          <img
            data-tooltip-id={`${mapValue?.fullName}+${key}`}
            data-tooltip-content={`${mapValue?.fullName}`}
            title={`${mapValue?.fullName}`}
            src={mapValue?.profileImg || 'https://e7.pngegg.com/pngimages/178/595/png-clipart-user-profile-computer-icons-login-user-avatars-monochrome-black.png'}
            onError={(e) => {
              e.target.onerror = null; // Prevents infinite loop if the dummy image fails
              e.target.src = 'https://e7.pngegg.com/pngimages/178/595/png-clipart-user-profile-computer-icons-login-user-avatars-monochrome-black.png'; // Fallback URL when the original fails
            }}
            className={`h-6 w-6 ${key !== 0 ? "-mx-2" : ""} border-2 border-black rounded-full`}
          />
          <Tooltip id={`${mapValue?.fullName}+${key}`} />
        </React.Fragment>
      ))}
      <div
        onClick={
          selectedIndex === index
            ? () => setSelectedIndex(null)
            : () => setSelectedIndex(index)
        }
        className="p-1 cursor-pointer bg-white/90 rounded-full border-2 border-black text-black"
      >
        <BsPlus />
      </div>
      {selectedIndex === index && (
        <div
        className={`absolute right-0 pt-2 bg-white px-2 py-1 z-20 rounded-md w-[210px] 
          ${(index === 0) 
            ? '-top-20' 
            : ((index + 1) % 5 === 0 || (index + 1) % 4 === 0 || (index + 1) % 3 === 0) 
              ? '-top-[210px]' 
              : 'top-8'
          }`}
                >
          <div className="w-[100%] h-auto max-h-[150px] overflow-y-scroll">
            <input
              type="text"
              value={otherUsersearchText}
              className="text-black border rounded-md px-2"
              placeholder="Search"
              onChange={handleInputChange}
            />
            {filteredUsers?.map((assign, key) => (
              <div className="flex justify-start my-2 items-center gap-2" key={assign.userId}>
                <input
                  type="checkbox"
                  checked={assign?.isAssigned}
                  onChange={() => handleAssignToggle(index, key, item?.hId)} // Toggle assignment status
                  id={assign?.fullName}
                />
                <div className="w-fit">
                  <img
                    data-tooltip-id={`${assign?.fullName}+${key}`}
                    data-tooltip-content={`${assign?.fullName}`}
                    title={`${assign?.fullName}`}
                    src={assign?.profileImg || "https://e7.pngegg.com/pngimages/178/595/png-clipart-user-profile-computer-icons-login-user-avatars-monochrome-black.png"}
                    onError={(e) => {
                      e.target.onerror = null; // Prevents infinite loop if the dummy image fails
                      e.target.src = "https://e7.pngegg.com/pngimages/178/595/png-clipart-user-profile-computer-icons-login-user-avatars-monochrome-black.png"; // Fallback URL when the original fails
                    }}
                    className="w-6 h-6 rounded-full"
                  />
                </div>
                <label
                  htmlFor={assign?.fullName}
                  className="hover:text-black duration-150 ease-in-out transition-all text-gray-500 cursor-pointer min-w-fit"
                >
                  {assign?.fullName}
                </label>
              </div>
            ))}
          </div>
          <div className="float-end flex items-center gap-2">
            <div
              onClick={() => setSelectedIndex(null)}
              className="cursor-pointer bg-red-100 w-fit px-2 py-1 mt-2 mb-1 rounded-md text-end text-xs text-red-500"
            >
              Cancel
            </div>
            <div
              onClick={handleAssignSave}
              className="cursor-pointer text-blue-500 w-fit px-2 py-1 mt-2 mb-1 rounded-md text-end text-xs bg-blue-100"
            >
              {isLoadingAssigining ? "Updating..." : "Update"}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OtherUserComponent;
