import React, { useState, useEffect, useRef } from "react";

import gsap from "gsap";
import ProfilePicture from "../../assets/icons/Revens/ProfilePicture.png";
import { GrNotification } from "react-icons/gr";
import getToken from "../../utils/getToken";
import Calendar from "../calendar";
import { FaCalendarAlt } from "react-icons/fa";
import { TfiArrowTopRight } from "react-icons/tfi";
import { useGSAP } from "@gsap/react";

import sunpic from "../../assets/navbar/darkLightToggle/sun.svg";
import sunbg from "../../assets/navbar/darkLightToggle/sunBg.svg";
import moon from "../../assets/navbar/darkLightToggle/moon.svg";
import moonbg from "../../assets/navbar/darkLightToggle/moonBg.svg";
import ratexDark from "../../assets/bgImage/rateXdarkBg.jpeg";
import ratexLight from "../../assets/bgImage/rateXlightBg.png";

import rworldLogoDark from "../../assets/logo/rWorldDarkLogo.svg";
import rworldLogoLight from "../../assets/logo/rWorldLightLogo.svg";
import { CgProfile } from "react-icons/cg";
import { IoMdLogOut } from "react-icons/io";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useGetHeaderUtilsQuery, useGetUserTaskQuery } from "../../redux/slices/dashboard";
import TaskProgress from "../Header/TaskProgress";

const Header = ({ setSideBarOpen, sideBarOpen }) => {
  const { data: getUserTask, refetch } = useGetUserTaskQuery();
  const [showProfileDropdown, setShowProfileDropdown] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const options = ["Option 1", "Option 2", "Option 3"];
  const [contentVisible, setContentVisible] = useState(true); // State for content visibility
  const profileImg = Cookies.get('profileImg'); 
  const [customDate, setCustomDate] = useState(new Date());
const {data:headerUtilsData}=useGetHeaderUtilsQuery();
const currentTime = new Date();
currentTime.setMinutes(0, 0, 0); // Set minutes, seconds, and milliseconds to zero to round off to the hour

const hours = currentTime.getHours();
const isPM = hours >= 12;
const displayHour = hours % 12 || 12; // Convert 24-hour format to 12-hour format

const timeString = `${displayHour}:00 ${isPM ? 'pm' : 'am'}`;
const endTimeString =`${(displayHour+2) % 12}:00 ${displayHour+2>=12 ? 'pm' : 'am'}`
  const token = getToken();

  const handleNavigate = (hId) => {
    window.location.href = `http://localhost:3000/ratex/token:${token}/hId:${hId}`;
  };

  const [theme, setTheme] = useState(
    localStorage.getItem("theme") ||
      (window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "dark")
  );

  const body = document.body;
  useEffect(() => {
    if (theme === "dark") {
      document.querySelector(".themeToggle").classList.remove("dark");

      body.style.backgroundImage = `url(${ratexLight})`;
      body.style.backgroundRepeat = `no-repeat`;
      body.style.backgroundSize = "cover";
      localStorage.setItem("theme", "dark");
    } else {
      document.querySelector(".themeToggle").classList.add("dark");
      body.style.backgroundImage = `url(${ratexDark})`;
      localStorage.setItem("theme", "light");
    }
  }, [theme]);

  const toggleTheme = () => {
    setTheme(theme === "dark" ? "light" : "dark");
  };

  useGSAP(() => {
    const tl = gsap.timeline({
      onComplete: () => {
        setContentVisible(true);
      },
    });

    tl.from(".notch", {
      width: 0,
      duration: 1.8,
      ease: "power2.out",
    })

      .from(".opi", {
        opacity: 0,
        x: -100,
        duration: 1,
        stagger: 0.3,
        ease: "expo.inOut",
      }); // overlapping with previous animation
    tl.from(
      ".innernotch",
      {
        width: 0,
        duration: 0.5,
        ease: "expo.in",
      },
      "-=150%"
    );
    return () => {
      tl.kill();
    };
  }, []);

  const divRef = useRef(null);

  useEffect(() => {
    // Function to handle clicks outside the div
    const handleClickOutside = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setShowProfileDropdown(false);
      }
    };

    // Add event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const navigate = useNavigate();
  return (
    <div className="w-[100%] sticky top-0 z-50  h-[80px] flex flex-row justify-between   px-3 py-1 items-center">
      <div className="w-[20%] flex justify-between gap-4">
        <div className="ml-7 flex gap-4">
          {/* <div
            onClick={() => {
              setSideBarOpen((prev) => !prev);
            }}
            className="cursor-pointer"
          >
            {sideBarOpen ? (
              <IoCloseSharp size={20} />
            ) : (
              <GiHamburgerMenu size={20} />
            )}
          </div> */}
          <div className="cursor-pointer" onClick={() => navigate("/")}>
            {theme === "light" ? (
              <img className="" width={100} src={rworldLogoDark} alt="Logo" />
            ) : (
              <img className="" width={100} src={rworldLogoLight} alt="Logo" />
            )}
          </div>
        </div>
      </div>
      <div className="w-[60%]  flex items-center px-4 justify-evenly h-[90%] text-textLight dark:text-textDark rounded-3xl notch">
        {contentVisible && (
          <div className="flex   w-full items-center px-4 justify-evenly h-[90%] text-textDark dark:text-textLight bg-navbarDark dark:bg-navbarLight rounded-3xl gap-4">
            <div className="flex items-center justify-center">
              <div className="overflow-hidden opi">
                <h1 className="text-sm font-[500]">Your Schedule</h1>
              </div>
              <div className="mx-4 relative flex  text-white items-center h-full opi">
                <Calendar
                  selectedDate={customDate}
                  setSelectedDate={setCustomDate}
                  enableDaysFromNow={60}
                  backgroundNone={true}
                  labelWhite={true}
                  showIconBackground={true}
                  icon={<FaCalendarAlt size={12} />}
                  height={"80"}
                />
              </div>
            </div>
            <div className="overflow-hidden h-[70%] w-[70%] innernotch">
              <div className="w-full flex items-center justify-center text-black px-4 h-full  text-sm bg-[#9aff77] rounded-2xl content">
                <div className=" border-r px-2 border-dividerGray w-28 font-[500] text-[12px]">
                 {timeString}
                </div>
                <div className="relative flex-grow mx-2 border flex items-center">

                    {headerUtilsData?.data?.meeting && headerUtilsData?.data?.meeting?.map((meet)=>

                        <TaskProgress startTime={meet?.startTime} endTime={meet?.endTime} />
                        )}

{headerUtilsData?.data?.TaskDetail && headerUtilsData?.data?.TaskDetail?.map((meet)=>

<TaskProgress startTime={meet?.startTime} endTime={meet?.endTime} />
)}
              
              </div>

                {/* <div className="w-[50%] rounded-xl border border-dividerGray h-[80%] mx-2 bg-[#9aff77]/40 "></div> */}
                <div className=" border-l px-2 border-dividerGray w-28 font-[500] text-[12px]">
               {endTimeString}
                </div>
              </div>
            </div>
            <div className="overflow-hidden opi">
              <div className="arrow">
                <TfiArrowTopRight />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="w-[20%]">
        <div className="flex border-l-2 px-3 border-white h-full justify-center items-center gap-5 cursor-pointer">
          <div
            onClick={toggleTheme}
            className={`relative w-16 h-8  rounded-full cursor-pointer overflow-hidden transition-all duration-300 ${
              theme === "light" ? "bg-transparent" : "bg-transparent"
            }`}
            style={{
              backgroundImage: `url(${theme === "light" ? moonbg : sunbg})`,
              backgroundSize: "cover",
            }}
          >
            {/* Sun/Moon Image */}
            <div
              className={`absolute w-6 h-6 flex justify-center items-center rounded-full top-[5px] transition-transform duration-300 ${
                theme === "light"
                  ? "translate-x-[0.1rem]"
                  : "translate-x-[2.5rem]"
              }`}
              style={{
                backgroundImage: `url(${theme === "light" ? moon : sunpic})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            ></div>
          </div>
          <div
            className="notification relative"
            onClick={() => navigate("/notification")}
          >
            <div className="z-[2] filter dark:invert-0 invert">
              <GrNotification color="white" />
            </div>
            <div className="absolute -top-2 bg-red-400 rounded-full px-1 z-0 text-[10px] -right-3">
              {headerUtilsData?.data?.unreadNotification}
            </div>
          </div>
          <div>
            <div
              ref={divRef}
              onClick={() => setShowProfileDropdown(!showProfileDropdown)}
              className="h-7 w-7 bg-orange-400  rounded-full border-2 overflow-hidden"
            >
              <img
                src={profileImg || ProfilePicture}
                alt=""
                className="w-14 h-14  object-cover"
              />
              {showProfileDropdown && (
                <div className="absolute top-[70px] z-50  right-12 flex text-sm flex-col rounded-md text-black bg-white">
                  <div onClick={()=>navigate('/profilePage')} className="border-b hover:bg-gray-100 rounded-md transition-all cursor-pointer ease-in-out duration-150 flex items-center gap-2 py-2 px-6">
                    <CgProfile />
                    Profile
                  </div>
                  <div
                    onClick={() => {
                      // Get all cookies

                      const allCookies = Cookies.get();

                      // Loop through the cookies and delete each one
                      Object.keys(allCookies).forEach((cookieName) => {
                        if(cookieName!=='recentLogin'){
                            Cookies.remove(cookieName);
                        }
                      });
                      localStorage.clear();
                      window.location.reload(); 
                      // navigate("/");
                      
                    }}
                    className="px-6 flex hover:bg-gray-100 rounded-md cursor-pointer transition-all ease-in-out duration-150 items-center gap-2 py-2"
                  >
                    <IoMdLogOut />
                    Logout
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
