import React, { useState, useEffect, useRef } from "react";
import UniversalTable, {
  DataNotFound,
} from "../../component/Table/UniversalTable";
import {
  useAssignServicesMutation,
  useGetFinalLeadQuery,
  useGetServicesQuery,
} from "../../redux/slices/CSO/dashboard";
import {
  useAddClientTransactionMutation,
  useEditClientTransactionMutation,
  useGetClientTransactionQuery,
} from "../../redux/slices/Finance/index";

import NormalDropDown from "../../component/DropDown/NormalDropDown";
import ReusableDropDown from "../../component/DropDown/DropDown";
import Select from "react-select";
import { toast } from "react-toastify";
import Skeleton from "../../component/Skeleton";
import AddCustomerForm from "./addCustomerForm";
import UniversalModal from "../../component/Modal/UniversalModel";
import { MdOutlineSort } from "react-icons/md";
import { CiFilter } from "react-icons/ci";
import { IoClose, IoSearch } from "react-icons/io5";
import UniversalLoader from "../../component/Loader/UniversalLoader";
import UniversalModel from "../../component/Modal/UniversalModel";
import Button from "../../component/button";
function separateCamelCase(str) {
  return str
    .replace(/([a-z])([A-Z])/g, "$1 $2") // Insert a space before each uppercase letter
    .replace(/^./, (match) => match.toUpperCase()); // Capitalize the first letter
}

const removeKeys = (array) => {
  return array.map((item) => {
    return Object.fromEntries(
      Object.entries(item).filter(([key]) => key !== "leadId")
    );
  });
};

const CSO_Dashboard = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [filterComplition, setFilterComplition] = useState();
  const [showFilter, setShowFilter] = useState(false);
  const [showSort, setShowSort] = useState(false);
  const [filterStatus, setFilterStatus] = useState();
  const [filterBudget, setFilterBudget] = useState();
  const [searchType, setSearchType] = useState("");
  const [selectedLeadId, setSelectedLeadId] = useState();
  const {
    data: getFinalData,
    isLoading,
    isFetching,
    isError,
    refetch: refetchFinalLead,
  } = useGetFinalLeadQuery({
    pageNumber: pageNumber,
    searchType: searchType,
    budgetSort: filterBudget,
  });
  const [editTransactionApi ,{isLoading:editClientTransactionIsLoading}]=useEditClientTransactionMutation()
  const { data: getTransactionDetail } = useGetClientTransactionQuery({
    leadId: selectedLeadId,
  });
  const [showLoader, setShowLoader] = useState(false);
  const [selectedOption, setSelectedOption] = useState();
  const [selectedClientId, setSelectedClientId] = useState();

  const [currentIndex, setCurrentIndex] = useState();
  const { data: getServices, refetch } = useGetServicesQuery();
  const filterArray = getFinalData?.data;
  const [actualSelectedOptions, setActualSelectedOption] = useState([]);
  const [OpenTransactionModel, setOpenTransactionModel] = useState(false);
  const [viewTransactionsModel, setViewTransactionsModel] = useState(false);
  const [editTransaction, setEditTransaction] = useState(false);
  const [oneTransactionDetail, setOneTransactionDetail] = useState();
  const [imageFile, setImageFile] = useState();
  const [transactionId, setTransactionId] = useState("");
  const [editTransactionId, setEditTransactionId] = useState("");
  const [editImageFile, setEditImageFile] = useState();
  const dropdownRef = useRef();
  const dropDownRef1 = useRef();
  const [isSearchVisible, setSearchVisible] = useState(false);

  const toggleSearch = () => {
    setSearchVisible(!isSearchVisible);
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowFilter(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const sortHandleClickOutside = (event) => {
    if (dropDownRef1.current && !dropDownRef1.current.contains(event.target)) {
      setShowSort(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", sortHandleClickOutside);
    return () => {
      document.removeEventListener("mousedown", sortHandleClickOutside);
    };
  }, []);
  useEffect(() => {
    // Initialize selected options based on API data
    if (getFinalData?.data) {
      const initialSelectedOptions = filterArray?.map((item) => {
        return item?.servicesAssigned?.length > 0 ? item.servicesAssigned : [];
      });

      filterArray.map((_, index) => {
        const newSelected = filterArray.servicesAssigned?.map((item) => {
          return {
            serviceId: item?.value,
          };
        });
        const obj = {
          leadId: getFinalData?.data[index]?.leadId,
          services: newSelected,
        };
        setSelectedOption(obj);
      });

      setActualSelectedOption(initialSelectedOptions);
    }
  }, [getFinalData?.data]);

  const servicesOption =
    getServices?.data &&
    getServices?.data?.map((item) => {
      return {
        label: item?.serviceName,
        value: item?.serviceId,
      };
    });
  const [assignServices] = useAssignServicesMutation();
  const [addClientTransaction] = useAddClientTransactionMutation();
  const [selectedAssignedServices, setSelectedAssignedServices] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const totalPages = getFinalData?.totalPages;
  const handleNext = () => {
    if (pageNumber < totalPages) {
      setPageNumber(pageNumber + 1);
    }
  };
  const handlePrevious = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file));
      setImageFile(file);
    }
  };
  const handleEditTransactionImage = (event) => {
    const file = event.target.files[0];
    if (file) {
      setOneTransactionDetail((prev) => ({
        ...prev,
        transactionImage: URL.createObjectURL(file),
      }));
      setImageFile(file);
    }
  };

  const handleSelectChange = (selected, index) => {
    // `selected` is an array of selected options or null if no options are selected
    const newSelected = selected?.map((item) => {
      return {
        serviceId: item?.value,
      };
    });
    const obj = {
      leadId: getFinalData?.data[index]?.leadId,
      services: newSelected,
    };
    setSelectedOption(obj);
    const updatedSelectedOptions = [...actualSelectedOptions];
    updatedSelectedOptions[index] = selected; // Update the selected options for the specific item
    setActualSelectedOption(updatedSelectedOptions);
    // setSelectedOption((prev)=>[...prev,selected])
    
  };

  const handleVave = () => {
    assignServices(selectedOption)
      .unwrap()
      .then((res) => {
        refetch();
        toast.success("Services assigned successfully!", {
          position: "bottom-right",
        });
      })
      .catch((err) => {
        toast.error("Something went wrong!", {
          position: "bottom-right",
        });
      });
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent", // Make the background transparent
      border: "1px solid #ccc",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid #aaa", // Change border color on hover
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "rgba(255, 255, 255, 0.9)", // Optional: set a semi-transparent background for the dropdown menu
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "rgba(0, 123, 255, 0.2)", // Optional: set a background for selected values
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "#fffff", // Optional: change label color
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "#007bff", // Optional: change remove button color
      ":hover": {
        backgroundColor: "rgba(255, 0, 0, 0.1)", // Optional: add hover effect on remove button
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "white", // Set the placeholder color to white
      fontSize: "12px",
    }),
  };
  function getGreeting() {
    const now = new Date();
    const hours = now.getHours();

    if (hours >= 0 && hours < 12) {
      return "Good morning";
    } else if (hours >= 12 && hours < 17) {
      return "Good afternoon";
    } else if (hours >= 17 && hours < 21) {
      return "Good evening";
    } else {
      return "Good night";
    }
  }

  const handleTransaction = () => {
    setShowLoader(true);
    addClientTransaction({
      transactionId: transactionId,
      img: imageFile,
      clientId: selectedClientId,
      leadId: selectedLeadId,
    })
      .unwrap()
      .then((res) => {
          setOpenTransactionModel(false);
        toast.success(res?.message, { position: "bottom-right" });
        refetch();
        setShowLoader(false);
        // getTaskRefetch();
        refetchFinalLead();
      })
      .catch((err) => {
        setShowLoader(false);
        toast.error(err?.data?.message, { position: "bottom-right" });
      });
  };
  const [isAddCustomerModalOpen, setIsAddCustomerModalOpen] = useState(false);
const handleEditTransaction =()=>{
    const apiData ={
        leadId:selectedLeadId,
        transactionId:oneTransactionDetail?.transactionId,
        transactionImage:imageFile
    }
    editTransactionApi(apiData).unwrap().then((res)=>{
        setEditTransaction(false)
        setViewTransactionsModel(false);
        toast.success(res?.message, { position: "bottom-right" });
        refetchFinalLead();
    }).catch((err)=>{
        toast.error(err?.data?.message, { position: "bottom-right" });
        
    })
}
  return (
    <div className="pt-20 mx-[100px] w-[100%] ">
      {isAddCustomerModalOpen && (
        <AddCustomerForm
          showModal={isAddCustomerModalOpen}
          setShowModal={setIsAddCustomerModalOpen}
          refetch={refetchFinalLead}
        />
      )}
      <UniversalLoader loader={showLoader ||editClientTransactionIsLoading} />
      <div className="text-xl my-8 dark:text-white text-black">
        <h1 className="text-[30px] font-bold">{getGreeting()}, Partner!</h1>
        <p className="text-[18px] font-semibold">Welcome to CFO Dashboard</p>
      </div>
      <div className="relative pb-14 mt-4 bg-gradient-to-r dark:from-[#3396E2BD] from-[#060B28BD] to-[#A0AEC0] p-4 dark:to-[#6679D3B5] rounded-md">
        <div className=" flex flex-row justify-between mb-[16px]">
          <div className="flex flex-col">
            <p className="text-[20px] font-semibold">Customer Details</p>
            <p className="text-[14px] font-semibold">
              These are the list of all the qualified leads converted into
              customers
            </p>
          </div>
          <div className="flex items-center ">
            <div ref={dropDownRef1} className="relative">
              <div
                onClick={() => setShowSort(!showFilter)}
                className="flex p-2 text-sm h-fit items-center filter text-darkThemeFontColor  gap-2 rounded-lg cursor-pointer"
              >
                <MdOutlineSort />
                Sort
              </div>
              {showSort && (
                <div className="absolute top-10 right-0 flex flex-col tex gap-2  text-darkThemeFontColor min-w-[400px] bg-gray-800 shadow-lg rounded-lg p-4 z-50">
                  <div className="w-full">
                    <p>Budget</p>
                    <div className="h-12 ">
                      <NormalDropDown
                        selectedValue={filterBudget}
                        setSelectedValue={setFilterBudget}
                        options={["Low to high", "High to low"]}
                        label={"Select value"}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/* <div ref={dropdownRef} className="relative ">
              <div
                onClick={() => setShowFilter(!showFilter)}
                className="flex p-2 text-sm h-fit items-center filter text-darkThemeFontColor  gap-2 rounded-lg cursor-pointer"
              >
                <CiFilter />
                Filter
              </div>
              {showFilter && (
                <div className="absolute top-10 right-0 flex flex-col gap-4  text-darkThemeFontColor min-w-[400px] bg-gray-800 shadow-lg rounded-lg p-4 z-50">
                  <div className="w-full">
                    <p>Status</p>
                    <div className="h-12">
                      <NormalDropDown
                        selectedValue={filterStatus}
                        setSelectedValue={setFilterStatus}
                        options={["Active", "Inactive"]}
                        label={"Select value"}
                      />
                    </div>
                  </div>

                  <div className="w-full">
                    <p>Completion</p>
                    <div className="h-12">
                      <NormalDropDown
                        selectedValue={filterComplition}
                        setSelectedValue={setFilterComplition}
                        options={["Onboarded", "Not Onboarded"]}
                        label={"Select value"}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div> */}
            <div className="flex items-center space-x-2">
              {/* Search Icon */}
              <div
                onClick={toggleSearch}
                className="text-white  hover:text-gray-900"
              >
                {isSearchVisible ? <IoClose /> : <IoSearch />}
              </div>

              {/* Search Input */}
              {isSearchVisible && (
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchType}
                  onChange={(e) => {
                    setSearchType(e.target.value);
                  }}
                  className="border text-black border-gray-300 rounded-lg py-1 px-2 w-48 focus:outline-none focus:border-blue-500 transition duration-150 ease-in-out"
                />
              )}
            </div>
            <button
              className="bg-blue-600 ml-4 px-[10px] py-[15px] rounded-lg text-[15px] font-semibold"
              onClick={() => {
                setIsAddCustomerModalOpen(true);
              }}
            >
              {" "}
              Add Customer
            </button>
          </div>
        </div>
        {!isLoading ? (
          <div>
            <div className="flex justify-between border-b border-[#56577A] p-2">
              {getFinalData?.data &&
                getFinalData?.data[0] &&
                Object.keys(getFinalData?.data[0])
                  ?.map((item) => {
                    if (
                      item !== "leadId" &&
                      item !== "clientId" &&
                      item !== "isTransaction"
                    ) {
                      return (
                        <div className="w-[16%] text-sm">
                          {separateCamelCase(item)}
                        </div>
                      );
                    }
                    return null; // Return null or remove this line to exclude the unwanted keys
                  })
                  .filter(Boolean)}
              <div className="w-[16%] text-sm">Action</div>
            </div>
            <div className="flex justify-start min-h-auto max-h-[500px] overflow-auto  flex-col">
              {getFinalData?.data ? (
                filterArray?.map((item, index) => {
                  return (
                    <div className="flex justify-between font-semibold border-b border-[#56577A] items-center p-2">
                      <div className="w-[16%]">{item?.clientName}</div>
                      <div className="w-[16%]">{item?.hotelName}</div>
                      <div className="w-[16%]">{item?.budget}</div>

                      <div className="w-[16%]">{item?.servicesPitched}</div>
                      <div className="w-[16%]">
                        <Select
                          onChange={(newValue) => {
                            handleSelectChange(newValue, index);
                            const newSelected = newValue?.map((item) => {
                              return {
                                serviceId: item?.value,
                              };
                            });
                            const obj = {
                              leadId: getFinalData?.data[index]?.leadId,
                              services: newSelected,
                            };

                            assignServices(obj);
                          }}
                          isMulti
                          maxMenuHeight={200}
                          className="w-[90%]"
                          menuPortalTarget={document.querySelector("body")}
                          options={servicesOption}
                          styles={customStyles}
                          value={actualSelectedOptions[index] || []} // Use state for selected options
                        />
                      </div>
                      <div
                        className="w-[16%] cursor-pointer hover:underline duration-150 ease-in-out transition-all"
                        // onClick={()=>{ setSelectedClientId(item?.clientId); item?.isTransaction? setOpenTransactionModel(true) : handleVave()}}
                      >
                        {/* { item?.isTransaction? 'Enter detail' : 'Assign'} */}

                        <div className="relative w-32 flex flex-col items-center justify-center cursor-pointer ">
                          <Select
                            onChange={(x) => {
                              if (x?.value === "Enter_detail") {
                                setSelectedClientId(item?.clientId);
                                setSelectedLeadId(item?.leadId);
                                setOpenTransactionModel(true);
                                // navigate(`/client/${item?.clientId}`);
                              } else if (x?.value === "Assign") {
                                setSelectedClientId(item?.clientId);
                                handleVave();

                                // handleFunction(item?.clientId,'Deactivate')
                              } else if (x?.value === "View_detail") {
                                setSelectedLeadId(item?.leadId);
                                setViewTransactionsModel(true);
                              }
                            }}
                            components={{
                              Indicator: () => (
                                <div className="custom-indicator">
                                  {/* <BsThreeDotsVertical className="check-icon" /> */}
                                </div>
                              ),
                            }}
                            // components={{
                            //   Option: CustomOption,
                            // }}
                            maxMenuHeight={200}
                            className="w-[90%]"
                            menuPortalTarget={document.querySelector("body")}
                            options={
                              item?.isTransaction
                                ? [
                                    {
                                      value: "View_detail",
                                      label: "View transaction detail",
                                    },
                                    // { value: "Assign", label: "Assign" },
                                  ]
                                : [
                                    {
                                      value: "Enter_detail",
                                      label: "Enter transaction detail",
                                    },
                                    // { value: "Assign", label: "Assign" }
                                  ]
                            }
                            styles={customStyles}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <DataNotFound />
              )}
            </div>

            {totalPages > 1 && (
              <div className="absolute bottom-2 w-full flex items-center justify-center mt-8">
                <div className="flex justify-center items-center space-x-4 ">
                  <button
                    onClick={handlePrevious}
                    className={`px-4 py-2 text-white rounded ${
                      pageNumber === 1
                        ? "bg-gray-400 cursor-not-allowed"
                        : "bg-blue-500 hover:bg-blue-700"
                    }`}
                    disabled={pageNumber === 1}
                  >
                    Previous
                  </button>

                  <span className="text-lg text-white dark:text-black font-medium">
                    Page {pageNumber} of {totalPages}
                  </span>

                  <button
                    onClick={handleNext}
                    className={`px-4 py-2 text-white rounded ${
                      pageNumber === totalPages
                        ? "bg-gray-400 cursor-not-allowed"
                        : "bg-blue-500 hover:bg-blue-700"
                    }`}
                    disabled={pageNumber === totalPages}
                  >
                    Next
                  </button>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="w-full h-[400px]">
            <Skeleton />{" "}
          </div>
        )}
      </div>
      {OpenTransactionModel && (
        <UniversalModal
          fixedWidth={true}
          setShowModel={setOpenTransactionModel}
          showSaveButton={true}
          handleSave={handleTransaction}
        >
          <div className="flex flex-col mt-4 items-start space-y-6 text-darkThemeFontColor p-4 min-w-[600px] ">
            <div className="absolute font-semibold mx-2 top-4 left-10">
              <h1>Enter Last Transaction Detail</h1>
            </div>
            {/* Heading for Transaction ID */}
            <div className="w-full">
              <h3 className="text-lg font-semibold  mb-2">Transaction ID</h3>
              <input
                type="text"
                name="transactionId"
                value={transactionId}
                onChange={(e) => setTransactionId(e.target.value)}
                placeholder="Enter Transaction ID"
                className="w-full border text-black border-gray-300 p-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            {/* Heading for Transaction Proof */}
            <div className="w-full">
              <h3 className="text-lg font-semibold mb-2">Transaction Proof</h3>
              <div className="w-32 h-32 rounded-lg border-2 border-dashed border-gray-300 overflow-hidden flex items-center justify-center relative">
                {selectedImage ? (
                  <img
                    src={selectedImage}
                    alt="Transaction Proof Preview"
                    className="object-cover w-full h-full"
                  />
                ) : (
                  <label className="w-full h-full flex items-center justify-center cursor-pointer ">
                    <span className="flex items-center text-sm justify-center">
                      Upload
                    </span>
                    <input
                      type="file"
                      onChange={handleImageChange}
                      accept="image/*"
                      className="hidden"
                    />
                  </label>
                )}
              </div>
              {selectedImage && (
                <button
                  onClick={() => setSelectedImage(null)}
                  className="mt-2 text-xs underline"
                >
                  Remove Image
                </button>
              )}
            </div>
          </div>
        </UniversalModal>
      )}
      {viewTransactionsModel && (
        <UniversalModel setShowModel={setViewTransactionsModel}>
            <UniversalLoader loader={editClientTransactionIsLoading} />
          <div className="w-full overflow-y-auto max-h-[70vh] p-4 mt-8">
            <div className="flex w-full justify-between items-center">
              <h2 className="text-lg font-bold text-white mb-4">
                Transaction Details
              </h2>
              <div className="flex gap-2 items-center">
                {editTransaction && (
                    <>
                      <h1 onClick={()=>{setEditTransaction(false)}} className="font-bold text-white p-2 bg-slate-600 mb-4 rounded-lg cursor-pointer">
                      Back
                    </h1>
                  <h1 onClick={handleEditTransaction} className="font-bold text-white p-2 bg-blue-400 mb-4 rounded-lg cursor-pointer">
                    Save
                  </h1>
                  </>
                )}
              </div>
            </div>

            {!editTransaction ? (
              getTransactionDetail?.data?.transaction?.map((transaction) => (
                <div
                  key={transaction.transactionId}
                  className="border-b flex flex-col border-white/20 pb-4 mb-4"
                >
                  <div className="w-full flex justify-between">
                    <div className="text-md font-semibold text-gray-100">
                      <span className="font-semibold">Transaction Id: </span>{" "}
                      {transaction.transactionId}
                    </div>
                    <div className="w-fit">
                      <Button
                        onClick={() => {
                          setEditTransaction(true);
                          setOneTransactionDetail(transaction);
                        }}
                        className="cursor-pointer"
                      >
                        Edit
                      </Button>
                    </div>
                  </div>
                  <div className="mt-2">
                    <h4 className="text-sm font-medium text-gray-300">
                      Transaction Image:
                    </h4>
                    <img
                      className="h-24 w-24"
                      src={transaction?.transactionImage}
                      alt=""
                    />
                  </div>
                </div>
              ))
            ) : (
              <div className="w-full overflow-y-auto max-h-[70vh]">
                <div className="flex flex-col items-start text-darkThemeFontColor min-w-[600px]">
                  <div className="absolute font-semibold mx-2 top-4 left-10">
                    <h1>Enter Last Transaction Detail</h1>
                  </div>

                  {/* Heading for Transaction ID */}
                  <div className="w-full">
                    <h3 className="text-lg font-semibold mb-2">
                      Transaction ID
                    </h3>
                    <input
                      type="text"
                      name="transactionId"
                      value={oneTransactionDetail?.transactionId || ""}
                      onChange={(e) =>
                        setOneTransactionDetail((prev) => ({
                          ...prev,
                          transactionId: e.target.value,
                        }))
                      }
                      placeholder="Enter Transaction ID"
                      className="w-full border text-black border-gray-300 p-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                  </div>

                  {/* Heading for Transaction Proof */}
                  <div className="w-full">
                    <h3 className="text-lg font-semibold mb-2">
                      Transaction Proof
                    </h3>
                    <div className="w-32 h-32 rounded-lg border-2 border-dashed border-gray-300 overflow-hidden flex items-center justify-center relative">
                      {oneTransactionDetail?.transactionImage &&
                      !selectedImage ? (
                        <>
                          <img
                            src={oneTransactionDetail?.transactionImage}
                            alt="Transaction Proof Preview"
                            className="object-cover w-full h-full"
                          />
                          {/* <button
                   
                      className="absolute bottom-2 right-2 text-xs bg-red-500 text-white px-2 py-1 rounded"
                    >
                      Remove
                    </button> */}
                        </>
                      ) : (
                        <label className="w-full h-full flex items-center justify-center cursor-pointer">
                          <span className="flex items-center text-sm justify-center">
                            Upload
                          </span>
                          <input
                            type="file"
                            onChange={handleEditTransactionImage}
                            accept="image/*"
                            className="hidden"
                          />
                        </label>
                      )}
                    </div>
                    {oneTransactionDetail.transactionImage && (
                      <button
                        onClick={() => {
                          setSelectedImage(null);
                          setOneTransactionDetail((prev) => ({
                            ...prev,
                            transactionImage: null,
                          }));
                        }}
                        className="mt-2 text-xs underline"
                      >
                        Remove Image
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </UniversalModel>
      )}
    </div>
  );
};

export default CSO_Dashboard;
