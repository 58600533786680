import React, { useEffect,  useState } from "react";
import OnboardingForm from "../../component/onBoardingForm";
import {
  useAddPreOnboardingMutation,
  useGetHotelServicesPreOnboardingQuery,
  useGetOtaDetailsQuery,
  useGetPreOnboardingFormQuery,
} from "../../redux/slices/onBoarding";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { useLocation } from "react-router-dom";
import swal from "sweetalert";
import { AiFillDelete } from "react-icons/ai";
import { useIsIpad } from "../../utils/isIpad";
import { FaYoutube } from "react-icons/fa";
import { IoIosSave } from "react-icons/io";
import RealTimeDate from "./RealTimeDate";
import ExcelToJson from "../../component/excelExport";

const OnboardingFormPage = () => {
  const location = useLocation();
  const clientId =
    location.pathname?.split("/")[location.pathname?.split("/")?.length - 1];
    console.log(clientId,"clientId")
  const { data: getOnboardingForm } = useGetPreOnboardingFormQuery({
    clientId: clientId,
  });

  const { data } = useGetHotelServicesPreOnboardingQuery();
  const { data: otaDetails } = useGetOtaDetailsQuery();
  const [addPreOnboarding, { isLoading }] = useAddPreOnboardingMutation();
  const [numberOfHotel, setNumberOfHotels] = useState(1);
  const [selectedIndex, setSelectedIndex] = useState();
  const [showDetails, setShowDetails] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [jsonData, setJsonData] = useState(null);
  const [objHeaders,setObjHeaders] = useState()

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const hotelService = data?.data?.map((item) => {
    return {
      serviceName: item?.label,
      serviceValue: item?.value,
      service: true,
      charges: 50,
    };
  });

  const otaData = otaDetails?.data?.map((ota) => {
    return {
      otaName: ota?.otaName,
      loginId: "",
      password: "",
      phoneNo: "",
      email: "",
      otaUserId: "",
      otaImage: ota?.otaImage,
    };
  });

  const [onboardingFormData, setOnboardingFormData] = useState([
    {
      // companyId: 123456,
      // clientId: 654321,
      hotelDetails: {
        hotelName: "",
        starCategory: "",
        typeOfProperty: "",
        location: "",
        address: "",
        nearestAirport: "",
        distanceFromAirport: "",
        nearestRailwayStation: "",
        distanceFromRailwayStation: "",
        nearbyAttraction: "",
      },
      contactDetails: {
        telephoneNo: "",
        faxNo: "",
        email: "",
        website: "",
        mobileNo: "",
      },
      policies: {
        checkIn: "",
        checkOut: "",
        cancellation: "",
        child: "",
        couple: "",
        extraBed: "",
        localId: "",
        guestProfile: "",
      },
      bankDetails: {
        bankName: "",
        accountName: "",
        accountNo: "",
        micrCode: "",
        ifscCode: "",
        branchCode: "",
      },
      hotelContacts: {
        generalManager: "",
        frontOfficeManager: "",
        revenueOperationPOC: "",
      },
      compsetDetail:{
        totalCompset:0,
        compsetNames:[]
      },
      competitor: [
        {
          hotelName: "",
          startRating: "",
        },
      ],
      otaDetails: otaData,
      channelManager: {
        loginId: "",
        password: "",
        propertyCode: "",
      },
      PMS: {
        loginId: "",
        password: "",
        propertyCode: "",
      },
      roomDetails: [
        {
          categoryName: "",
          roomType: "",
          size: "",
          noOfRooms: "",
          floor: "",
          minOccupancy: "",
          maxOccupancy: "",
          extraBed: true,
          typeOfExtraBed: "",
          extraBedTariff: "",
          basicTariff: "",
          mealPlan: "",
          rateDiffInMealPlan: "",
          bedType: "",
          desciption: "",
        },
      ],
      hotelFacility: hotelService,
      recreationFacility: {
        swimmingPool: true,
        sunBedPool: true,
        spa: true,
        kidsPlayArea: true,
        garden: true,
        gameZone: true,
        gym: true,
      },
      services: {
        wifiServices: "",
        wifiSpeed: "",
        AC: "",
        typeOfAC: "",
        Fan: "",
        roomService: "",
        roomServiceOperationalHours: 24,
        LaundryCleaning: "",
        ironBoard: "",
        smokingRooms: "",
        miniBar: "",
        personalSafetyBox: "",
        teaCoffeeAmenities: "",
        TV: "",
        typeOfTV: "",
        sizeOfTheTV: "",
        Intercom: "",
        wardrobe: "",
        Blanket: "",
        typeOfBlanket: "",
        internationalDirectDialTelephoneFacility: "",
        complimentaryBreakfast: "",
      },
      bathroomAmenities: {
        showerCubicle: "",
        showerCurtain: "",
        bathTub: "",
        hairDryer: "",
        toiletries: "",
        towels: "",
        dentalKit: "",
        mirror: "",
        bathroomSlippers: "",
        bathroomPhone: "",
      },
      banquet: [
        {
          hallName: "",
          pax: "",
        },
      ],
      restaurantDetails: [
        {
          name: "",
          cover: "",
          breakfastStart: "",
          lunchStart: "",
          dinnerStart: "",
          breakfastEnd: "",
          lunchEnd: "",
          dinnerEnd: "",
          menuType: "",
        },
      ],
    },
  ]);

  const handleAddMainObject = () => {
    setOnboardingFormData((prevState) => [
      ...prevState,
      {
        // companyId: 123456,
        // clientId: 654321,
        hotelDetails: {
          hotelName: "",
          starCategory: "",
          typeOfProperty: "",
          location: "",
          address: "",
          nearestAirport: "",
          distanceFromAirport: "",
          nearestRailwayStation: "",
          distanceFromRailwayStation: "",
          nearbyAttraction: "",
        },
        contactDetails: {
          telephoneNo: "",
          faxNo: "",
          email: "",
          website: "",
          mobileNo: "",
        },
        policies: {
          checkIn: "",
          checkOut: "",
          cancellation: "",
          child: "",
          couple: "",
          extraBed: "",
          localId: "",
          guestProfile: "",
        },
        bankDetails: {
          bankName: "",
          accountName: "",
          accountNo: "",
          micrCode: "",
          ifscCode: "",
          branchCode: "",
        },
        hotelContacts: {
          generalManager: "",
          frontOfficeManager: "",
          revenueOperationPOC: "",
        },
        competitor: [
          {
            hotelName: "",
            startRating: "",
          },
        ],
        otaDetails: otaData,
        channelManager: {
          loginId: "",
          password: "",
          propertyCode: "",
        },
        PMS: {
          loginId: "",
          password: "",
          propertyCode: "",
        },
        roomDetails: [
          {
            categoryName: "",
            roomType: "",
            size: "",
            noOfRooms: "",
            floor: "",
            minOccupancy: "",
            maxOccupancy: "",
            extraBed: true,
            typeOfExtraBed: "",
            extraBedTariff: "",
            basicTariff: "",
            mealPlan: "",
            rateDiffInMealPlan: "",
            bedType: "",
            desciption: "",
          },
        ],
        hotelFacility: hotelService,
        recreationFacility: {
          swimmingPool: true,
          sunBedPool: true,
          spa: true,
          kidsPlayArea: true,
          garden: true,
          gameZone: true,
          gym: true,
        },
        services: {
          wifiServices: "",
          wifiSpeed: "",
          AC: "",
          typeOfAC: "",
          Fan: "",
          roomService: "",
          roomServiceOperationalHours: 24,
          LaundryCleaning: "",
          ironBoard: "",
          smokingRooms: "",
          miniBar: "",
          personalSafetyBox: "",
          teaCoffeeAmenities: "",
          TV: "",
          typeOfTV: "",
          sizeOfTheTV: "",
          Intercom: "",
          wardrobe: "",
          Blanket: "",
          typeOfBlanket: "",
          internationalDirectDialTelephoneFacility: "",
          complimentaryBreakfast: "",
        },
        bathroomAmenities: {
          showerCubicle: "",
          showerCurtain: "",
          bathTub: "",
          hairDryer: "",
          toiletries: "",
          towels: "",
          dentalKit: "",
          mirror: "",
          bathroomSlippers: "",
          bathroomPhone: "",
        },
        banquet: [
          {
            hallName: "",
            pax: "",
          },
        ],
        restaurantDetails: [
          {
            name: "",
            cover: "",
            breakfastStart: "",
            lunchStart: "",
            dinnerStart: "",
            breakfastEnd: "",
            lunchEnd: "",
            dinnerEnd: "",
            menuType: "",
          },
        ],
      },
    ]);
  };

  const deleteObjectByIndex = (index) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this item!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("Item has been deleted!", {
          icon: "success",
        });
        setOnboardingFormData((prevData) =>
          prevData.filter((_, i) => i !== index)
        );
      }
    });
  };

  useEffect(() => {
    if (otaData) {
      setOnboardingFormData((prevState) =>
        prevState.map((item) => ({
          ...item,
          otaDetails: otaData, // Update otaDetails with the new otaData
        }))
      );
    }
  }, [otaDetails]); // Make sure this depends on otaData

  useEffect(() => {
    if (data) {
      setOnboardingFormData((prevState) =>
        prevState.map((item) => ({
          ...item,
          hotelFacility: hotelService, // Update otaDetails with the new otaData
        }))
      );
    }
  }, [data]);
  useEffect(() => {
    setNumberOfHotels(onboardingFormData?.length);
  }, [onboardingFormData]);

  const updateHotelName = (index, newHotelName) => {
    setOnboardingFormData((prevData) =>
      prevData.map((item, i) =>
        i === index
          ? {
              ...item,
              hotelDetails: {
                ...item.hotelDetails,
                hotelName: newHotelName,
              },
            }
          : item
      )
    );
  };

  const addHotels = () => {
    setOnboardingFormData([]); // Reset the array before adding new data
    for (let i = 0; i < numberOfHotel; i++) {
      handleAddMainObject();
    }
  };

  const handleSave = () => {
    const obj = {
      numberOfHotel: numberOfHotel,
      clientId:clientId,
      propertyDetail: onboardingFormData,
    };
    addPreOnboarding(obj)
      .unwrap()
      .then((res) => {
        toast.success("Pre onboarding data saved successfully!", {
          position: "bottom-right",
        });
      })
      .catch((err) => {
        toast.error("Something went wrong!", { position: "bottom-right" });
      });
  };

  const navigateToUrl = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const apiData = getOnboardingForm?.data?.propertyDetail;

  useEffect(() => {
    if (apiData) {
      setNumberOfHotels(getOnboardingForm?.data?.numberOfHotel);
      setOnboardingFormData(apiData);
    }
  }, [getOnboardingForm?.data]);

  const isIpad = useIsIpad();

  useEffect(() => {
    if (jsonData && selectedFile) {
      const objHeaders = Object.keys(jsonData)
      setObjHeaders(objHeaders)
    }
  }, [jsonData,selectedFile]);

  return (
    <div className="w-[100%] inset-0 fixed top-0 mx-auto h-screen">
      <div className={`flex  w-[100%] xs:pr-4  justify-between items-center`}>
        <div className="ml-3">
          <img
            width={100}
            src="https://static.wixstatic.com/media/3df195_1310073eba3d4ade9ab96bf3a4e00860~mv2.png/v1/fill/w_980,h_551,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/3df195_1310073eba3d4ade9ab96bf3a4e00860~mv2.png"
          />
        </div>
        <div className="flex  items-center gap-2 ">
          <h1 className="mt-2 mb-2 xs:hidden text-nowrap text-black dark:text-white text-lg flex items-center mx-4">
            Company Pre-Onboarding Form
          </h1>
          <input
            className="text-black text-sm"
            type="file"
            accept=".xlsx, .xls"
            onChange={handleFileChange}
          />
          <div
            onClick={handleSave}
            className="w-fit bg-green-100 flex items-center gap-2  cursor-pointer text-green-500 border-green-500 border hover:bg-green-500 hover:text-white duration-150 transition-all ease-in-out text-sm  px-4 py-1 rounded-lg "
          >
            {/* <IoIosSave /> */}
            Save
          </div>
          <div
            onClick={() =>
              navigateToUrl("https://www.youtube.com/@retvensofficial")
            }
            className="w-fit flex items-center gap-2 cursor-pointer text-white text-nowrap border-green-500 border bg-green-500 hover:text-white duration-150 transition-all ease-in-out text-sm  px-4 py-1 rounded-lg "
          >
            {/* <FaYoutube /> */}
            View Demo
          </div>
          <div className="text-black text-nowrap xs:hidden text-sm mr-4">
            <RealTimeDate />
          </div>
        </div>
      </div>

      {selectedFile && (
        <ExcelToJson file={selectedFile} setData={setJsonData} />
      )}

      {!showDetails && (
        <div className=" xs:w-[92%] lg:w-[97%] xl:w-[98%] w-[96%] ml-[16px]  relative rounded-lg p-4 bg-lightThemeGradient z-10  dark:bg-darkThemeGradient">
          <div>
            <div className="text-sm">Enter Number of Hotels</div>
            <div className="text-sm flex items-center gap-4">
              <input
                type="number"
                value={numberOfHotel}
                onChange={(e) => setNumberOfHotels(e.target.value)}
                placeholder="Enter number of hotels"
                className="px-2 h-10 text-black min-w-[200px] rounded-md my-2 text-sm placeholder:text-sm"
              />
              <div
                onClick={() => {
                  addHotels();
                }}
                className="text-sm flex items-center  bg-blue-100 text-blue-500 hover:text-white hover:bg-blue-500 duration-150 transition-all ease-in-out px-4 py-2 rounded-lg w-fit cursor-pointer"
              >
                + Add
              </div>
            </div>
          </div>

          <div
            className={`grid ${isIpad ? "grid-cols-2" : "xs:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 grid-cols-1"} xs:gap-4 mt-[60px]`}
          >
            {onboardingFormData &&
              onboardingFormData?.map((items, index) => {
                return (
                  <div>
                    <div>Hotel #{index + 1}</div>
                    <div className="flex xs:block items-center gap-4">
                      <input
                        placeholder="Enter hotel name"
                        value={items?.hotelDetails.hotelName}
                        onChange={(e) => {
                          updateHotelName(index, e.target.value);
                        }}
                        className="px-2 h-10 min-w-[200px] xs:min-w-[100%]  rounded-md my-2 text-sm placeholder:text-sm text-black"
                      />
                      <div className="flex items-center gap-4">
                        <div
                          onClick={() => {
                            setSelectedIndex(index);
                            setShowDetails(true);
                          }}
                          className="text-sm whitespace-nowrap bg-blue-100 text-blue-500 hover:text-white hover:bg-blue-500 duration-150 transition-all ease-in-out px-4 py-2 rounded-lg w-fit cursor-pointer"
                        >
                          Add More Details
                        </div>
                        <div
                          onClick={() => {
                            deleteObjectByIndex(index);
                          }}
                          className="text-sm flex items-center gap-4 bg-red-100 text-red-500 hover:text-white hover:bg-red-500 duration-150 transition-all ease-in-out px-4 py-2 rounded-lg w-fit cursor-pointer"
                        >
                          <AiFillDelete />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}

      <div className="p-4 flex flex-col gap-4">
        {Array?.isArray(onboardingFormData) &&
          onboardingFormData?.map((step, index) => {
            return (
              <>
                {selectedIndex === index && (
                  <OnboardingForm
                    index={selectedIndex}
                    data={step}
                    setOnboardingFormData={setOnboardingFormData}
                    onboardingFormData={onboardingFormData[selectedIndex]}
                    setShowDetails={setShowDetails}
                    setSelectedIndex={setSelectedIndex}
                    handleSave1={handleSave}
                  />
                )}
              </>
            );
          })}
      </div>
    </div>
  );
};

export default OnboardingFormPage;
