// import { emptySplitApi } from "../../injectEndpoint";
import { get } from "react-hook-form";
import getToken from "../../utils/getToken";
import { emptySplitApi } from "../injectEndpoint";

export const Dashboard = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserTask: builder.query({
      query: () => ({
        url: `task/getUserTask`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
      }),
      invalidatesTags: ["getUserTask"],
    }),
    getNotification: builder.query({
      query: (data) => ({
        url: `notification/getNotification`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
        params: data,
      }),
      invalidatesTags: ["getNotification"],
    }),
    getrevenueGrowth: builder.query({
      query: () => ({
        url: `dashboard/getRevenueGrowth`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
      }),
      invalidatesTags: ["revenueGrowth"],
    }),
    getActivityManager: builder.query({
      query: () => ({
        url: `dashboard/getActivityManager`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
      }),
      invalidatesTags: ["getActivityManager"],
    }),
    getChannelPerformance: builder.query({
      query: (data) => ({
        url: `dashboard/channelPerformance`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
        params: data,
      }),
      invalidatesTags: ["getChannelPerformance"],
    }),
    getPropertyChain: builder.query({
      query: () => ({
        url: `dashboard/getPropertyClient`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
      }),
      invalidatesTags: ["getPropertyChain"],
    }),
    getStarCategory: builder.query({
      query: () => ({
        url: `dashboard/getStarCategory`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
      }),
      invalidatesTags: ["getStarCategory"],
    }),
    postHelpandSupport: builder.mutation({
      query: (data) => ({
        url: `helpAndSupport/helpAndSupport`,
        method: "POST",
        headers: {
          Authorization: getToken(),
        },
        body: data,
      }),
      invalidatesTags: ["postHelpandSupport"],
    }),
    getAllFaq: builder.query({
      query: () => ({
        url: `helpAndSupport/getAllFaq`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
      }),
      invalidatesTags: ["getAllFaq"],
    }),
    getHighPerformanceClient: builder.query({
      query: () => ({
        url: `dashboard/highPerformanceClient`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
      }),
      invalidatesTags: ["getClientPerformance"],
    }),
    getLowPerformanceClient: builder.query({
      query: () => ({
        url: `dashboard/lowPerformanceClient`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
      }),
      invalidatesTags: ["getClientPerformance"],
    }),
    getOnboardingFunnel: builder.query({
      query: () => ({
        url: `dashboard/getOnboardingFunnel`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
      }),
      invalidatesTags: ["getOnboardingFunnel"],
    }),
    getDesignation: builder.query({
      query: (data) => ({
        url: `userRoutes/getDesignation`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
        params: data,
      }),
      invalidatesTags: ["getDesignation"],
    }),
    getPerformanceDashboard: builder.query({
      query: () => ({
        url: `dashboard/performanceDashboard`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
      }),
      invalidatesTags: ["getPerformanceDashboard"],
    }),
    getEmployeeOverview: builder.query({
      query: (data) => ({
        url: `dashboard/getEmplyoeeOverview`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
        params: data,
      }),
      invalidatesTags: ["getEmployeeOverview"],
    }),
    getPersonalProgress: builder.query({
      query: (data) => ({
        url: `dashboard/getPersonalProgress?startDate=2024-09-14&endDate=2024-09-19`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
        params: data,
      }),
      invalidatesTags: ["getPersonalProgress"],
    }),
    getTeamAcitivy: builder.query({
      query: (data) => ({
        url: `dashboard/getActivityHistory`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
        params: data,
      }),
      invalidatesTags: ["getTeamAcitivy"],
    }),
    getReminder: builder.query({
      query: (data) => ({
        url: `reminder/getReminder`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
        params: data,
      }),
      invalidatesTags: ["getReminder"],
    }),
    getSingleReminder: builder.query({
      query: (data) => ({
        url: `reminder/getSingleReminder`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
        params: { reminderId: data?.reminderId },
      }),
      invalidatesTags: ["getSingleReminder"],
    }),
    addReminder: builder.mutation({
      query: (data) => ({
        url: `reminder/addReminder`,
        method: "POST",
        headers: {
          Authorization: getToken(),
        },
        body: data,
      }),
      invalidatesTags: ["addReminder"],
    }),
    editReminder: builder.mutation({
      query: (data) => ({
        url: `reminder/editReminder`,
        method: "PATCH",
        headers: {
          Authorization: getToken(),
        },
        params: { reminderId: data?.reminderId },
        body: data,
      }),
      invalidatesTags: ["editReminder"],
    }),
    getUpComingMeeting: builder.query({
      query: (data) => ({
        url: `dashboard/upComingMeeting`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
        params: data,
      }),
      invalidatesTags: ["getUpComingMeeting"],
    }),
    getAllMeeting: builder.query({
      query: (data) => ({
        url: `meeting/getAllMeeting`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
        params: data,
      }),
      invalidatesTags: ["getAllMeeting"],
    }),
    clientData: builder.query({
      query: (data) => ({
        url: `meeting/getAllMeeting`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
        params: data,
      }),
      invalidatesTags: ["getAllMeeting"],
    }),
    getUpcomingTask: builder.query({
      query: (data) => ({
        url: `dashboard/upComingTask?startDate=2024-09-01&endDate=2024-09-31`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
        params: data,
      }),
      invalidatesTags: ["getUpcomingTask"],
    }),
    getHotelPerformanceSummery: builder.query({
      query: (data) => ({
        url: `dashboard/hotelPerformanceSummary`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
        params: data,
      }),
      invalidatesTags: ["getHotelPerformanceSummery"],
    }),
    getAllReminder: builder.query({
      query: (data) => ({
        url: `reminder/getAllReminder`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
        params: data,
      }),
      invalidatesTags: ["getAllReminder"],
    }),
    getClientCRO: builder.query({
      query: (data) => ({
        url: `dashboard/croDashboard`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
        params: data,
      }),
      invalidatesTags: ["getClientCRO"],
    }),
    getPropertiesOnRisk: builder.query({
      query: (data) => ({
        url: `dashboard/propertiesOnRisk?startDate=2024-09-01&endDate=2024-09-31`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
        params: data,
      }),
      invalidatesTags: ["getPropertiesOnRisk"],
    }),
    assiginPropertyTask: builder.mutation({
      query: (data) => ({
        url: `properties/assignProperty`,
        method: "PATCH",
        headers: {
          Authorization: getToken(),
        },
        body: data,
      }),
      providesTags: ["assiginPropertyTask"],
    }),
    otaRevenueBreakdown: builder.query({
      query: (data) => ({
        url: `properties/otaRevenueBreakDown`,
        method: "GET",
        headers: {
          Authorization: getToken(), // Make sure this function returns a valid token
        },
        params: {
          hId: data?.hId, // Dynamic or static as per requirement
          type: data?.type || "", // Optionally pass 'type'
          startDate: data?.startDate || "2024-09-10", // Make dates dynamic if needed
          endDate: data?.endDate || "2024-09-30", // Fix the end date to be valid
        },
      }),
      invalidatesTags: ["otaRevenueBreakdown"],
    }),
    clientPropertyRevenue: builder.query({
      query: (data) => ({
        url: `client/clientPropertyRevenue`,
        method: "GET",
        headers: {
          Authorization: getToken(), // Make sure this function returns a valid token
        },
        params: {
          clientId: data?.clientId,
        },
      }),
      invalidatesTags: ["clientPropertyRevenue"],
    }),
    otaTable: builder.query({
      query: (data) => ({
        url: `properties/otaDetail`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
        params: data,
      }),
      invalidatesTags: ["otaTable"],
    }),
    PropertyRevenueBreakdonw: builder.query({
      query: (data) => ({
        url: `properties/propertyManagement`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
        params: data,
      }),
      invalidatesTags: ["PropertyRevenueBreakdonw"],
    }),
    propertyManagement: builder.query({
      query: (data) => ({
        url: `properties/propertyManagement`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
        params: data,
      }),
      invalidatesTags: ["propertyManagement"],
    }),
    clientManagement: builder.query({
      query: (data) => ({
        url: `client/clientele`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
        params: data,
      }),
      invalidatesTags: ["clientManagement"],
    }),
    composetDetail: builder.query({
      query: (data) => ({
        url: `properties/compsetDetail`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
        params: data,
      }),
      invalidatesTags: ["composetDetail"],
    }),
    editNotification: builder.mutation({
      query: (data) => ({
        url: `notification/editNotification`,
        method: "PATCH",
        headers: {
          Authorization: getToken(),
        },
        body: {
          notificationId: [data?.notificationId],
          markAsRead: data?.markAsRead,
        },
      }),
      invalidatesTags: ["editNotification"],
    }),
    employeeProperty: builder.query({
      query: (data) => ({
        url: `employee/employeeProperty`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
        params: data,
      }),
      invalidatesTags: ["employeeProperty"],
    }),
    getEmployeeActivity: builder.query({
      query: (data) => ({
        url: `employee/getEmployeeActivityHistory`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
        params: data,
      }),
      invalidatesTags: ["getEmployeeActivity"],
    }),
    getHeaderUtils: builder.query({
      query: () => ({
        url: `notification/getHeaderUtils`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
      }),
      invalidatesTags: ["getHeaderUtils"],
    }),
    clientPropertyManagement: builder.query({
      query: (data) => ({
        url: `properties/clientPropertyManagement`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
        params: data,
      }),
      invalidatesTags: ["clientPropertyManagement"],
    }),
    unAssignProperty: builder.mutation({
      query: (data) => ({
        url: `settings/user/deleteAssignProperty`,
        method: "PATCH",
        headers: {
          Authorization: getToken(),
        },
        body: data,
      }),
      invalidatesTags: ["unAssignProperty"],
    }),
    addPropertyDetail: builder.mutation({
      query: (data) => ({
        url: `properties/addPropertyDetail`,
        method: "POST",
        headers: {
          Authorization: getToken(),
        },
        body: data,
      }),
      invalidatesTags: ["addPropertyDetail"],
    }),
    getPropertyActivity: builder.query({
      query: (data) => ({
        url: `properties/getPropertyActivity`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
        params: data,
        // body: data,
      }),
      invalidatesTags: ["getPropertyActivity"],
    }),
    deleteProperty: builder.mutation({
      query: (data) => ({
        url: `properties/deleteProperty`,
        method: "PATCH",
        headers: {
          Authorization: getToken(),
        },
        params: data,
      }),
      invalidatesTags: ["deleteProperty"],
    }),
    deleteClient: builder.mutation({
      query: (data) => ({
        url: `client/deleteClient`,
        method: "PATCH",
        headers: {
          Authorization: getToken(),
        },
        params: data,
      }),
      invalidatesTags: ["deleteClient"],
    }),
    deactiveProperty: builder.mutation({
      query: (data) => ({
        url: "properties/deactivateProperty",
        method: "PATCH",
        headers: {
          Authorization: getToken(),
        },
        params: { hId: data?.hId },
        body: { isActive: data?.isActive },
      }),
      inValidatesTags: ["deactiveProperty"],
    }),
    addWhatsappGroup: builder.mutation({
      query: (data) => ({
        url: "client/addingWhatsappGroup",
        method: "POST",
        headers: {
          Authorization: getToken(),
        },
        params: { clientId: data?.clientId },
        body: { whatsappGroup: data?.whatsappGroup },
      }),
      inValidatesTags: ["addWhatsappGroup"],
    }),
    sendOnboardingLink: builder.mutation({
      query: (data) => ({
        url: "client/sendOnboardingLink",
        method: "PATCH",
        headers: {
          Authorization: getToken(),
        },
        body: {
            "whatsappGroup": [
              {
                "whatsappGroupName":data?.groupName
              }
            ]
          },
          params: {
            clientId: data?.clientId,
          },
      }),
      inValidatesTags: ["getWhatsappGroup"],
    }),
    getWhatsappGroupDetail: builder.query({
      query: (data) => ({
        url: "client/getWhatsappGroupDetail",
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
        params: data,
      }),
      inValidatesTags: ["getWhatsappGroupDetail"],
    }),
    DistressClient: builder.query({
      query: (data) => ({
        url: `properties/DistressClient`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
        params: data,
      }),
      invalidatesTags: ["DistressClient"],
    }),
    notOnboardedProperty: builder.query({
      query: (data) => ({
        url: `properties/notOnboardedProperty`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
        params: data,
      }),
      invalidatesTags: ["notOnboardedProperty"],
    }),
    getSearchUser: builder.query({
      query: (data) => ({
        url: "client/getSearchUser",
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
        params: { searchType: data?.searchText },
      }),
      inValidatesTags: ["getSearchUser"],
    }),
    
  }),
  overrideExisting: false, // Add
});
export const {
  useGetUserTaskQuery,
  useGetrevenueGrowthQuery,
  useGetActivityManagerQuery,
  useGetChannelPerformanceQuery,
  useGetPropertyChainQuery,
  useGetStarCategoryQuery,
  usePostHelpandSupportMutation,
  useGetAllFaqQuery,
  useGetHighPerformanceClientQuery,
  useGetOnboardingFunnelQuery,
  useGetDesignationQuery,
  useGetLowPerformanceClientQuery,
  useGetEmployeeOverviewQuery,
  useGetPersonalProgressQuery,
  useGetTeamAcitivyQuery,
  useGetReminderQuery,
  useGetAllReminderQuery,
  useAddReminderMutation,
  useGetUpComingMeetingQuery,
  useGetAllMeetingQuery,
  useGetUpcomingTaskQuery,
  useGetHotelPerformanceSummeryQuery,
  useEditReminderMutation,
  useGetClientCROQuery,
  useGetPropertiesOnRiskQuery,
  useOtaRevenueBreakdownQuery,
  useOtaTableQuery,
  usePropertyRevenueBreakdonwQuery,
  usePropertyManagementQuery,
  useClientManagementQuery,
  useComposetDetailQuery,
  useGetNotificationQuery,
  useAssiginPropertyTaskMutation,
  useGetSingleReminderQuery,
  useGetPerformanceDashboardQuery,
  useEditNotificationMutation,
  useEmployeePropertyQuery,
  useGetEmployeeActivityQuery,
  useGetHeaderUtilsQuery,
  useClientPropertyRevenueQuery,
  useClientPropertyManagementQuery,
  useUnAssignPropertyMutation,
  useAddPropertyDetailMutation,
  useGetPropertyActivityQuery,
  useDeletePropertyMutation,
  useDeleteClientMutation,
  useDeactivePropertyMutation,
  useAddWhatsappGroupMutation,
  useSendOnboardingLinkMutation,
  useGetSearchUserQuery,
  useGetWhatsappGroupDetailQuery,
  useDistressClientQuery,
  useNotOnboardedPropertyQuery
} = Dashboard; // Corrected export
