import React, { useEffect, useState } from "react";
import { FaLongArrowAltLeft } from "react-icons/fa";
import Button from "../../component/button";
import ProfilePicture from "../../assets/icons/Revens/ProfilePicture.png";
import UserProfileWrapper from "../../component/WrapperComponents/UserProfileWrapper";
import {
    useChangeAssignRoleMutation,
    useGetClientDetailQuery,
    useGetPropertyAssignQuery,
    useGetPropertyDetailQuery,
    useGetUserDetailQuery,
    useGetUserListQuery,
    usePostPropertyAssignMutation,
} from "../../redux/slices/Setting";
import AddEmployee from "../employees/AddEmployee";
import { useNavigate, useParams } from "react-router-dom";
import Properties from "../../component/DashboardScreen/Properties";
import OTATable from "../../component/DashboardScreen/OTATable";
import CompsetTable from "../../component/DashboardScreen/CompsetTable";
import OTARevenueBreakdown from "../../component/ApexGraphComponent/OTARevenueBreakdown";
import UniversalTable from "../../component/Table/UniversalTable";
import { CiFilter, CiGrid42 } from "react-icons/ci";
import { IoIosSearch } from "react-icons/io";
import PropertyActivity from "../../component/PropertyActivity";
import NormalDropDown from "../../component/DropDown/NormalDropDown";
import { toast } from "react-toastify";
import { useUnAssignPropertyMutation } from "../../redux/slices/dashboard";
const UserDetail = () => {
    const { userId } = useParams();
    const navigate = useNavigate();
    const { data, refetch } = useGetPropertyDetailQuery({ hId: userId });
    const { data: assignedToData, refetch: assignedToRefetch } = useGetPropertyAssignQuery({ hId: userId });
    const [userData, setUserData] = useState();
    const [showAddEmployee, setShowAddEmployee] = useState(false);
    const [showAddButton, setShowAddButton] = useState(false);
    const [toggleButton,setToggleButton]=useState(false)
    const [selectedScreen, setSelectedScreen] = useState("Overview");
    const { data: userListData, isLoading: userListLoading } = useGetUserListQuery({ hId: userId });
    const [selectedUser, setSelectedUser] = useState([])
    const [otherUsersearchText, setOtherUserSearchText] = useState('')
    const [filteredUsers, setFilteredUsers] = useState(userListData?.data);
    const [assignProperty, { isLoading: assignPropertyIsLoading }] = usePostPropertyAssignMutation();
    const [redirectArray, setRedirectArray] = React.useState([
        { role: "Manager", user: null },
        { role: "Associate", user: null },
        { role: "Intern", user: null },
        { role: "Executive", user: null },
      ]);
      const [changeAssignRole,{isLoading:changeAssignRoleIsLoaing}]=useChangeAssignRoleMutation()

    const [unAssignProperty] = useUnAssignPropertyMutation();
    useEffect(() => {
        setUserData(data?.data);
    }, [data]);
    useEffect(() => {
        const filtered = userListData?.data?.filter(user =>
            user?.name?.toLowerCase().includes(otherUsersearchText?.toLowerCase())
        );
        setFilteredUsers(filtered);
    }, [userListData?.data, otherUsersearchText]);
    const handleInputChange = (e) => {
        setOtherUserSearchText(e.target.value);
    };
    const handleAssignToggle = (userId) => {
        setSelectedUser((prev) => [...prev, { userId: userId }])
    };
    const handleAssignSave = () => {
        assignProperty({ userIds: selectedUser, hId: parseInt(userId, 10) }).unwrap().then((res) => {
            toast.success(res?.message, { position: "bottom-right" });
            setShowAddButton(false);
            assignedToRefetch();
        })
            .catch((err) => {
                toast.error(err?.data?.message, { position: "bottom-right" });
            });
    }

    const handleunAssign = (item) => {
        unAssignProperty({ userId: item?.userId, hId: parseInt(userId, 10), propertyStatus: true }).unwrap().then((res) => {
            toast.success(res?.message, { position: "bottom-right" })
            assignedToRefetch();
        })
    }
    const handleDropdownChange = async (value, index) => {
        // Update the selected user in the array
        const updatedArray = [...redirectArray];
        updatedArray[index].user = value;
        setRedirectArray(updatedArray);
    
        // Prepare the payload for the API
        const payload = {
          hId: userId,
          propertyRole: {
            userId: value, // selected userId
            role: updatedArray[index].role, // role for this index
          },
        };

        changeAssignRole(payload).unwrap().then((res)=>{
            toast.success(res?.message, { position: "bottom-right" });
            
        }).catch((err)=>{
            toast.error(err?.data?.message, { position: "bottom-right" });
        })
    }    
 
    return (
        <>
            <div className="p-6 w-full min-h-[100%] ">
                {!showAddEmployee ? (
                    <div className="h-auto mt-20 pl-20 w-full">
                        <div className="w-full h-16 flex mt-20 items-center justify-between border-y-2 dark:border-white border-black">
                            <div className="left flex gap-2 items-center">
                                <div className="flex p-2 text-sm items-center border-r-2 dark:border-white border-black gap-2 text-lightThemeFontColor dark:text-darkThemeFontColor">
                                    Property Details
                                </div>
                                <div
                                    onClick={() => setSelectedScreen("Overview")}
                                    className={`flex ${selectedScreen === "Overview" ? "dark:bg-white dark:text-black bg-black/40 text-white" : "text-lightThemeFontColor dark:text-darkThemeFontColor"} rounded-lg p-2 text-sm cursor-pointer items-center  gap-2 `}
                                >
                                    Overview
                                </div>
                                <div
                                    onClick={() => setSelectedScreen("Activity")}
                                    className={`flex ${selectedScreen === "Activity" ? "dark:bg-white dark:text-black bg-black/40 text-white" : "text-lightThemeFontColor dark:text-darkThemeFontColor"} rounded-lg p-2 text-sm cursor-pointer items-center  gap-2 `}
                                >
                                    Activity
                                </div>
                            </div>
                        </div>
                        {selectedScreen == 'Overview' ? <>
                            <div className="flex mt-8 w-full   justify-between h-full">
                                <div className="flex  bg-lightThemeGradient dark:bg-darkThemeGradient text-darkThemeFontColor p-4  w-[60%] rounded-lg gap-4  ">
                                    <UserProfileWrapper label={"Property Overview"}>
                                        <div className=" flex w-full gap-10 justify-between">
                                            <div className="w-full flex ">
                                                <div className=" w-[90%]  flex   justify-between">
                                                    <div className="flex gap-4 flex-col justify-between ">
                                                        <div className=" ">
                                                            <h1 className="font-bold">Property owner</h1>
                                                            <p>{userData?.propertyOwner || '--'}</p>
                                                        </div>
                                                        <div className="">
                                                            <h1 className="font-bold ">Total Properties </h1>
                                                            <p>{userData?.totalProperty || '--'}</p>
                                                        </div>
                                                        <div className=" ">
                                                            <h1 className=" font-bold ">Mobile Number </h1>
                                                            <p>{userData?.propertyNumber || '--'}</p>
                                                        </div>
                                                        <div className=" ">
                                                            <h1 className="  font-bold">
                                                                Point Of Contact 1 (POC){" "}
                                                            </h1>
                                                            <p>{userData?.pointOfContact?.pointOfContactName || '--'} </p>
                                                        </div>
                                                        <div className="">
                                                            <h1 className="  font-bold">POC 1 Email </h1>
                                                            <p>{userData?.pointOfContact?.pointOfEmail || '--'}</p>
                                                        </div>
                                                        <div className="">
                                                            <h1 className="  font-bold">Current Address</h1>
                                                            <p>{userData?.pointOfContact?.address?.fullAddress || '--'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-col gap-4  ">
                                                        <div className="  ">
                                                            <h1 className="font-bold">Onboarding Date </h1>
                                                            <div className="">{userData?.onBordingDate || '--'}</div>
                                                        </div>
                                                        <div className=" ">
                                                            <h1 className="font-bold">Location </h1>
                                                            <p>{userData?.location || '--'}</p>
                                                        </div>
                                                        <div className=" ">
                                                            <h1 className="font-bold">Email </h1>
                                                            <p>{userData?.propertyEmail || '--'}</p>
                                                        </div>
                                                        <div className=" ">
                                                            <h1 className="font-bold">POC 1 Designation </h1>
                                                            <p>{userData?.pointOfContact?.pointOfDesignation || '--'}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </UserProfileWrapper>
                                </div>
                                <div className=" relative w-[39%] h-[500px] overflow-y-auto rounded-lg bg-lightThemeGradient p-4 dark:bg-darkThemeGradient text-darkThemeFontColor ">
                                    <UserProfileWrapper
                                        showAddButton={showAddButton}
                                        setShowAddButton={setShowAddButton}
                                        setToggleButton={setToggleButton}
                                        toggleButton={toggleButton}
                                        label={"Assigned To "}
                                    >

                                        {showAddButton && <div className="absolute  right-16 text-black rounded-lg p-2 top-16"> <div className="w-1/2">
                                            <div
                                                className={`absolute right-0 pt-2 bg-white px-2 py-1 z-20 rounded-md w-[210px] `}
                                            >
                                                <div className="w-[100%] h-auto max-h-[150px] overflow-y-scroll">
                                                    <input
                                                        type="text"
                                                        value={otherUsersearchText}
                                                        className="text-black border rounded-md px-2"
                                                        placeholder="Search"
                                                        onChange={handleInputChange}
                                                    />
                                                    {filteredUsers?.map((assign, key) => (
                                                        <div className="flex justify-start my-2 items-center gap-2" key={assign.userId}>
                                                            <input
                                                                type="checkbox"
                                                                checked={assign?.isAssigned}
                                                                onChange={() => handleAssignToggle(assign?.id)} // Toggle assignment status
                                                                id={assign?.fullName}
                                                            />
                                                            <div className="w-fit">
                                                                <img
                                                                    data-tooltip-id={`${assign?.fullName}+${key}`}
                                                                    data-tooltip-content={`${assign?.fullName}`}
                                                                    title={`${assign?.fullName}`}
                                                                    src={assign?.profileImg || "https://e7.pngegg.com/pngimages/178/595/png-clipart-user-profile-computer-icons-login-user-avatars-monochrome-black.png"}
                                                                    onError={(e) => {
                                                                        e.target.onerror = null; // Prevents infinite loop if the dummy image fails
                                                                        e.target.src = "https://e7.pngegg.com/pngimages/178/595/png-clipart-user-profile-computer-icons-login-user-avatars-monochrome-black.png"; // Fallback URL when the original fails
                                                                    }}
                                                                    className="w-6 h-6 rounded-full"
                                                                />
                                                            </div>
                                                            <label
                                                                htmlFor={assign?.name}
                                                                className="hover:text-black duration-150 ease-in-out transition-all text-gray-500 cursor-pointer min-w-fit"
                                                            >
                                                                {assign?.name}
                                                            </label>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className="float-end flex items-center gap-2">
                                                    <div
                                                        onClick={() => setShowAddButton(false)}
                                                        className="cursor-pointer bg-red-100 w-fit px-2 py-1 mt-2 mb-1 rounded-md text-end text-xs text-red-500"
                                                    >
                                                        Cancel
                                                    </div>
                                                    <div
                                                        onClick={handleAssignSave}
                                                        className="cursor-pointer text-blue-500 w-fit px-2 py-1 mt-2 mb-1 rounded-md text-end text-xs bg-blue-100"
                                                    >
                                                        {userListLoading ? "Updating..." : "Update"}
                                                    </div>
                                                </div>
                                            </div>
                                        </div></div>}
                                        {toggleButton ?   <div className="p-4 w-full mx-auto">
      <div className="flex flex-col border rounded-lg shadow-lg">
        {/* Table Header */}
        <div className="flex bg-gray-200 font-semibold text-gray-700">
          <div className="flex-1 p-2 border-r">Role</div>
          <div className="flex-1 p-2">User</div>
        </div>
        {/* Table Rows */}
        {redirectArray.map((item, index) => (
          <div key={index} className="flex border-t">
            <div className="flex-1 p-2 border-r">{item.role}</div>
            <div className="flex-1 p-2">
              <NormalDropDown
                selectedValue={item.user}
                setSelectedValue={(value) => handleDropdownChange(value, index)}
                showSelectedValue={true}
                options={userListData?.data}
                sendOnlyId={true}
                label={"Select value"}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
:
                                        <UniversalTable
                                            dataArray={assignedToData?.data}
                                            showOnlyEmail={true}
                                            showActions={"assignedTo"}
                                            handleunAssign={handleunAssign}
                                        />}
                                    </UserProfileWrapper>
                                </div>
                            </div>
                            <div className="flex justify-between">
                                {/* <div className="mt-2 w-[60%]">
                <Properties/>
              </div> */}


                            </div>

                            <div className="flex h-[570px] justify-between ">
                                <div className="mt-2  h-full w-[60%]">
                                    <OTATable hId={userId} />
                                </div>
                                <div className="mt-2 h-full w-[39%]">
                                    <OTARevenueBreakdown hId={userId} />
                                </div>

                            </div>
                            <div className="mt-4 w-[60%]">
                                <CompsetTable hId={userId} />
                            </div>

                        </> : <PropertyActivity hId={userId} />}
                    </div>
                ) : (
                    <AddEmployee
                        setShowAddEmployee={setShowAddEmployee}
                        editEmployee={true}
                        data={data}
                        getClientRefetch={refetch}
                    />
                )}
            </div>
        </>
    );
};

export default UserDetail;
