import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  useNavigate,
  Navigate,
} from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import PrivateRoute from "./privateRoute";
import PublicRoute from "./publicRoute";
import NotFound from "../component/notFound";
import OTPInputField from "../component/OTPvarification/OTPInputField";
import LoginPage from "../pages/OnBoarding/LoginPage";
import NewOnBoarding from "../pages/OnBoarding/newOnBoarding";
import LoadingPage from "../pages/OnBoarding/LoadingPage";
import LoginDashboard from "../pages/OnBoarding/LoginDashboard";
import DividedScreen from "../component/dividedScreen";
import AddTeam from "../pages/addTeam";
import LastStep from "../pages/OnBoarding/lastStep";
import NewLoginDashboard from "../pages/OnBoarding/NewLoginDashboard";
import getToken from "../utils/getToken";
import UserDetail from "../pages/UserDetail/UserDetail";
import ClientDetail from "../pages/ClientDetail/clientDetails";
import AllClient from "../pages/AllClients/AllClient";
import AllEmployees from "../pages/employees/AllEmployees";
import Support from "../pages/Support/Support";
import FAQs from "../pages/Support/FAQs";
import KanabelContainer from "../component/Kanabel/KanabelContainer";
import Test from "../pages/newPages/test";
import WelcomeScreen from "../pages/RevenueManagement/WelcomeScreen";
import SettingPage from "../pages/Settings";
import AllReminder from "../component/Modal/AllReminderr";
import AllMeetings from "../component/Dashboard/AllMeetings";
import ClientManagement from "../pages/ClientDetail/ClientManagement";
import Properties from "../component/DashboardScreen/Properties";
import CSO_Dashboard from "../pages/CSO/dashboard";
import Notifications from "../pages/Notifications";
import EmployeeOverview from "../component/ApexGraphComponent/EmployeeOverview";
import EmployeeOveriviewPage from "../pages/EmployeeOveriviewPage";
import NotificationSetting from "../pages/NotificationSetting";
import ProfilePage from "../pages/ProfilePage";
import OnboardingFormPage from "../pages/onBoardingForm";
import EmployeeDetails from "../pages/EmployeeDetails";
import DynamicTable from '../component/Table/DynamicTable'
import DistressClient from "../component/DashboardScreen/DistressClient";
import NotOnboardedProperty from "../component/DashboardScreen/NotOnboardedProperty";
function AppRouters() {
  const location = useLocation();
  const isAuthenticated = getToken() ? true : false;
  const navigate = useNavigate();

  return (
    <Routes key={location.pathname} location={location}>
      <Route element={<PrivateRoute isAuthenticated={isAuthenticated} />}>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/" element={
        isAuthenticated ? <Navigate to="/dashboard" replace /> : <Navigate to="/login" replace />
      } />
        <Route path="/CSO-Dashboard" element={<CSO_Dashboard />} />
        <Route
          path="/"
          element={
            isAuthenticated ? (
              <Navigate to="/dashboard" replace />
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        <Route path="/onboarding" element={<NewOnBoarding />} />
        <Route path="/loginDashboard" element={<NewLoginDashboard />} />
        <Route path="/add-team-members" element={<AddTeam />} />
        <Route path="/onboarding/success" element={<LastStep />} />
        <Route path="/otp" element={<OTPInputField />} />
        <Route path="/loading" element={<LoadingPage />} />
        <Route path="/reminders" element={<AllReminder />} />
        <Route path="/meetings" element={<AllMeetings />} />
        <Route path="/properties/:userId" element={<UserDetail />} />
        <Route path="/client/:hotelId" element={<ClientDetail />} />
        <Route path="/All-client" element={<AllClient />} />
        <Route path="/settings" element={<SettingPage />} />
        <Route path="/settings/task-management" element={<SettingPage />} />
        <Route path="/employee" element={<AllEmployees />} />
        <Route path="/Help-And-Support" element={<Support />} />
        <Route path="/FAQ" element={<FAQs />} />
        <Route path="/task-management" element={<KanabelContainer />} />
        <Route path="/task-management/kanban" element={<KanabelContainer />} />
        <Route path="/task-management/list" element={<KanabelContainer />} />
        <Route path="/clients" element={<ClientManagement />} />
        <Route path="/notification" element={<Notifications />} />
        <Route path="/settings/employee-management" element={<EmployeeOveriviewPage />} />
        <Route path="/settings/notification-management" element={<NotificationSetting />} />
        <Route path="/ProfilePage" element={<ProfilePage />} />
         <Route path="/dynamicTable" element={<DynamicTable />} />
        <Route path="/employeeOverview/:empId" element={<EmployeeDetails/>} />
        {/* <Route path="/client-pre-onboarding-form/:id" element={<OnboardingFormPage />} /> */}
        <Route
          path="/properties"
          element={
            <div className="p-6 w-full  h-auto ">

              <div className="h-auto mt-20 pl-20 w-full">
                
                <Properties showAddProperty={true} />
              </div>
            </div>
          }
        />
         <Route
          path="properties/distressClient"
          element={
            <div className="p-6 w-full  h-auto ">

              <div className="h-auto mt-20 pl-20 w-full">
                
                <DistressClient showAddProperty={true} />
              </div>
            </div>
          }
        />
         <Route
          path="properties/notOnboardedProperty"
          element={
            <div className="p-6 w-full  h-auto ">

              <div className="h-auto mt-20 pl-20 w-full">
                
                <NotOnboardedProperty showAddProperty={true} />
              </div>
            </div>
          }
        />
      </Route>

      

      <Route path="/welcome" element={<WelcomeScreen />} />

      <Route element={<PublicRoute isAuthenticated={isAuthenticated} />}>
        
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<LoginPage />} />

        <Route path="/test" element={<Test />} />

        <Route path="/forgot-password" element={<LoginDashboard />} />
        <Route path="/change-password/*" element={<LoginDashboard />} />
      </Route>
      <Route path="/client-pre-onboarding-form/:id" element={<OnboardingFormPage />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default AppRouters;
