import React, { useEffect, useState } from "react";

import Button from "../../component/button";
import ReusableInput from "../../component/InputField/ReusableInput";
import { FormProvider, useForm } from "react-hook-form";
import NormalDropDown from "../../component/DropDown/NormalDropDown";
import Cookies from "js-cookie";
import {
  useAddClientMutation,
  useUpdateClientMutation,
} from "../../redux/slices/Setting";
import { FaArrowLeft } from "react-icons/fa";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import DateTimePicker from "react-datetime-picker";
import UniversalLoader from "../../component/Loader/UniversalLoader";

const AddClient = ({
  data,
  clientReload=()=>{},
  setShowAddClient,
  editClients = false,
}) => {
  const methods = useForm();
  const { hotelId } = useParams();
  const [propertyType, setPropertyType] = useState("Single Property");
  const [chainName, setChainName] = useState();
  const [assignedTo, setAssignedTo] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState([]);
  const [value, onChange] = useState(new Date());

  const propertyTypeOption = ["Single Property", "Chain Property"];
useEffect(()=>{
  if(propertyType =='Single Property')
  methods.setValue("totalProperty", 1);
},[propertyType])
  //   const assignedToOption = userDropDown?.data;
  const [updateClient ,{isLoading:updateClientLoading}] = useUpdateClientMutation();
  const [addClient ,{isLoading:addClientLoading}] = useAddClientMutation();
  useEffect(() => {
    if (data) {
      methods.setValue("clientName", data?.clientName);
      //   setPropertyType(data?.data?.propertyType);
      methods.setValue("totalProperty", data?.totalProperty);
      methods.setValue("propertyOwnerNumber", data?.mobileNumber);
      methods.setValue("propertyOwnerEmail", data?.email);
      methods.setValue("gstDetail", data?.propertyDetails?.[0]?.gstDetail);
      methods.setValue("gstNumber", data?.propertyDetails?.[0]?.gstNumber);
      methods.setValue("panCard", data?.propertyDetails?.[0]?.panCard);
      methods.setValue("location", data?.location);
      methods.setValue(
        "pointOfContact.pointOfContact",
        data?.pointOfContact?.pointOfContact
      );
      methods.setValue(
        "pointOfContact.designation",
        data?.pointOfContact?.designation
      );
      methods.setValue("pointOfContact.email", data?.pointOfContact?.email);
      methods.setValue("pointOfContact.address", data?.pointOfContact?.address);
      setAssignedTo(data?.data?.assignedTo?.map((item) => item?.fullName));
    }
  }, [data]);

  const companyIds = Cookies.get("company");
  const onSubmit = (data) => {
    if (editClients) {
      updateClient({
        propertyCode: hotelId,
        propertyDetails: {
          clientName: data?.clientName,
          onBordingDate: value,
          totalProperty: data?.totalProperty,
          location: data?.location,
          mobileNumber: data?.propertyOwnerNumber,
          email: data?.propertyOwnerEmail,
          propertyDetails:[{

            gstDetail: data?.gstDetail,
            gstNumber: data?.gstNumber,
            panCard: data?.panCard,
          }],
          // gstDetail: data?.gstDetail,
          // gstNumber: data?.gstNumber,
          // panCard: data?.panCard,
          pointOfContact: data?.pointOfContact,
          companyId: companyIds,
          isRevenue: true,
        },
      })
        .unwrap()
        .then((res) => {
          toast.success(res?.message, { position: "bottom-right" });
          setShowAddClient(false);
          clientReload();
        })
        .catch((err) => {
          toast.error(err?.data?.message, { position: "bottom-right" });
          clientReload();
        });
    } else {
      addClient({
        clientName: data?.clientName,
        onBordingDate: value,
        totalProperty: data?.totalProperty,
        location: data?.location,
        mobileNumber: data?.propertyOwnerNumber,
        propertyDetails:[{

          gstDetail: data?.gstDetail,
          gstNumber: data?.gstNumber,
          panCard: data?.panCard,
        }],
        email: data?.propertyOwnerEmail,
        pointOfContact: data?.pointOfContact,
        companyId: companyIds,
        isRevenue: true,
      })
        .unwrap()
        .then((res) => {
          toast.success(res?.message, { position: "bottom-right" });
          setShowAddClient(false);
          clientReload();
        })
        .catch((err) => {
          toast.error(err?.data?.message, { position: "bottom-right" });
          clientReload();
        });
    }
  };
  //   useEffect(() => {
  //     setDepartmentOptions(departmentData?.data || []);
  //   }, [departmentData?.data]);
  return (
    <div className="w-[100%] bg-lightThemeGradient rounded-lg dark:bg-darkThemeGradient  text-darkThemeFontColor p-4 h-full ">
        <UniversalLoader loading={addClientLoading ||updateClientLoading}/>
      <FormProvider {...methods}>
        <form
          className="flex flex-col h-full w-full justify-start items-between"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <div className="w-full flex justify-between items-center">
            <div className="flex gap-4 items-center">
              <div className="">
                <Button
                  onClick={() => {
                    setShowAddClient(false);
                  }}
                >
                  <FaArrowLeft />
                </Button>
              </div>
              <div className="">
                <h1 className="text-darkThemeFontColor text-2xl font-bold">
                  {editClients ? "Edit Client" : "Add New Client"}
                </h1>

                {editClients ? (
                  <p className="">Let's edit client and assign to your team</p>
                ) : (
                  <p className="">
                    Let's add a new client and assign to your team
                  </p>
                )}
              </div>
            </div>
            <div>
              <Button>Save Client</Button>
            </div>
          </div>

          <div className="grid h-[80%] overflow-y-auto grid-cols-2 gap-4 mt-6">
            <ReusableInput
              label="Client Name"
              name="clientName"
              type="text"
              placeholder="Enter Client Name"
              rules={{ required: "Property Client is required" }}
              showIcon={false}
            />
            {!editClients && (
              <div >
                <h1 className="text-lg font-medium">Property Type</h1>
                <div className="h-12">
                  <NormalDropDown
                    options={propertyTypeOption}
                    label="Select an option"
                    selectedValue={propertyType}
                    setSelectedValue={setPropertyType}
                  />
                </div>
              </div>
            )}
            {propertyType !== "Single Property" && (
              <ReusableInput
                label="Chain Name"
                name="chainName"
                type="text"
                placeholder="Enter Chain Name"
                // rules={{ required: "Property Chain Name is required" }}
                showIcon={false}
              />
            )}
            <div className="col-span-1">
              <p>On Boarding Date</p>
              <div className="h-12 mt-1 border flex  items-center rounded-lg border-white">
                {" "}
                <DateTimePicker
                  onChange={onChange}
                  value={value}
                  format="y-MM-dd"
                  className="w-full relative rounded-lg text-white p-2 dark:text-white"
                  maxDate={new Date()}

                />
              </div>
            </div>

            <ReusableInput
              label="Total Property"
              name="totalProperty"
              type="text"
              placeholder="Enter Total Property Name"
              // rules={{ required: "Property Owner Name is required" }}
              showIcon={false}
            />
            <ReusableInput
              label="Location"
              name="location"
              type="text"
              placeholder="Enter Location"
              // rules={{ required: "Property Location is required" }}
             
            />
            <ReusableInput
              label="Property Owner number"
              name="propertyOwnerNumber"
              type="phoneNumber"
              placeholder="Enter Property Owner number"
              // rules={{ required: "Property Owner number is required" }}
              showIcon={true}
            />
            <ReusableInput
              label="Property Owner Email"
              name="propertyOwnerEmail"
              type="email"
              placeholder="Enter Property Owner Email address"
              // rules={{ required: "Property Owner Email is required" }}
              showIcon={true}
            />
            {/* <ReusableInput
              label="Gst Description"
              name="gstDetail"
              type="text"
              placeholder="Enter GST Description"
              // rules={{ required: "GST Description is required" }}
              showIcon={true}
            /> */}
            <ReusableInput
              label="Gst Number"
              name="gstNumber"
              type="text"
              placeholder="Enter GST Number"
              // rules={{ required: "Property GST Number is required" }}
            
            />
            <ReusableInput
              label="Pancard Number"
              name="panCard"
              type="text"
              placeholder="Enter Pan Number"
              // rules={{ required: "Pan Number is required" }}
      
            />
            <ReusableInput
              label="Point of Contact"
              name="pointOfContact.pointOfContact"
              type="text"
              placeholder="Enter POC "
              // rules={{ required: "POC is required" }}
             
            />
            <ReusableInput
              label="Designation"
              name="pointOfContact.designation"
              type="text"
              placeholder="Enter Designation "
              // rules={{ required: "POC is required" }}
        
            />
            <ReusableInput
              label="Contact Number"
              name="pointOfContact.contactNumber"
              type="phoneNumber"
              placeholder="Enter Phone Number "
              // rules={{ required: "POC is required" }}
             
            />
            <ReusableInput
              label="POC Email"
              name="pointOfContact.email"
              type="email"
              placeholder="Enter POC Email address"
              // rules={{ required: "POC Email is required" }}
            
            />
            <ReusableInput
              label="Address"
              name="pointOfContact.address"
              type="text"
              placeholder="Enter address "
              // rules={{ required: "address is required" }}
              
            />
          </div>

       
        </form>
      </FormProvider>
    </div>
  );
};

export default AddClient;
