import React, { useEffect, useState } from "react";
import gsap from "gsap";
import { FaQrcode } from "react-icons/fa6";
import Cookies from "js-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import { useGSAP } from "@gsap/react";

//icons
import SideBarSwitchButton from "./SubComponent/sideBarSwitchButton";
import { MdOutlineDashboard } from "react-icons/md";
import { IoPeopleOutline } from "react-icons/io5";
import { AiOutlineDashboard } from "react-icons/ai";
import { BiBuildingHouse } from "react-icons/bi";
import { PiNotepad } from "react-icons/pi";
import { IoSettingsOutline } from "react-icons/io5";
import { IoMdArrowBack } from "react-icons/io";

const SideBar = ({ setSelectedItem = () => {}, selectedItem }) => {
  const [expand, setExpand] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleLogout = () => {
    Cookies.remove("token");

    // Redirect to the login page
    window.location.href = "/login";

    // Reload the webpage
    window.location.reload();
  };
  const [contentVisible, setContentVisible] = useState(true); // State for content visibility

  const departments = Cookies.get("departments");
  const department = departments && departments.split(",").map(Number);

  const designation = Cookies.get("designationId")
    ? JSON.parse(Cookies.get("designationId"))
    : [];

  useGSAP(() => {
    const tl = gsap.timeline({
      onComplete: () => {
        setContentVisible(true);
      },
    });

    tl.from(".sideNotch", {
      height: 0,
      duration: 1.8,
      ease: "power2.out",
    }).from(".sideOpi", {
      opacity: 0,
      x: -100,
      duration: 1,
      stagger: 0.3,
      ease: "expo.inOut",
    }); // overlapping with previous animation
    tl.from(
      ".innerSideNotch",
      {
        height: 0,
        duration: 0.5,
        ease: "expo.in",
      },
      "-=150%"
    );
    return () => {
      tl.kill();
    };
  }, []);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleHover = (index) => {
    setActiveIndex(index);
  };

  const handleMouseOut = () => {
    setActiveIndex(null);
  };

  let dashboardSidebarList;

  if (department && department.includes(323878055)) {
    dashboardSidebarList = [
      {
        icon: MdOutlineDashboard,
        isSelected: true,
        actualIndex: 1,
        buttonText: "Dashboard",
        path: "/dashboard",
      },
      {
        icon: IoPeopleOutline,
        isSelected: false,
        actualIndex: 3,
        buttonText: "Clientelé",
        path: "/clients",
      },
      {
        icon: BiBuildingHouse,
        isSelected: false,
        actualIndex: 4,
        buttonText: "Properties",
        path: "/properties",
      },
    ];
  } else {
    dashboardSidebarList = [
      {
        icon: MdOutlineDashboard,
        isSelected: true,
        actualIndex: 1,
        buttonText: "Dashboard",
        path: "/dashboard",
      },

      {
        icon: IoPeopleOutline,
        isSelected: false,
        actualIndex: 3,
        buttonText: "Clientelé",
        path: "/clients",
      },
      {
        icon: BiBuildingHouse,
        isSelected: false,
        actualIndex: 4,
        buttonText: "Properties",
        path: "/properties",
      },
      {
        icon: PiNotepad,
        isSelected: false,
        actualIndex: 5,
        buttonText: "Tasks",
        path: "/task-management",
      },
      {
        icon: IoSettingsOutline,
        isSelected: false,
        actualIndex: 6,
        buttonText: "Settings",
        redirect: "/settings",
        path: "/settings",
      },
    ];
  }

  if (location.pathname.toLowerCase().includes("/setting")) {
    dashboardSidebarList = [
      {
        icon: IoMdArrowBack,
        isSelected: true,
        actualIndex: 5,
        buttonText: "Go Back",
        path: "/dashboard",
      },
      {
        icon: MdOutlineDashboard,
        isSelected: true,
        actualIndex: 1,
        buttonText: "Task Management",
        path: "/settings/task-management",
      },
      {
        icon: AiOutlineDashboard,
        isSelected: false,
        actualIndex: 2,
        buttonText: " Dashboard Management",
        path: "/settings/dashboard-management",
      },
    //   {
    //     icon: IoPeopleOutline,
    //     isSelected: false,
    //     actualIndex: 3,
    //     buttonText: "Employee Management",
    //     path: "/settings/employee-management",
    //   },
      {
        icon: BiBuildingHouse,
        isSelected: false,
        actualIndex: 4,
        buttonText: "Notification Setting",
        path: "/settings/notification-management",
      },
    ];

    if (designation === 968760251 || designation === 96876025 || designation === 19687170) {
        dashboardSidebarList.push({
          icon: IoPeopleOutline,
          isSelected: false,
          actualIndex: 3,
          buttonText: "Employee Management",
          path: "/settings/employee-management",
        });
      }
  }

  useEffect(() => {
    // if (location.pathname.toLowerCase().includes("/setting") && dashboardSidebarList) {
      dashboardSidebarList.forEach((item) => {
        if (location.pathname.toLowerCase().includes(item?.path?.toLowerCase())) {
          setSelectedIndex(item?.actualIndex);
        }
      });
    // }
  }, [location.pathname]);
  
  return (
    <div className="h-[90vh]  flex justify-center items-center">
      {contentVisible && (
        <ul className=" h-fit items-center  px-4   justify-evenly sideNotch ">
          {dashboardSidebarList.map((listItem, ind) => {
            return (
              <li className="" key={ind}>
                <SideBarSwitchButton
                  onMouseOver={() => handleHover(listItem.actualIndex)}
                  onMouseOut={handleMouseOut}
                  setSelectedIndex={setSelectedIndex}
                  Icon={listItem.icon}
                  isFirst={ind === 0 ? true : false}
                  isLast={
                    ind === dashboardSidebarList?.length - 1 ? true : false
                  }
                  isSelected={
                    selectedIndex === listItem?.actualIndex ? true : false
                  }
                  activeIndex={activeIndex}
                  setActiveIndex={setSelectedIndex}
                  actualIndex={listItem.actualIndex}
                  buttonText={listItem.buttonText}
                  action={listItem?.path}
                />
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default SideBar;
