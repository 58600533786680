import React, { useState } from "react";

import UniversalTable from "../../component/Table/UniversalTable";
import Tablewrap from "../../component/Table/TableWrap";
import Button from "../../component/button";

import AddEmployee from "./AddEmployee";
import { useGetUsersListQuery } from "../../redux/slices/Setting";
import UniversalLoader from "../../component/Loader/UniversalLoader";

const AllEmployees = () => {
  const [showAddEmployee, setShowAddEmployee] = useState(false);
  const { data: employeeData, isLoading, isFetching ,refetch:employeeRefetch} = useGetUsersListQuery();
  return (
    <>
      <UniversalLoader loading={isLoading || isFetching} />

      {showAddEmployee ? (
        <AddEmployee getClientRefetch={employeeRefetch} setShowAddEmployee={setShowAddEmployee} />
      ) : (
        <div className="w-full   h-[93vh] overflow-y-auto  bg-rbg p-4 flex flex-col justify-start items-start ">
          <div className="w-[100%] flex justify-between  p-4 ">
            <div className="flex">
              <div>
                <h1 className="text-2xl font-bold">All Employee</h1>

                <p className="">
                  Lets keep a check on your employees, partner!
                </p>
              </div>
            </div>
            <div>
              <Button
                onClick={() => {
                  setShowAddEmployee((prev) => !prev);
                }}
              >
                Add Employees
              </Button>
            </div>
          </div>
          <div className="w-[100%] mt-6 flex-wrap flex justify-between mx-auto ">
            <Tablewrap label={"All Employee"}>
              <UniversalTable
                isLoading={isLoading}
                showActions={"user"}
                dataArray={employeeData?.data}
              />
            </Tablewrap>
          </div>
        </div>
      )}
    </>
  );
};

export default AllEmployees;
