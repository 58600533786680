import React from "react";
import Skeleton from "../Skeleton";
import Chart from "react-apexcharts";
import dataNotFound from "../../assets/icons/404/5.svg";
import { useGetPersonalProgressQuery } from "../../redux/slices/dashboard";
import dayjs from "dayjs"; // To format the date strings
import NegativeScreen from "../NegativeScreen";

const PersonalProgress = () => {
    const { data: personalProgressData, isLoading } = useGetPersonalProgressQuery();

    // Format the dates for the x-axis
    const formattedDates = personalProgressData?.data?.map(item =>
        dayjs(item.date).format("MMM D")
    ) || [];

    // Get the completed tasks for the series data
    const completedTasks = personalProgressData?.data?.map(item => item.completedTask) || [];

    // Define the options for the chart
    const options = {
        chart: {
            type: "line",
            height: 350,
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false,
            },
        },
        stroke: {
            width: 2,
            curve: "straight",
            colors: ["#FFFFFF"],
        },
        xaxis: {
            categories: formattedDates,
            labels: {
                style: {
                    colors: "#FFFFFF", // Tailwind color slate-400
                },
            },
        },
        yaxis: {
            labels: {
                style: {
                    colors: "#FFFFFF", // Tailwind color slate-400
                },
                formatter: function (value) {
                    return value;
                },
            },
        },
        grid: {
            borderColor: "#E2E8F0", // Tailwind color slate-200
        },
        dataLabels: {
            enabled: false,
        },
        tooltip: {
            theme: "dark", // Set the theme to dark
            style: {
                fontSize: '12px',
                fontFamily: undefined,
                colors: ["#FFFFFF"], // Set the tooltip text color to white
            },
        },
    };

    const series = [
        {
            name: "Completed Tasks",
            data: completedTasks,
        },
    ];

    return (
        <div className="w-[100%] rounded-lg py-4  bg-lightThemeGradient dark:bg-darkThemeGradient h-[100%]">
            {isLoading ? (
                <Skeleton />
            ) : personalProgressData?.data ? (
                <>
                    <div className="w-full flex px-4 items-center justify-between gap-2">
                        <h1 className="font-semibold text-textColor">Personal Progress</h1>
                    </div>

                    {personalProgressData?.data?.length > 0 ?
                        <Chart options={options} series={series} type="line" height={350} /> :<div className="w-[98%] flex items-center h-[80%] p-4 justify-center"> <NegativeScreen /> </div>}

                </>
            ) : (
                <div className="w-full flex bg-[#7c8ba130] items-center justify-start h-[90%] p-4 border-2 border-[#7C8BA1] rounded-lg m-2">
                    <img src={dataNotFound} className="h-60 w-60" alt="Data not found" />
                    <div className="w-full flex justify-center">
                        <p className="text-xl font-medium">No recent Activities</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PersonalProgress;
