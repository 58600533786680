import React from 'react';
import Chart from 'react-apexcharts';
import { useGetHotelPerformanceSummeryQuery } from '../../redux/slices/dashboard';
import Skeleton from '../Skeleton';
import NegativeScreen from '../NegativeScreen';

const HotelPerformanceSummery = () => {
    const { data: hotelPerformanceData, isLoading } = useGetHotelPerformanceSummeryQuery();

    // Extract the property names and outcomes from the data
    const propertyNames = hotelPerformanceData?.data?.map(item => item?.propertyName) ||[];
    const outcomes = hotelPerformanceData?.data?.map(item => item?.overallOutcome ||0) || [];

    // Chart options
    const chartOptions = {
        chart: {
            type: 'bar',
            height: 350,
            toolbar: {
                show: false, // Disable the toolbar (removes the download button)
            },
            events: {
                dataPointSelection: (event, chartContext, config) => {
                    // Triggered when a bar is clicked
                }
            }
        },
        plotOptions: {
            bar: {
                columnWidth: '45%',
                distributed: true,
                borderRadius: 7, // Default border-radius
                borderRadiusApplication: 'end', // Apply border-radius only to the top (end of the bar)
            }
        },
        colors: ['#8EC9FF', '#8EC9FF', '#8EC9FF', '#8EC9FF', '#5578EB'], // Custom colors for bars
        states: {
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: 'none',
                }
            },
            normal: {
                filter: {
                    type: 'none',
                }
            },
            hover: {
                filter: {
                    type: 'none',
                }
            },
            selected: {
                filter: {
                    type: 'lighten',
                    value: 0.05 // Lighten selected bar
                }
            }
        },
        dataLabels: {
            enabled: true,
            formatter: (val) => `${val}%`,
            style: {
                colors: ['#FFFFFF'], // Make data labels white
            },
        },
        xaxis: {
            categories: propertyNames || [],
            labels: {
                style: {
                  colors: "#FFFFFF", // Tailwind color slate-400
                },
              },
        },
        yaxis: {
            labels: {
                formatter: (val) => `${val}%`,
                style: {
                    colors: ['#FFFFFF'], // Make Y-axis labels white
                }
            }
        },
        tooltip: {
            theme: 'dark', // Makes tooltip dark-themed (text white)
            y: {
                formatter: (val) => `${val}%`,
            }
        },
        grid: {
            yaxis: {
                lines: {
                    show: true,
                },
            },
        },
        // Styling for active bar (on click)
        states: {
            active: {
                filter: {
                    type: 'none', // No filter, but using custom border
                },
                stroke: {
                    width: 2, // Border width
                    colors: ['#007bff'] // Border color (blue)
                }
            }
        }
    };

    const chartData = [{
        name: 'Performance',
        data: outcomes && outcomes.length > 0 ? outcomes : [0], // Fallback to 0 if outcomes is empty
    }];
    
    return (
        <div className='bg-lightThemeGradient dark:bg-darkThemeGradient w-full rounded-lg '>
            <h2 className='p-4 font-bold' style={{ color: 'white' }}>Hotel Performance Summary</h2>
            <div className=' mr-3'>
            {(!isLoading && Array.isArray(chartData) && chartData?.length > 0 ) ? (
              hotelPerformanceData?.data?.length >0 ?
                <Chart
                    options={chartOptions}
                    series={chartData}
                    type="bar"
                    height={350}
                />:<NegativeScreen/>
                
            ) : (
                <div className='h-[200px]'>
                    <Skeleton />
                </div>
            )}
            </div>
        </div>
    );
};

export default HotelPerformanceSummery;
