import React, { useEffect, useState } from "react";
import RocketIcon from "../../assets/icons/Rocket.svg";
import Login from "../../component/OnBoarding/Login";
import SignUp from "../../component/OnBoarding/SignUp";
import { useLocation, useNavigate } from "react-router-dom";
import RecentLoginCard from "../../component/RecentLoginCard/RecentLoginCard";
import Cookies from "js-cookie";
import getToken from "../../utils/getToken";
import UniversalLoader from "../../component/Loader/UniversalLoader";
import { useIsIpad } from "../../utils/isIpad";
// import { navigate } from "@storybook/addon-links/*";

const LoginPage = () => {
  const location = useLocation();
  const recentLoginData = Cookies.get("recentLogin");
  const [loading, setLoading] = useState(false);
  let parsedLoginData = [];
  try {
    parsedLoginData = recentLoginData ? JSON.parse(recentLoginData) : [];
  } catch (e) {
    console.error("Failed to parse recent login data:", e);
  }
  const [recentLogin, setRecentLogin] = useState(parsedLoginData);
  const [recentLoginEmail, setRecentLoginEmail] = useState("");

  const handleRecentLoginClick = (email) => {
    setRecentLoginEmail(email);
  };
  const isIpad = useIsIpad();
  useEffect(() => {
    const function1 = async () => {
      try {
        const data = await fetch("https://jsonplaceholder.typicode.com/todos/1")
          .then((response) => {
            if (!response.status === "OK") {
              throw new Error("Failed to fetch data");
            }
            return response.json();
          })
          .catch((response) => {
            throw new Error("Failed to fetch");
          });
          
        const dataJsong = data;
        } catch (e) {
        console.log(e, "error");
      }
    };
    function1();
  }, []);

  return (
    <div className="w-[100vw] h-[100vh] min-h-[100vh]  overflow-hidden">
      <UniversalLoader loading={loading} />
      <div
        className={`flex items-center bg-RGreen 0 h-[100%] w-[100%] ${isIpad ? "p-1 " : "p-24"}`}
      >
        <div className=" flex mx-10 py-10  md:gap-6 flex-col h-[100%] w-[100%] md:w-2/4">
          <div className="flex flex-col  text-white">
            <h1 className=" text-xl md:text-2xl font-bold">
              Welcome to R-World
            </h1>
            <p className=" text-sm md:text-2xl ">
              Don't just take our word for it – experience the transformation
              for yourself!
            </p>
          </div>
          <div className="text-white text-sm md:text-base font-[13px]">
            From personalized revenue strategies to operational efficiency and
            beyond
          </div>
        </div>
        <div
          className={` md:absolute ${isIpad ? "left-[50px] h-[400px] w-[285px]" : "top-15 left-[300px] h-[385px] w-[385px]"}    hidden md:flex `}
        >
          <img src={RocketIcon} alt="" />
        </div>

        <div
          className={`absolute ${isIpad ? " w-[800px ]  top-[10vh] right-12" : " w-[500px]  top-[15vh] right-16"} `}
        >
          {location.pathname === "/signup" ? (
            <SignUp setLoading={setLoading} setRecentLogin={setRecentLogin} />
          ) : (
            <Login
              recentLoginEmail={recentLoginEmail}
              setLoading={setLoading}
              setRecentLogin={setRecentLogin}
            />
          )}
        </div>
      </div>
      {location.pathname === "/login" && (
        <div className="hidden  md:flex p-4 flex-col gap-4">
          <h1 className="text-2xl font-bold">Recent Login</h1>
          <div className="flex gap-4 h-auto w-[700px] flex-wrap">
            {recentLogin?.map(
              (item, index) =>
                item && (
                  <div
                    key={index}
                    onClick={() => handleRecentLoginClick(item.email)}
                  >
                    <RecentLoginCard
                      setRecentLogin={setRecentLogin}
                      recentLogin={recentLogin}
                      index={index}
                      name={item.name}
                      email={item.email}
                    />
                  </div>
                )
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginPage;
