import React, { useState } from "react";
import {
  OnBoarding,
  useAddPreOnboardingMutation,
  useGetHotelServicesPreOnboardingQuery,
  useGetOtaDetailsQuery,
  useGetPreOnboardingFormQuery,
} from "../../redux/slices/onBoarding";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { useIsIpad } from "../../utils/isIpad";
import { FaCheck, FaEye, FaEyeSlash } from "react-icons/fa6";
import OTADetail from "./OTADetail";
import { RxCross2 } from "react-icons/rx";
const OnboardingForm = ({
  data: formData,
  index,
  setOnboardingFormData,
  onboardingFormData,
  setShowDetails,
  handleSave1,
  setSelectedIndex,
}) => {
  const location = useLocation();
  const clientId =
    location.pathname?.split("/")[location.pathname?.split("/")?.length - 1];
  const { data: getOnboardingForm } = useGetPreOnboardingFormQuery({
    clientId: clientId,
  });
  const { data } = useGetHotelServicesPreOnboardingQuery();
  const { data: otaDetails } = useGetOtaDetailsQuery();

  const hotelService = data?.data?.map((item) => {
    return {
      serviceName: item?.label,
      serviceValue: item?.value,
      service: true,
      charges: 50,
    };
  });
  //   const otaData = otaDetails?.data?.map((ota) => {
  //     return {
  //       otaName: ota?.otaName,
  //       loginId: "booking_grandplaza",
  //       password: "pass1234",
  //       phoneNo: "+1234567890",
  //       email: "ota@grandplaza.com",
  //       otaUserId: "",
  //       otaImage: ota?.otaImage,
  //     };
  //   });

  // const [onboardingFormData, setFieldData] = useState();

  // useEffect(()=>{
  //   if(formData){
  //     setOnboardingFormData((prevState)=>[...prevState,formData])
  //   }
  // },[formData])
  // useEffect(() => {
  //   if (getOnboardingForm?.data) {
  //     setOnboardingFormData(getOnboardingForm?.data);
  //   }
  // }, [getOnboardingForm?.data]);

  // useEffect(() => {
  //   if (otaData) {
  //     setOnboardingFormData((prevState) => ({
  //       ...prevState,
  //       otaDetails: otaData, // Update the otaDetails directly with otaData
  //     }));
  //   }
  // }, [otaDetails]);
  // useEffect(() => {
  //   if (data) {
  //     setOnboardingFormData((prevState) => ({
  //       ...prevState,
  //       hotelFacility: hotelService, // Update the otaDetails directly with otaData
  //     }));
  //   }
  // }, [data]);

  const [addPreOnboarding, { isLoading }] = useAddPreOnboardingMutation();
  const [currentScreen, setCurrentScreen] = useState({
    otaCredential: true,
    hotelInformation: false,
    roomDetails: false,
    roomFacilityAmenitieshotelFacility: false,
    banquetDetails: false,
    "f&b_Details": false,
  });

  const handleNext = () => {
    if (currentScreen.otaCredential) {
      setCurrentScreen({
        otaCredential: false,
        hotelInformation: true,
        roomDetails: false,
        roomFacilityAmenitieshotelFacility: false,
        banquetDetails: false,
        "f&b_Details": false,
      });
    } else if (currentScreen.hotelInformation) {
    
      if (
        onboardingFormData?.compsetDetail?.compsetNames.length ==
        Number(onboardingFormData?.compsetDetail?.totalCompset)
      ) {
        setCurrentScreen({
          otaCredential: false,
          hotelInformation: false,
          roomDetails: true,
          roomFacilityAmenitieshotelFacility: false,
          banquetDetails: false,
          "f&b_Details": false,
        });
      } else {
        alert("Total Number of compset and entered compset should be equal ");
      }
    } else if (currentScreen.roomDetails) {
      setCurrentScreen({
        otaCredential: false,
        hotelInformation: false,
        roomDetails: false,
        roomFacilityAmenitieshotelFacility: true,
        banquetDetails: false,
        "f&b_Details": false,
      });
    } else if (currentScreen.roomFacilityAmenitieshotelFacility) {
      setCurrentScreen({
        otaCredential: false,
        hotelInformation: false,
        roomDetails: false,
        roomFacilityAmenitieshotelFacility: false,
        banquetDetails: true,
        "f&b_Details": false,
      });
    } else if (currentScreen.banquetDetails) {
      setCurrentScreen({
        otaCredential: false,
        hotelInformation: false,
        roomDetails: false,
        roomFacilityAmenitieshotelFacility: false,
        banquetDetails: false,
        "f&b_Details": true,
      });
    }
  };

  const handleBack = () => {
    if (currentScreen.hotelInformation) {
      setCurrentScreen({
        otaCredential: true,
        hotelInformation: false,
        roomDetails: false,
        roomFacilityAmenitieshotelFacility: false,
        banquetDetails: false,
        "f&b_Details": false,
      });
    } else if (currentScreen.roomDetails) {
      setCurrentScreen({
        otaCredential: false,
        hotelInformation: true,
        roomDetails: false,
        roomFacilityAmenitieshotelFacility: false,
        banquetDetails: false,
        "f&b_Details": false,
      });
    } else if (currentScreen.roomFacilityAmenitieshotelFacility) {
      setCurrentScreen({
        otaCredential: false,
        hotelInformation: false,
        roomDetails: true,
        roomFacilityAmenitieshotelFacility: false,
        banquetDetails: false,
        "f&b_Details": false,
      });
    } else if (currentScreen.banquetDetails) {
      setCurrentScreen({
        otaCredential: false,
        hotelInformation: false,
        roomDetails: false,
        roomFacilityAmenitieshotelFacility: true,
        banquetDetails: false,
        "f&b_Details": false,
      });
    } else if (currentScreen["f&b_Details"]) {
      setCurrentScreen({
        otaCredential: false,
        hotelInformation: false,
        roomDetails: false,
        roomFacilityAmenitieshotelFacility: false,
        banquetDetails: true,
        "f&b_Details": false,
      });
    }
  };

  const handleSave = () => {
    setShowDetails(false);
    setSelectedIndex();
    handleSave1();
    // addPreOnboarding()
    //   .unwrap()
    //   .then((res) => {
    //     toast.success("Pre onboarding data saved successfully!", {
    //       position: "bottom-right"
    //     });
    //   })
    //   .catch((err) => {
    //     toast.error("Something went wrong!", { position: "bottom-right" });
    //   });
  };

  const handleChange = (path, value, objIndex, arrayIndex = null, isToken) => {
    setOnboardingFormData((prevState) => {
      const newState = prevState.map((obj, index) =>
        index === objIndex ? { ...obj } : obj
      ); // Copy the entire array and target object immutably

      const keys = path.split(".");

      if (arrayIndex !== null) {
        const arrayKey = keys[0];
        const field = keys.slice(1).join(".");
        debugger;
        if (Array.isArray(newState[objIndex][arrayKey])) {
          newState[objIndex][arrayKey] = newState[objIndex][arrayKey].map(
            (item, i) => {
              if (i === arrayIndex) {
                return { ...item, [field]: value }; // Update the specific field in array item
              }
              return item;
            }
          );
        }
      } else if (isToken) {
        newState[objIndex][keys[0]][keys[1]] = [
          ...(newState[objIndex][keys[0]][keys[1]] || []), // Ensure it's an array
          value, // Add the new value
        ];

        return newState;
      } else {
        // const arrayKey = keys[0];
        // const obj = keys.reduce((acc, currKey, i) => {
        //   debugger;
        //   if (i === keys.length - 1) {
        //     // Clone the nested object to avoid mutation
        //     acc[currKey] = value;
        //   }
        //   return acc[currKey] ? { ...acc[currKey] } : acc[currKey];
        // }, newState[objIndex]);

        newState[objIndex][keys[0]][keys[1]] = value;
      }

      return newState;
    });
  };

  // Add a restaurant to a specific object in the array
  const addRestaurant = (objIndex) => {
    const newRestaurant = {
      name: "",
      cover: "",
      breakfastStart: "",
      breakfastEnd: "",
      lunchStart: "",
      lunchEnd: "",
      dinnerStart: "",
      dinnerEnd: "",
      menuType: "",
    };

    setOnboardingFormData((prevState) => {
      const newState = [...prevState];
      newState[objIndex].restaurantDetails = [
        ...newState[objIndex].restaurantDetails,
        newRestaurant,
      ];
      return newState;
    });
  };

  // Delete a restaurant from a specific object in the array
  const deleteRestaurant = (objIndex, index) => {
    setOnboardingFormData((prevState) => {
      const newState = [...prevState];
      newState[objIndex].restaurantDetails = newState[
        objIndex
      ].restaurantDetails.filter((_, i) => i !== index);
      return newState;
    });
  };

  // Add a banquet to a specific object in the array
  const addBanquet = (objIndex) => {
    const newBanquet = {
      hallName: "",
      pax: "",
    };

    setOnboardingFormData((prevState) => {
      const newState = [...prevState]; // Create a shallow copy of the current state
      newState[objIndex] = {
        ...newState[objIndex], // Spread the existing properties of the object at objIndex
        banquet: [...newState[objIndex].banquet, newBanquet], // Add the new banquet to the banquet array
      };
      return newState; // Return the updated state
    });
  };

  // Delete a banquet from a specific object in the array
  const deleteBanquet = (objIndex, index) => {
    setOnboardingFormData((prevState) => {
      const newState = [...prevState];
      newState[objIndex].banquet = newState[objIndex].banquet.filter(
        (_, i) => i !== index
      );
      return newState;
    });
  };

  // Add a room to a specific object in the array
  const handleAddMoreRoomDetails = (objIndex) => {
    const newRoom = {
      categoryName: "",
      roomType: "",
      size: "",
      noOfRooms: "",
      floor: "",
      minOccupancy: "",
      maxOccupancy: "",
      extraBed: false,
      typeOfExtraBed: "",
      extraBedTariff: 0,
      basicTariff: "",
      mealPlan: "",
      rateDiffInMealPlan: "",
      bedType: "",
      desciption: "",
    };

    setOnboardingFormData((prevState) => {
      const newState = [...prevState];
      newState[objIndex].roomDetails = [
        ...newState[objIndex].roomDetails,
        newRoom,
      ];
      return newState;
    });
  };
  // Delete a room from a specific object in the array
  const handleDeleteRoomDetails = (objIndex, index) => {
    setOnboardingFormData((prevState) => {
      const newState = [...prevState];
      newState[objIndex].roomDetails = newState[objIndex].roomDetails.filter(
        (_, i) => i !== index
      );
      return newState;
    });
  };

  return (
    <div className="w-[100%] relative rounded-lg  p-4 bg-lightThemeGradient z-10  dark:bg-darkThemeGradient h-[85vh]">
      <div className="flex xs:block justify-between items-center">
        <div className="text-lg font-semibold ">
          Onboarding Form &nbsp;
          <span className="">
            ({currentScreen?.otaCredential && "OTA Credentials"}
            {currentScreen?.hotelInformation && "Hotel Information"}
            {currentScreen?.roomDetails && "Room Details"}
            {currentScreen?.roomFacilityAmenitieshotelFacility &&
              "Hotel & Room Facility Amenities"}
            {currentScreen?.banquetDetails && "Banquet Details"}
            {currentScreen["f&b_Details"] && "F&B Details"})
          </span>
        </div>
        <div className="flex gap-2 xs:mt-2 items-center">
          <div
            onClick={() => {
              setSelectedIndex();
              setShowDetails(false);
            }}
            className="bg-gray-100 hover:bg-gray-500 cursor-pointer hover:text-white duration-150 transition-all ease-in-out text-gray-600 px-4 py-1 text-sm rounded-md"
          >
            Go to Main Page
          </div>
          {!currentScreen?.otaCredential && "OTA Credentials" && (
            <div
              onClick={handleBack}
              className="bg-red-100 hover:bg-red-500 cursor-pointer hover:text-white duration-150 transition-all ease-in-out text-red-500 px-4 py-1 text-sm rounded-md"
            >
              Back
            </div>
          )}
          <div
            onClick={currentScreen["f&b_Details"] ? handleSave : handleNext}
            className="bg-blue-100 whitespace-nowrap hover:bg-blue-500 cursor-pointer hover:text-white duration-150 transition-all ease-in-out text-blue-500 px-4 py-1 text-sm rounded-md"
          >
            {currentScreen["f&b_Details"] ? "Save" : "Save & Next"}
          </div>
        </div>
      </div>
      {/* <div className="text-xl my-4 font-semibold">
       
      </div> */}
      <div className="mt-8  md:h-[100%]">
        {currentScreen.otaCredential && (
          <OTA_Credentials
            data={onboardingFormData}
            handleChange={handleChange}
            index={index}
          />
        )}
        {currentScreen.hotelInformation && (
          <HotelDetails
            index={index}
            data={onboardingFormData}
            handleChange={handleChange}
            setOnboardingFormData={setOnboardingFormData}
          />
        )}
        {currentScreen.roomDetails && (
          <RoomDetails
            data={onboardingFormData}
            handleChange={handleChange}
            handleDeleteRoomDetails={handleDeleteRoomDetails}
            handleAddMoreRoomDetails={handleAddMoreRoomDetails}
            index={index}
          />
        )}
        {currentScreen.roomFacilityAmenitieshotelFacility && (
          <RoomAndHotelFacilities
            data={onboardingFormData}
            handleChange={handleChange}
            index={index}
          />
        )}
        {currentScreen.banquetDetails && (
          <Banquet
            data={onboardingFormData}
            handleChange={handleChange}
            addBanquet={addBanquet}
            deleteBanquet={deleteBanquet}
            index={index}
          />
        )}
        {currentScreen["f&b_Details"] && (
          <FNB_Details
            data={onboardingFormData}
            handleChange={handleChange}
            addRestaurant={addRestaurant}
            deleteRestaurant={deleteRestaurant}
            index={index}
          />
        )}
      </div>
    </div>
  );
};

export default OnboardingForm;

const OTA_Credentials = ({ data, handleChange, index }) => {
  const isIpad = useIsIpad();
  const [viewPassword, setViewPassword] = useState();
  return (
    <div>
      <div className="w-[100%] mx-auto">
        <div className="mb-6 text-lg font-semibold border-b">
          Enter OTA Details
        </div>
        <div className="max-h-[500px] overflow-y-scroll">
          {Array.isArray(data?.otaDetails) &&
            data?.otaDetails?.map((ota, objIndex) => {
              return (
                <OTADetail
                  ota={ota}
                  objIndex={objIndex}
                  index={index}
                  handleChange={handleChange}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};
const HotelDetails = ({ data, handleChange, index, setOnboardingFormData }) => {
  const isIpad = useIsIpad();
  const removeToken = (path, objIndex, indexx) => {
    setOnboardingFormData((prevState) => {
      const newState = prevState.map((obj, index) =>
        index === objIndex ? { ...obj } : obj
      );
      const keys = path.split(".");

      newState[objIndex][keys[0]][keys[1]] = newState[objIndex][keys[0]][
        keys[1]
      ].filter((item, index) => index !== indexx);
      return newState;
    });
  };

  const [showDropDown, setShowDropDown] = useState(false);
  return (
    <div className="h-full">
      <div className="w-[100%] h-[90%] overflow-y-scroll mx-auto">
        <div className="mb-4 text-lg font-semibold border-b">Hotel Details</div>
        <div
          className={`grid ${isIpad ? "grid-cols-3" : "xs:grid-cols-1  lg:grid-cols-4 xl:grid-cols-4 grid-cols-2"} gap-4`}
        >
          <div className="mb-4">
            <div className="text-sm">Hotel Name</div>
            <div className="text-sm mt-1">
              <input
                value={data?.hotelDetails?.hotelName}
                onChange={(e) =>
                  handleChange("hotelDetails.hotelName", e.target.value, index)
                }
                className="px-2 xs:w-[100%] h-8 text-sm text-black rounded-md focus:outline-none"
                placeholder="Enter OTA Name"
              />
            </div>
          </div>
          <div className="mb-4">
            <div className="text-sm">Star Category</div>

            <div
              className="relative"
              onClick={() => setShowDropDown((prev) => !prev)}
            >
              <input
                type="text"
                value={data?.hotelDetails?.starCategory}
                min={1}
                max={7}
                className="px-2 h-8 min-w-[162px] xs:w-[100%] text-sm text-black rounded-md focus:outline-none"
                placeholder="Enter Star Category"
              />

              {showDropDown && (
                <div className="bg-white absolute min-w-[162px] mt-1 xs:w-[100%] rounded-md text-black px-2 z-10 shadow-md">
                  {Array(5)
                    .fill("")
                    .map((_, idx) => (
                      <div
                        key={idx}
                        onMouseDown={() => {
                          handleChange(
                            "hotelDetails.starCategory",
                            `${idx + 1} star`,
                            index
                          );
                          setShowDropDown(false);
                        }}
                        className="cursor-pointer hover:bg-gray-200 p-1 rounded-md"
                      >
                        {idx + 1} star
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>

          <div className="mb-4">
            <div className="text-sm">Type of Property</div>
            <div className="text-sm mt-1">
              <input
                value={data?.hotelDetails?.typeOfProperty}
                onChange={(e) =>
                  handleChange(
                    "hotelDetails.typeOfProperty",
                    e.target.value,
                    index
                  )
                }
                className="px-2 xs:w-[100%] h-8 text-sm text-black rounded-md focus:outline-none"
                placeholder="Enter Type Of Property"
              />
            </div>
          </div>
          <div className="mb-4">
            <div className="text-sm">Location</div>
            <div className="text-sm mt-1">
              <input
                value={data?.hotelDetails?.location}
                onChange={(e) =>
                  handleChange("hotelDetails.location", e.target.value, index)
                }
                className="px-2 xs:w-[100%] h-8 text-sm text-black rounded-md focus:outline-none"
                placeholder="Enter Location"
              />
            </div>
          </div>
          <div className="mb-4">
            <div className="text-sm">Address</div>
            <div className="text-sm mt-1">
              <textarea
                className="px-2 text-sm xs:w-[100%] text-black rounded-md focus:outline-none"
                placeholder="Enter Address"
                value={data?.hotelDetails?.address}
                onChange={(e) =>
                  handleChange("hotelDetails.address", e.target.value, index)
                }
              />
            </div>
          </div>
          <div className="mb-4">
            <div className="text-sm">Nearest Airport</div>
            <div className="text-sm mt-1">
              <input
                value={data?.hotelDetails?.nearestAirport}
                onChange={(e) =>
                  handleChange(
                    "hotelDetails.nearestAirport",
                    e.target.value,
                    index
                  )
                }
                className="px-2 xs:w-[100%] h-8 text-sm text-black rounded-md focus:outline-none"
                placeholder="Enter Nearest Airport"
              />
            </div>
          </div>
          <div className="mb-4">
            <div className="text-sm">Distance from Airport</div>
            <div className="text-sm mt-1">
              <input
                value={data?.hotelDetails?.distanceFromAirport}
                onChange={(e) =>
                  handleChange(
                    "hotelDetails.distanceFromAirport",
                    e.target.value,
                    index
                  )
                }
                className="px-2 xs:w-[100%] h-8 text-sm text-black rounded-md focus:outline-none"
                placeholder="Enter Distance"
              />
            </div>
          </div>
          <div className="mb-4">
            <div className="text-sm">Nearest Railway Station</div>
            <div className="text-sm mt-1">
              <input
                value={data?.hotelDetails?.nearestRailwayStation}
                onChange={(e) =>
                  handleChange(
                    "hotelDetails.nearestRailwayStation",
                    e.target.value,
                    index
                  )
                }
                className="px-2 xs:w-[100%] h-8 text-sm text-black rounded-md focus:outline-none"
                placeholder="Enter Nearest Railway Station"
              />
            </div>
          </div>
          <div className="mb-4">
            <div className="text-sm">Distance from Railway Station</div>
            <div className="text-sm mt-1">
              <input
                value={data?.hotelDetails?.distanceFromRailwayStation}
                onChange={(e) =>
                  handleChange(
                    "hotelDetails.distanceFromRailwayStation",
                    e.target.value,
                    index
                  )
                }
                className="px-2 xs:w-[100%] h-8 text-sm text-black rounded-md focus:outline-none"
                placeholder="Enter Distance"
              />
            </div>
          </div>
        </div>

        <div className="my-4 text-lg font-semibold border-b">
          Contact Details
        </div>
        <div
          className={`grid ${isIpad ? "grid-cols-3" : "xs:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 grid-cols-2"} gap-4`}
        >
          <div className="mb-4">
            <div className="text-sm">Telephone No.</div>
            <div className="text-sm mt-1">
              <input
                value={data?.contactDetails?.telephoneNo}
                onChange={(e) =>
                  handleChange(
                    "contactDetails.telephoneNo",
                    e.target.value.replace(/[^0-9]/g, ""),
                    index
                  )
                }
                max={10}
                className="px-2 xs:w-[100%] h-8 text-sm text-black rounded-md focus:outline-none"
                placeholder="Enter Telephone no."
              />
            </div>
          </div>
          <div className="mb-4">
            <div className="text-sm">Mobile No.</div>
            <div className="text-sm mt-1">
              <input
                value={data?.contactDetails?.mobileNo}
                onChange={(e) =>
                  handleChange(
                    "contactDetails.mobileNo",
                    e.target.value.replace(/[^0-9]/g, ""),
                    index
                  )
                }
                max={10}
                className="px-2 h-8 min-w-[162px] text-sm text-black rounded-md focus:outline-none"
                placeholder="Enter Mobile No."
              />
            </div>
          </div>
          <div className="mb-4">
            <div className="text-sm">Fax No.</div>
            <div className="text-sm mt-1">
              <input
                value={data?.contactDetails?.faxNo}
                onChange={(e) =>
                  handleChange(
                    "contactDetails.faxNo",
                    e.target.value.replace(/[^0-9]/g, ""),
                    index
                  )
                }
                className="px-2 xs:w-[100%] h-8 text-sm text-black rounded-md focus:outline-none"
                placeholder="Enter Fax No."
              />
            </div>
          </div>
          <div className="mb-4">
            <div className="text-sm">Email</div>
            <div className="text-sm mt-1">
              <input
                value={data?.contactDetails?.email}
                onChange={(e) =>
                  handleChange("contactDetails.email", e.target.value, index)
                }
                type="email"
                className="px-2 xs:w-[100%] h-8 text-sm text-black rounded-md focus:outline-none"
                placeholder="Enter Email"
              />
            </div>
          </div>
          <div className="mb-4">
            <div className="text-sm">Website</div>
            <div className="text-sm mt-1">
              <input
                value={data?.contactDetails?.website}
                onChange={(e) =>
                  handleChange("contactDetails.website", e.target.value, index)
                }
                className="px-2 xs:w-[100%] h-8 text-sm text-black rounded-md focus:outline-none"
                placeholder="Enter Website"
              />
            </div>
          </div>
        </div>
        <div className="my-4 text-lg font-semibold border-b">Bank Details</div>
        <div
          className={`grid ${isIpad ? "grid-cols-3" : "xs:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 grid-cols-2"} gap-4`}
        >
          <div className="mb-4">
            <div className="text-sm">Account Name</div>
            <div className="text-sm mt-1">
              <input
                value={data?.bankDetails?.accountName}
                onChange={(e) =>
                  handleChange("bankDetails.accountName", e.target.value, index)
                }
                className="px-2 xs:w-[100%] h-8 text-sm text-black rounded-md focus:outline-none"
                placeholder="Enter Account Name"
              />
            </div>
          </div>
          <div className="mb-4">
            <div className="text-sm">Account No.</div>
            <div className="text-sm mt-1">
              <input
                value={data?.bankDetails?.accountNo}
                onChange={(e) =>
                  handleChange("bankDetails.accountNo", e.target.value, index)
                }
                className="px-2 h-8 min-w-[162px] text-sm text-black rounded-md focus:outline-none"
                placeholder="Enter Account No."
              />
            </div>
          </div>
          <div className="mb-4">
            <div className="text-sm">Bank Name</div>
            <div className="text-sm mt-1">
              <input
                value={data?.bankDetails?.bankName}
                onChange={(e) =>
                  handleChange("bankDetails.bankName", e.target.value, index)
                }
                className="px-2 xs:w-[100%] h-8 text-sm text-black rounded-md focus:outline-none"
                placeholder="Enter Bank Name"
              />
            </div>
          </div>
          <div className="mb-4">
            <div className="text-sm">Branch Code</div>
            <div className="text-sm mt-1">
              <input
                value={data?.bankDetails?.branchCode}
                onChange={(e) =>
                  handleChange("bankDetails.branchCode", e.target.value, index)
                }
                className="px-2 xs:w-[100%] h-8 text-sm text-black rounded-md focus:outline-none"
                placeholder="Enter Branch Code"
              />
            </div>
          </div>
          <div className="mb-4">
            <div className="text-sm">IFSC Code</div>
            <div className="text-sm mt-1">
              <input
                value={data?.bankDetails?.ifscCode}
                onChange={(e) =>
                  handleChange("bankDetails.ifscCode", e.target.value, index)
                }
                className="px-2 xs:w-[100%] h-8 text-sm text-black rounded-md focus:outline-none"
                placeholder="Enter IFSC Code"
              />
            </div>
          </div>
          <div className="mb-4">
            <div className="text-sm">MICR Code</div>
            <div className="text-sm mt-1">
              <input
                value={data?.bankDetails?.micrCode}
                onChange={(e) =>
                  handleChange("bankDetails.micrCode", e.target.value, index)
                }
                className="px-2 xs:w-[100%] h-8 text-sm text-black rounded-md focus:outline-none"
                placeholder="Enter MICR Code"
              />
            </div>
          </div>
        </div>
        <div className="my-4 text-lg font-semibold border-b">
          Hotel Contacts
        </div>
        <div
          className={`grid ${isIpad ? "grid-cols-3" : "xs:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 grid-cols-2"} gap-4`}
        >
          <div className="mb-4">
            <div className="text-sm">Enter Front Office Manager Name</div>
            <div className="text-sm mt-1">
              <input
                value={data?.hotelContacts?.frontOfficeManager}
                onChange={(e) =>
                  handleChange(
                    "hotelContacts.frontOfficeManager",
                    e.target.value,
                    index
                  )
                }
                className="px-2 xs:w-[100%] h-8 text-sm text-black rounded-md focus:outline-none"
                placeholder="Enter Front Office Manager Name"
              />
            </div>
          </div>
          <div className="mb-4">
            <div className="text-sm">Enter General Manager Name</div>
            <div className="text-sm mt-1">
              <input
                value={data?.hotelContacts?.generalManager}
                onChange={(e) =>
                  handleChange(
                    "hotelContacts.generalManager",
                    e.target.value,
                    index
                  )
                }
                className="px-2 xs:w-[100%] h-8 text-sm text-black rounded-md focus:outline-none"
                placeholder="Enter General Manager Name"
              />
            </div>
          </div>
          <div className="mb-4">
            <div className="text-sm">Enter Revenue Operation (POC)</div>
            <div className="text-sm mt-1">
              <input
                value={data?.hotelContacts?.revenueOperationPOC}
                onChange={(e) =>
                  handleChange(
                    "hotelContacts.revenueOperationPOC",
                    e.target.value,
                    index
                  )
                }
                className="px-2 xs:w-[100%] h-8 text-sm text-black rounded-md focus:outline-none"
                placeholder="Enter Revenue Operation (POC)"
              />
            </div>
          </div>
        </div>
        <div className="my-4 text-lg font-semibold border-b">
          Compset Detail
        </div>
        <div
          className={`grid ${isIpad ? "grid-cols-3" : "xs:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 grid-cols-2"} gap-4`}
        >
          <div className="mb-4">
            <div className="text-sm">Total Compset</div>
            <div className="text-sm mt-1">
              <input
                type={"number"}
                value={data?.compsetDetail?.totalComposet}
                onChange={(e) =>
                  handleChange(
                    "compsetDetail.totalCompset",
                    e.target.value,
                    index
                  )
                }
                className="px-2 xs:w-[100%] h-8 text-sm text-black rounded-md focus:outline-none"
                placeholder="Enter Total number of compset"
              />
            </div>
          </div>
          <div className="mb-4">
            <div className="text-sm">Enter Compset Name</div>
            <div className="text-sm mt-1">
              <input
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault(); // Prevent default Enter key behavior

                    // Check if compsetNames length exceeds totalComposet
                    const currentCompsetNames =
                      data?.compsetDetail?.compsetNames || [];
                    const totalCompset = Number(
                      data?.compsetDetail?.totalCompset
                    );

                    if (currentCompsetNames.length >= totalCompset) {
                      alert(
                        `You can't add more than ${totalCompset} compset names.`
                      );
                      return; // Stop execution if limit is reached
                    }

                    // If validation passes, call handleChange
                    handleChange(
                      "compsetDetail.compsetNames",
                      e.target.value, // Pass the new value to add to the array
                      index,
                      null,
                      true
                    );

                    e.target.value = ""; // Clear input field after submission
                  }
                }}
                className="px-2 xs:w-[100%] h-8 text-sm text-black rounded-md focus:outline-none"
                placeholder="Enter Compset Name"
              />
              <div className="flex gap-2 mt-4">
                {data?.compsetDetail?.compsetNames.map(
                  (ticket, ticketIndex) => (
                    <div
                      key={ticketIndex}
                      onClick={() =>
                        removeToken(
                          "compsetDetail.compsetNames",
                          index,
                          ticketIndex
                        )
                      }
                      className="flex items-center bg-gray-200 text-black py-2 px-4 rounded-lg relative"
                    >
                      <span>{ticket}</span>
                      <button
                        onClick={() => {}}
                        className="absolute -top-2 right-0 text-red-500 font-bold text-lg"
                      >
                        &times;
                      </button>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const RoomDetails = ({
  data,
  handleChange,
  handleDeleteRoomDetails,
  handleAddMoreRoomDetails,
  index,
}) => {
  const isIpad = useIsIpad();
  return (
    <div>
      <div className="w-[100%] max-h-[550px] overflow-y-scroll mx-auto">
        <div className="mb-4 text-lg font-semibold border-b">Room Details</div>
        <div className="w-[100%]">
          {Array.isArray(data?.roomDetails) &&
            data?.roomDetails?.map((room, roomIndex) => {
              return (
                <div
                  className={`grid  ${isIpad ? "grid-cols-3" : "xs:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 grid-cols-2"}  mb-4 gap-4`}
                >
                  <div className="mb-4">
                    <div className="text-sm">
                      Category Name #{roomIndex + 1}
                    </div>
                    <div className="text-sm mt-1">
                      <input
                        value={room?.categoryName}
                        onChange={(e) =>
                          handleChange(
                            "roomDetails.categoryName",
                            e.target.value,
                            roomIndex,
                            index
                          )
                        }
                        className="px-2 xs:w-[100%] h-8 text-sm text-black rounded-md focus:outline-none"
                        placeholder="Enter Category Name"
                      />
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="text-sm">Type of Room</div>
                    <div className="text-sm mt-1">
                      <input
                        value={room?.roomType}
                        onChange={(e) =>
                          handleChange(
                            "roomDetails.roomType",
                            e.target.value,
                            roomIndex,
                            index
                          )
                        }
                        className="px-2 xs:w-[100%] h-8 text-sm text-black rounded-md focus:outline-none"
                        placeholder="Enter Room Type"
                      />
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="text-sm">Room Size</div>
                    <div className="text-sm mt-1">
                      <input
                        value={room?.size}
                        onChange={(e) =>
                          handleChange(
                            "roomDetails.size",
                            e.target.value,
                            roomIndex,
                            index
                          )
                        }
                        type="number"
                        className="px-2 xs:w-[100%] h-8 text-sm text-black rounded-md focus:outline-none"
                        placeholder="Enter Room Size"
                      />
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="text-sm">Number of Rooms</div>
                    <div className="text-sm mt-1">
                      <input
                        value={room?.noOfRooms}
                        onChange={(e) =>
                          handleChange(
                            "roomDetails.noOfRooms",
                            e.target.value,
                            roomIndex,
                            index
                          )
                        }
                        type="number"
                        className="px-2 xs:w-[100%] h-8 text-sm text-black rounded-md focus:outline-none"
                        placeholder="Enter No. of Rooms"
                      />
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="text-sm">Floor</div>
                    <div className="text-sm mt-1">
                      <input
                        value={room?.floor}
                        type="number"
                        onChange={(e) =>
                          handleChange(
                            "roomDetails.floor",
                            e.target.value,
                            roomIndex,
                            index
                          )
                        }
                        className="px-2 xs:w-[100%] h-8 text-sm text-black rounded-md focus:outline-none"
                        placeholder="Enter Floor"
                      />
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="text-sm">Minimum Occupancy</div>
                    <div className="text-sm mt-1">
                      <input
                        value={room?.minOccupancy}
                        onChange={(e) =>
                          handleChange(
                            "roomDetails.minOccupancy",
                            e.target.value,
                            roomIndex,
                            index
                          )
                        }
                        className="px-2 xs:w-[100%] h-8 text-sm text-black rounded-md focus:outline-none"
                        placeholder="Enter Min. Occupancy"
                      />
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="text-sm">Maximum Occupancy</div>
                    <div className="text-sm mt-1">
                      <input
                        value={room?.maxOccupancy}
                        onChange={(e) =>
                          handleChange(
                            "roomDetails.maxOccupancy",
                            e.target.value,
                            roomIndex,
                            index
                          )
                        }
                        type="number"
                        className="px-2 xs:w-[100%] h-8 text-sm text-black rounded-md focus:outline-none"
                        placeholder="Enter Max. Occupancy"
                      />
                    </div>
                  </div>
                  {/* switch component for boolean value */}
                  {/* <div className="mb-4">
                    <div className="text-sm">Enter Extra Bed</div>
                    <div className="text-sm mt-1">
                      <input
                        value={room?.extraBed}
                        onChange={(e) =>
                          handleChange(
                            "roomDetails.extraBed",
                            e.target.value,
                            roomIndex
                          )
                        }
                        className="px-2 xs:w-[100%] h-8 text-sm text-black rounded-md focus:outline-none"
                        placeholder="Extra Bed"
                      />
                    </div>
                  </div> */}
                  <div className="mb-4">
                    <div className="text-sm">Type of Extra Bed</div>
                    <div className="text-sm mt-1">
                      <input
                        value={room?.typeOfExtraBed}
                        onChange={(e) =>
                          handleChange(
                            "roomDetails.typeOfExtraBed",
                            e.target.value,
                            roomIndex,
                            index
                          )
                        }
                        className="px-2 xs:w-[100%] h-8 text-sm text-black rounded-md focus:outline-none"
                        placeholder="Enter Type of Extra Bed"
                      />
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="text-sm">Extra Bed Tariff</div>
                    <div className="text-sm mt-1">
                      <input
                        value={room?.extraBedTariff}
                        onChange={(e) =>
                          handleChange(
                            "roomDetails.extraBedTariff",
                            e.target.value,
                            roomIndex,
                            index
                          )
                        }
                        type="number"
                        className="px-2 xs:w-[100%] h-8 text-sm text-black rounded-md focus:outline-none"
                        placeholder="Enter Extra Bed Tariff"
                      />
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="text-sm">Basic Tariff</div>
                    <div className="text-sm mt-1">
                      <input
                        value={room?.basicTariff}
                        onChange={(e) =>
                          handleChange(
                            "roomDetails.basicTariff",
                            e.target.value,
                            roomIndex,
                            index
                          )
                        }
                        className="px-2 xs:w-[100%] h-8 text-sm text-black rounded-md focus:outline-none"
                        placeholder="Enter Basic Tariff"
                      />
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="text-sm">Meal Plan</div>
                    <div className="text-sm mt-1">
                      <input
                        value={room?.mealPlan}
                        onChange={(e) =>
                          handleChange(
                            "roomDetails.mealPlan",
                            e.target.value,
                            roomIndex,
                            index
                          )
                        }
                        className="px-2 xs:w-[100%] h-8 text-sm text-black rounded-md focus:outline-none"
                        placeholder="Enter Meal Plan"
                      />
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="text-sm">Bed Type</div>
                    <div className="text-sm mt-1">
                      <input
                        value={room?.bedType}
                        onChange={(e) =>
                          handleChange(
                            "roomDetails.bedType",
                            e.target.value,
                            roomIndex,
                            index
                          )
                        }
                        className="px-2 xs:w-[100%] h-8 text-sm text-black rounded-md focus:outline-none"
                        placeholder="Enter Bed Type"
                      />
                    </div>
                  </div>
                  <div className="mb-4 col-span-3">
                    <div className="text-sm">Room Description/Remarks</div>
                    <div className="text-sm flex items-start gap-4 mt-1">
                      <textarea
                        value={room?.desciption}
                        onChange={(e) =>
                          handleChange(
                            "roomDetails.desciption",
                            e.target.value,
                            roomIndex,
                            index
                          )
                        }
                        rows={5}
                        cols={50}
                        className="px-2  text-sm text-black rounded-md focus:outline-none"
                        placeholder="Enter Desciption"
                      />
                      <div
                        className="flex items-center w-fit  bg-red-100 px-4 py-1 rounded-lg text-red-500 text-sm h-fit -mt-[0px] cursor-pointer"
                        onClick={() =>
                          handleDeleteRoomDetails(index, roomIndex)
                        }
                      >
                        Delete
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <div className="w-[100%] pt-4 mx-auto">
        <div
          onClick={() => handleAddMoreRoomDetails(index)}
          className="w-fit cursor-pointer bg-blue-100 text-sm hover:text-white hover:bg-blue-500 transition-all ease-in-out duration-150 text-blue-500 px-4 py-1 rounded-lg"
        >
          + Add more
        </div>
      </div>
    </div>
  );
};
const RoomAndHotelFacilities = ({ data, handleChange, index }) => {
  const isIpad = useIsIpad();
  const valid = true;
  return (
    <div className="md:h-[90%]">
      <div className="w-[100%] max-h-[800px] h-full md:max-h-full overflow-y-scroll mx-auto">
        <div className="mb-4 text-lg font-semibold border-b">
          Hotel Facility
        </div>
        <div className="md:h-full max-h-[400px] h-full md:max-h-full overflow-y-scroll">
          {data.hotelFacility?.map((hotel, hotelIndex) => {
            return (
              <div
                className={`grid ${isIpad ? "grid-cols-3" : "xs:grid-cols-1 lg:grid-cols-4 xl:grid-cols-4 grid-cols-2"} mb-2`}
              >
                <div className="flex gap-2">
                  <div className="mb-4">
                    <div className="text-sm">Service Name</div>
                    <div className="text-sm mt-1">
                      <input
                        disabled
                        value={hotel?.serviceName}
                        onChange={(e) =>
                          handleChange(
                            "hotelFacility.serviceName",
                            e.target.value,
                            index,
                            hotelIndex
                          )
                        }
                        className="px-2 h-8 xs:w-[100%] disabled:bg-gray-200 text-sm text-black rounded-md focus:outline-none"
                        placeholder="Enter Service Name"
                      />
                    </div>
                  </div>
                  <div className="flex items-center gap-2 justify-center">
                    <div
                      onClick={() => {
                        handleChange(
                          "hotelFacility.service",
                          true,
                          index,
                          hotelIndex
                        );
                      }}
                      className={`p-2 cursor-pointer ${hotel?.service && "bg-green-500"}`}
                    >
                      <FaCheck />
                    </div>
                    <div
                      onClick={() => {
                        handleChange(
                          "hotelFacility.service",
                          false,
                          index,
                          hotelIndex
                        );
                      }}
                      className={`p-2 cursor-pointer ${!hotel?.service && "bg-red-500"} `}
                    >
                      <RxCross2 />
                    </div>
                  </div>
                </div>
                <div className="mb-4">
                  <div className="text-sm">Charges</div>
                  <div className="text-sm mt-1">
                    <input
                      value={hotel?.charges}
                      disabled={!hotel?.service}
                      type="number"
                      onChange={(e) =>
                        handleChange(
                          "hotelFacility.charges",
                          e.target.value,
                          index,
                          hotelIndex
                        )
                      }
                      className="px-2 xs:w-[100%] h-8 text-sm text-black rounded-md focus:outline-none"
                      placeholder="Enter Charges"
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
const FNB_Details = ({
  data,
  handleChange,
  addRestaurant,
  deleteRestaurant,
  index,
}) => {
  const isIpad = useIsIpad();
  return (
    <div>
      <div className="w-[100%] max-h-[550px] overflow-y-scroll mx-auto">
        <div className="mb-4 text-lg font-semibold border-b">
          Restraunt Details
        </div>
        <div className="">
          {data?.restaurantDetails?.map((res, objIndex) => {
            return (
              <div
                className={`grid xs:gap-4 ${isIpad ? "grid-cols-3" : "xs:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 grid-cols-2"}  my-2 ${data?.restaurantDetails.length === index + 1 ? "" : "border-b"}`}
              >
                <div className="mb-4">
                  <div className="text-sm">
                    Restraunt Name {`#${objIndex + 1}`}
                  </div>
                  <div className="text-sm mt-1">
                    <input
                      value={res?.name}
                      onChange={(e) =>
                        handleChange(
                          "restaurantDetails.name",
                          e.target.value,
                          objIndex,
                          index
                        )
                      }
                      className="px-2 xs:w-[100%] h-8 text-sm text-black rounded-md focus:outline-none"
                      placeholder="Enter Restraunt Name"
                    />
                  </div>
                </div>
                <div className="mb-4">
                  <div className="text-sm">Cover</div>
                  <div className="text-sm mt-1">
                    <input
                      value={res?.cover}
                      onChange={(e) =>
                        handleChange(
                          "restaurantDetails.cover",
                          e.target.value,
                          objIndex,
                          index
                        )
                      }
                      className="px-2 xs:w-[100%] h-8 text-sm text-black rounded-md focus:outline-none"
                      placeholder="Enter Cover"
                    />
                  </div>
                </div>
                <div className="mb-4 xs:col-span-1 col-span-2">
                  <div className="text-sm">Breakfast Timing</div>
                  <div className="text-sm items-center flex gap-2 mt-1">
                    From
                    <input
                      value={res?.breakfastStart}
                      onChange={(e) =>
                        handleChange(
                          "restaurantDetails.breakfastStart",
                          e.target.value,
                          objIndex,
                          index
                        )
                      }
                      className="px-2 xs:w-[100%] h-8 text-sm text-black rounded-md focus:outline-none"
                      placeholder="Enter Breakfast Start Timing"
                      type="time"
                    />
                    To
                    <input
                      value={res?.breakfastEnd}
                      onChange={(e) =>
                        handleChange(
                          "restaurantDetails.breakfastEnd",
                          e.target.value,
                          objIndex,
                          index
                        )
                      }
                      className="px-2 xs:w-[100%] h-8 text-sm text-black rounded-md focus:outline-none"
                      placeholder="Enter Breakfast End Timing"
                      type="time"
                    />
                  </div>
                </div>
                <div className="mb-4 xs:col-span-1 col-span-2">
                  <div className="text-sm">Lunch Timing</div>
                  <div className="text-sm items-center flex gap-2 mt-1">
                    From
                    <input
                      value={res?.lunchStart}
                      onChange={(e) =>
                        handleChange(
                          "restaurantDetails.lunchStart",
                          e.target.value,
                          objIndex,
                          index
                        )
                      }
                      className="px-2 xs:w-[100%] h-8 text-sm text-black rounded-md focus:outline-none"
                      placeholder="Enter Lunch Start Timing"
                      type="time"
                    />
                    To
                    <input
                      value={res?.lunchEnd}
                      onChange={(e) =>
                        handleChange(
                          "restaurantDetails.lunchEnd",
                          e.target.value,
                          objIndex,
                          index
                        )
                      }
                      className="px-2 xs:w-[100%] h-8 text-sm text-black rounded-md focus:outline-none"
                      placeholder="Enter Lunch End Timing"
                      type="time"
                    />
                  </div>
                </div>
                <div className="mb-4 xs:col-span-1 col-spa">
                  <div className="text-sm">Dinner Timing</div>
                  <div className="text-sm items-center flex gap-2 mt-1">
                    From
                    <input
                      value={res?.dinnerStart}
                      onChange={(e) =>
                        handleChange(
                          "restaurantDetails.dinnerStart",
                          e.target.value,
                          objIndex,
                          index
                        )
                      }
                      className="px-2 xs:w-[100%] h-8 text-sm text-black rounded-md focus:outline-none"
                      placeholder="Enter Dinner Start Timing"
                      type="time"
                    />
                    To
                    <input
                      value={res?.dinnerEnd}
                      onChange={(e) =>
                        handleChange(
                          "restaurantDetails.dinnerEnd",
                          e.target.value,
                          objIndex,
                          index
                        )
                      }
                      className="px-2 xs:w-[100%] h-8 text-sm text-black rounded-md focus:outline-none"
                      placeholder="Enter Dinner End Timing"
                      type="time"
                    />
                  </div>
                </div>
                <div className="mb-4">
                  <div className="text-sm">Menu Type</div>
                  <div className="text-sm mt-1">
                    <input
                      value={res?.menuType}
                      onChange={(e) =>
                        handleChange(
                          "restaurantDetails.menuType",
                          e.target.value,
                          objIndex,
                          index
                        )
                      }
                      className="px-2 xs:w-[100%] h-8 text-sm text-black rounded-md focus:outline-none"
                      placeholder="Enter Menu Type"
                    />
                  </div>
                </div>
                <div
                  className="flex items-center mb-2 w-fit bg-red-100 px-4 py-1 rounded-lg text-red-500 text-sm h-fit mt-[26px] cursor-pointer"
                  onClick={() => deleteRestaurant(index, objIndex)}
                >
                  Delete
                </div>
              </div>
            );
          })}
          <div
            onClick={() => addRestaurant(index)}
            className="w-fit cursor-pointer bg-blue-100 text-sm hover:text-white hover:bg-blue-500 transition-all ease-in-out duration-150 text-blue-500 px-4 py-1 rounded-lg"
          >
            + Add more
          </div>
        </div>
      </div>
    </div>
  );
};

const Banquet = ({ data, handleChange, addBanquet, deleteBanquet, index }) => {
  return (
    <div>
      <div className="w-[100%] max-h-[550px] overflow-y-scroll mx-auto">
        <div className="mb-4 text-lg font-semibold border-b">
          Banquet Details
        </div>
        <div>
          {data?.banquet?.map((item, objIndex) => {
            return (
              <div className="flex items-center gap-4">
                <div className="mb-4">
                  <div className="text-sm">Hall Name {`#${objIndex + 1}`}</div>
                  <div className="text-sm mt-1">
                    <input
                      value={item?.hallName}
                      onChange={(e) =>
                        handleChange(
                          "banquet.hallName",
                          e.target.value,
                          index,
                          objIndex
                        )
                      }
                      className="px-2 xs:w-[100%] h-8 text-sm text-black rounded-md focus:outline-none"
                      placeholder="Enter Hall Name"
                    />
                  </div>
                </div>
                <div className="mb-4">
                  <div className="text-sm">PAX</div>
                  <div className="text-sm mt-1">
                    <input
                      value={item?.pax}
                      onChange={(e) =>
                        handleChange(
                          "banquet.pax",
                          e.target.value,
                          index,
                          objIndex
                        )
                      }
                      className="px-2 xs:w-[100%] h-8 text-sm text-black rounded-md focus:outline-none"
                      placeholder="Enter PAX"
                      type="number"
                    />
                  </div>
                </div>
                <div
                  className="w-fit px-4 py-1 mt-2 cursor-pointer bg-red-100 text-red-500 hover:bg-red-500 hover:text-red-100 transition-all ease-in-out duration-150 rounded-lg text-sm"
                  onClick={() => deleteBanquet(index, objIndex)}
                >
                  Delete
                </div>
              </div>
            );
          })}
          <div
            onClick={() => addBanquet(index)}
            className="w-fit  cursor-pointer relative bg-blue-100 text-sm hover:text-white hover:bg-blue-500 transition-all ease-in-out duration-150 text-blue-500 px-4 py-1 rounded-lg"
          >
            + Add more
          </div>
        </div>
      </div>
    </div>
  );
};
