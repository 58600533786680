import React from "react";
import { Tooltip } from "react-tooltip";

const TaskTab = ({
  dataArray,
  setTaskScreen,
  isLoading,
  showActions = false,
  setSelectedTask,
  isTask = true,
}) => {
  const keys = dataArray && dataArray[0] && Object.keys(dataArray[0]);

  return (
    <div className="bg-component-background w-[100%] h-[100%] rounded-lg overflow-hidden">
      <div className="h-full  overflow-y-auto">
      <div className="bg-component-background">
        <div className="w-full sticky top-0 bg-blue-400 z-10">
          {/* Make header sticky */}
          <div className="h-20 flex justify-between text-darkThemeFontColor bg items-center w-full text-sm leading-none ">
            {keys?.includes("subTaskId") && (
              <div className="font-normal flex justify-center min-w-32">
                Sub Task Id
              </div>
            )}
            {keys?.includes("taskId") && (
              <div className="font-normal flex justify-center min-w-32">
                Task Id
              </div>
            )}
            {keys?.includes("title") && (
              <div className="font-normal flex justify-center min-w-32">
                Task name
              </div>
            )}
            {keys?.includes("subTask") && (
              <div className="font-normal flex justify-center min-w-32">
                Sub Task
              </div>
            )}
                {keys?.includes("owner") && (
              <div className="font-normal flex justify-center min-w-32">
                Owner
              </div>
            )}
            {keys?.includes("assignedBy") && (
              <div className="font-normal flex justify-center min-w-32">
                assigned By
              </div>
            )}
            {keys?.includes("expectedTime") && (
              <div className="font-normal flex justify-center min-w-32">
                Expected Time
              </div>
            )}
            {keys?.includes("totalTime") && (
              <div className="font-normal flex justify-center min-w-32">
                Total Time
              </div>
            )}
            {keys?.includes("action") && (
              <div className="font-normal flex justify-center min-w-32">
                Action
              </div>
            )}
            {keys?.includes("status") && (
              <div className="font-normal flex justify-center min-w-32">
                Status
              </div>
            )}
          </div>
        </div>
         </div>
        <div className="w-[100%] flex flex-col justify-between">
          {dataArray &&
            dataArray?.map((item, index) => {
            //   if (isTask && !item?.isSubTask) {
            //     return;
            //   }
              return (
                <div
                  key={index}
                  className="h-20 text-sm flex justify-between items-center w-full leading-none text-textColor border-b border-t border-gray-200 bg-component-background"
                >
                  {item?.subTaskId && (
                    <div className="w-32 flex items-center justify-center truncate">
                      <button className="font-semibold">
                        {item?.subTaskId}
                      </button>
                    </div>
                  )}
                  {item?.taskId && (
                    <div className="w-32 flex items-center justify-center truncate">
                      <button className="font-semibold">{item?.taskId}</button>
                    </div>
                  )}
                  {item?.title && (
                    <div
                      onClick={() => {
                        setTaskScreen((prev) => prev + 1);
                        setSelectedTask(item?.taskId); //
                      }}
                      className="w-32 flex cursor-pointer items-center justify-center"
                    >
                      <div
                        className="font-semibold"
                        data-tooltip-id={item?.title}
                        data-tooltip-content={item?.title}
                      >
                        {item?.title || '--'}
                      </div>
                      <Tooltip
                        id={item?.title}
                        style={{
                          borderRadius: "10px",
                          maxWidth: "350px",
                          fontSize: "12px",
                          whiteSpace: "normal",
                          wordWrap: "break-word",
                          overflow: "hidden",
                          zIndex: 20,
                        }}
                      />
                    </div>
                  )}
                  {item?.subTask && (
                    <div
                      onClick={() => {
                        setTaskScreen((prev) => prev + 1);
                      }}
                      className="w-32 flex cursor-pointer items-center justify-center"
                    >
                      <div
                        className="font-semibold"
                        data-tooltip-id={item?.subTask}
                        data-tooltip-content={item?.subTask}
                      >
                        {item?.subTask}
                      </div>
                      <Tooltip
                        id={item?.subTask}
                        style={{
                          borderRadius: "10px",
                          maxWidth: "350px",
                          fontSize: "12px",
                          whiteSpace: "normal",
                          wordWrap: "break-word",
                          overflow: "hidden",
                          zIndex: 20,
                        }}
                      />
                    </div>
                  )}
                   {( keys?.includes("owner") )&& (
                    <div className="w-32 flex items-center justify-center">
                    
                      <div className="font-semibold">{item?.owner}</div>
                    </div>
                  )}
                  {item?.assignedBy && (
                    <div className="w-32 flex items-center justify-center">
                      {/* <img
                      src={item.assigneeImage}
                      alt="Assignee"
                      className="w-8 h-8 rounded-full mr-2"
                    /> */}
                      <div className="font-semibold">{item?.assignedBy}</div>
                    </div>
                  )}
                  {item?.expectedTime && (
                    <div className="w-32 flex-wrap flex items-center truncate justify-center">
                      {item?.expectedTime}
                    </div>
                  )}
                  {keys?.includes("totalTime") && (
                    <div className="w-32 flex justify-center">
                      {item?.totalTime}
                    </div>
                  )}
                  {item?.action && (
                    <div className="w-32 flex items-center justify-center">
                      <a href="#" className=" hover:underline">
                        {item?.action}
                      </a>
                    </div>
                  )}
                  {item?.status && (
                    <div className="w-32 flex items-center justify-center">
                      <span className="text-[#77A980] font-semibold">
                        {item?.status}
                      </span>
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default TaskTab;
