import React from 'react';

const ProgressBar = ({ progress=50 }) => {
  return (
    // <div className='relative w-full h-1 bg-black  rounded-md  border-gray-300'>
    //   <div className='absolute top-0 left-0 h-full bg-[#ABD2FF] rounded-md' style={{ width: `${progress}%` }}></div>
    // </div>
    <div className="relative w-full h-1 bg-black rounded-md border-gray-300">
    <div
      className={`absolute top-0 left-0 h-full rounded-md ${
        progress >= 0 ? 'bg-[#ABD2FF]' : 'bg-red-500'
      }`}
      style={{ width: `${Math.abs(progress)}%` }}
    ></div>
  </div>

  );
}

export default ProgressBar;
