import React, { useEffect, useRef, useState } from "react";
import Skeleton from "../Skeleton";
import dataNotFound from "../../assets/icons/404/1.svg";
import { useNavigate } from "react-router-dom";
import getToken from "../../utils/getToken";
import { Tooltip } from "react-tooltip";
import { useSwitchToRatexQuery } from "../../redux/slices/onBoarding";
import { formatCurrency } from "../../utils/FormatCurrency";
import PersonalProgress from "../ApexGraphComponent/PersonalProgress";
import ProgressBar from "../ProgressBar/progressBar";
import { toast } from "react-toastify";
import { BsPlus, BsThreeDotsVertical } from "react-icons/bs";
import {
  useAssiginPropertyTaskMutation,
  useUnAssignPropertyMutation,
} from "../../redux/slices/dashboard";
import { format } from "date-fns";
import OtherUserComponent from "./OtherUserComponent";

import Select from "react-select";
import switchToRatex from "../../utils/SwitchToRatex";

export const DataNotFound = () => {
  return (
    <div className="w-[98%] m-4 flex bg-[#7c8ba130]  items-center justify-start  h-auto p-4  border-2 border-[#7C8BA1] rounded-lg  ">
      <img src={dataNotFound} className="h-60 w-60" alt="" />
      <div className="w-full flex justify-center">
        <p className="text-xl font-medium">No recent Activities</p>
      </div>
    </div>
  );
};
const UniversalTable = ({
  dataArray,
  isLoading,
  showActions = false,
  showOnlyEmail,
  handleunAssign,
  handleFunction,
  isSwitch,
  isEmp,
  refetch,
}) => {
  const [assiginPropertyTask, { isLoading: isLoadingAssigining }] =
    useAssiginPropertyTaskMutation();
  const [objToAssign, setObjToAssign] = useState();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [duplicateData, setDuplicateData] = useState();

  const [unAssignProperty] = useUnAssignPropertyMutation();
  const { data: switchToRate } = useSwitchToRatexQuery();
  const navigate = useNavigate();
const userId =localStorage.getItem('userId')
  const [otherUsersearchText, setOtherUserSearchText] = useState("");
  const keys = dataArray && dataArray[0] && Object.keys(dataArray[0]);

  const [showMoreDropdown, setShowMoreDropdown] = useState(false);

  useEffect(() => {
    if (dataArray) {
      setDuplicateData(dataArray);
    }
  }, [dataArray]);

  if (isLoading) {
    return (
      <div className="h-[200px]">
        <Skeleton />
      </div>
    );
  }
  if (!dataArray || !keys) {
    return <DataNotFound />;
  }
  const token = getToken();

  // const handleNavigate = (hId) => {
  //   const token = switchToRate?.data?.data?.token;
  //   if (token) {
  //     window.location.href = `http://localhost:3000/ratex/token:${token}/hId:${hId}`;
  //   } else {
  //     console.error("Token not received from switchToRate");
  //   }
  // };

  const handleNavigate = (hId) => {
    const token = switchToRate?.data?.data?.token;
    if (token) {
      window.open(
        `https://ratex.retvenslabs.com/ratex/token:${token}/hId:${hId}`,
        "_blank"
      );
    } else {
      toast.error("Token not received from switch to rate.", {
        position: "bottom-right",
      });
    }
  };

  const handleAssignToggle = (propertyIndex, userIndex, hId) => {
    setDuplicateData((prevState) => {
      const updatedState = prevState?.map((property, pIdx) => {
        if (pIdx === propertyIndex) {
          return {
            ...property,
            otherUser: property?.otherUser?.map((user, uIdx) =>
              uIdx === userIndex
                ? { ...user, isAssigned: !user?.isAssigned }
                : user
            ),
          };
        }
        return property;
      });

      // Filter the updated property based on hId using updatedState
      const filterPropArray = updatedState?.filter((item) => {
        return parseInt(item?.hId) === parseInt(hId);
      });


      setObjToAssign({
        hId: hId,
        otherUser: filterPropArray && filterPropArray[0]?.otherUser,
      });

      // Return the updated state to setDuplicateData
      return updatedState;
    });
  };

  const handleAssignSave = () => {
    assiginPropertyTask(objToAssign)
      .unwrap()
      .then((res) => {
        toast.success("Assigining task updated successfully!", {
          position: "bottom-right",
        });
        setSelectedIndex(null);
        refetch();
      })
      .catch((err) => {
        toast.error("Something went wrong!", { position: "bottom-right" });
        setSelectedIndex(null);
      });
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent", // Make the background transparent
      border: "1px solid transparent",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid transparent", // Change border color on hover
      },
      color: "transparent",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "rgba(255, 255, 255, 0.9)", // Optional: set a semi-transparent background for the dropdown menu
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "transparent", // Set the placeholder color to white
      fontSize: "12px",
    }),
  };

  return (
    <div className="bg-component-background mt-2 w-[100%]  rounded-lg overflow-hidden">
      <div className=" overflow-y-auto">
        <div className="w-full  sticky top-0  z-10">
          {" "}
          {/* Make header sticky */}
          <div className="bg-component-background">
            <div className="h-20 flex justify-between items-center w-full bg-component-background text-white text-sm leading-none ">
              {keys?.includes("name") && (
                <th className="font-normal flex justify-center min-w-32">
                  Name
                </th>
              )}
              {keys?.includes("clientName") && (
                <th className="font-normal flex justify-center min-w-32">
                  Name
                </th>
              )}
              {keys?.includes("otaName") && (
                <th className="font-normal flex justify-center min-w-32">
                  OTA Name
                </th>
              )}
              {keys?.includes("employeeName") && (
                <th className="font-normal flex justify-center min-w-32">
                  Employee
                </th>
              )}
                {keys?.includes("propertyName") && (
                <th className="font-normal flex justify-center min-w-32">
                  Name
                </th>
              )}
              {keys?.includes("isActive") && (
                <th className="font-normal flex justify-center min-w-32">
                  Status
                </th>
              )}
              {keys?.includes("budget") && (
                <th className="font-normal flex justify-center min-w-32">
                  Budget
                </th>
              )}
              {keys?.includes("totalProperty") && (
                <th className="font-normal flex justify-center min-w-32">
                  Hotels
                </th>
              )}
            
              {keys?.includes("otherUser") && (
                <th className="font-normal flex justify-center min-w-32">
                  Assignee
                </th>
              )}
              {keys?.includes("revenue") && (
                <th className="font-normal flex justify-center w-32">
                  Revenue
                </th>
              )}
              {keys?.includes("overallOutcome") && (
                <th className="font-normal flex justify-center w-32">
                  Hotel Performance
                </th>
              )}
               {keys?.includes("revenuePerChange") && (
                <th className="font-normal flex justify-center w-32">
                  Revenue Change Percentage
                </th>
              )}

              {keys?.includes("completion") && (
                <th className="font-normal flex justify-center min-w-32">
                  Completion
                </th>
              )}
              {keys?.includes("fullName") && (
                <th className="font-normal flex justify-center min-w-32">
                  Full Name
                </th>
              )}

              {keys.includes("link") && (
                <div className="w-32 flex gap-2 items-center justify-start ">
                  Link
                </div>
              )}
              {keys?.includes("designation") && (
                <th className="font-normal flex justify-center min-w-32">
                  Role
                </th>
              )}
              {keys?.includes("roleInProperty") && (
                <th className="font-normal flex justify-center min-w-32">
                  Role In Property
                </th>
              )}

              {keys?.includes("hotelAssigned") && (
                <th className="font-normal flex justify-center min-w-32">
                  Hotel Assigned
                </th>
              )}

              {keys?.includes("occupancy") && (
                <th className="font-normal flex justify-center min-w-32">
                  Occupancy
                </th>
              )}
              {keys?.includes("ADR") && (
                <th className="font-normal flex justify-center min-w-32">
                  ADR
                </th>
              )}

              {keys?.includes("email") && (
                <th className="font-normal flex justify-center min-w-32">
                  Email
                </th>
              )}

              {keys?.includes("status") && (
                <th className="font-normal flex justify-center min-w-32">
                  Status
                </th>
              )}
              {keys?.includes("isOnboarded") && (
                <th className="font-normal flex justify-center min-w-32">
                  Is Onboarded
                </th>
              )}
              {keys?.includes("type") && (
                <th className="font-normal flex justify-center min-w-32">
                  Type
                </th>
              )}

              {/* {keys?.includes("revenue") && (
                <th className="font-normal flex justify-center min-w-32">
                  Revenue by OTA
                </th>
              )} */}
              {keys?.includes("category") && (
                <th className="font-normal flex justify-center min-w-32">
                  Category{" "}
                </th>
              )}
              {keys?.includes("companyId") && (
                <th className="font-normal flex justify-center min-w-32">
                  Company Id
                </th>
              )}
              {keys?.includes("lastModified") && (
                <th className="font-normal flex justify-center min-w-32">
                  last Modified{" "}
                </th>
              )}
              {keys?.includes("lastModifiedBy") && (
                <th className="font-normal flex justify-center min-w-32">
                  last Modified By
                </th>
              )}
              {keys?.includes("lastSeen") && (
                <th className="font-normal flex justify-center min-w-32">
                  lastSeen
                </th>
              )}

              {showActions && (
                <th className={`font-normal text-center ${showActions=='clientele' || showActions== "assignedTo" ?'w-40':'min-w-32'} `}>Action</th>
              )}
            </div>
          </div>
        </div>
        <div className="w-[100%]  flex flex-col justify-between">
          {duplicateData &&
            duplicateData?.map((item, index) => {
              return (
                <div
                  key={index}
                  className="h-20 text-sm flex justify-between items-center w-full leading-none text-textColor border-b border-t border-gray-200  bg-component-background hover:px-4 "
                >
                  {keys.includes("name") && (
                    <div className="w-32 flex items-center justify-start truncate">
                              <Tooltip
                        id={item?.name}
                        style={{
                          borderRadius: "10px",
                          maxWidth: "350px",
                          fontSize: "12px",
                          whiteSpace: "normal",
                          wordWrap: "break-word",
                          overflow: "hidden",
                          zIndex: 20,
                        }}
                      />
                      <div data-tooltip-id={item?.name}
                          data-tooltip-content={item?.name} className="font-semibold ">{item?.name}</div>
                    </div>
                  )}
                  {keys.includes("otaName") && (
                    <div className="w-32 flex gap-2 items-center justify-start ">
                      <div className="h-8 w-8 rounded-full">
                        {" "}
                        <img
                          src={item?.otaImage}
                          className="object-fit border  h-full w-full rounded-full"
                        />
                      </div>{" "}
                      {item?.otaName}
                    </div>
                  )}
                  {keys.includes("clientName") && (
                    <div className="w-32 flex gap-2 items-center justify-start ">
                      {/* <div className="h-8 w-8 rounded-full overflow-hidden">
                      <img
                        src={item?.profileImg}
                        alt="Profile"
                        className="object-cover h-full w-full"
                        onError={(e) => {
                          e.target.onerror = null; // Prevents infinite loop if the dummy image fails
                          e.target.src =
                            "https://cdn.pixabay.com/photo/2021/08/30/22/33/warning-6587278_1280.png"; // Fallback URL when the original fails
                        }}
                      />

                    </div> */}
                      {item?.clientName}
                    </div>
                  )}
                  {keys.includes("employeeName") && (
                    <div className="w-36 relative  flex  gap-2 items-center justify-between ">
                      <Tooltip
                        id={item?.employeeEmail}
                        style={{
                          borderRadius: "10px",
                          maxWidth: "350px",
                          fontSize: "12px",
                          whiteSpace: "normal",
                          wordWrap: "break-word",
                          overflow: "hidden",
                          zIndex: 20,
                        }}
                      />
                      <div className="h-8 w-8 rounded-full overflow-hidden">
                        <img
                          src={
                            item?.profileImg ||
                            "https://e7.pngegg.com/pngimages/178/595/png-clipart-user-profile-computer-icons-login-user-avatars-monochrome-black.png"
                          }
                          alt="Profile"
                          className="object-cover h-full w-full"
                          onError={(e) => {
                            e.target.onerror = null; // Prevents infinite loop if the dummy image fails
                            e.target.src =
                              "https://e7.pngegg.com/pngimages/178/595/png-clipart-user-profile-computer-icons-login-user-avatars-monochrome-black.png"; // Fallback URL when the original fails
                          }}
                        />
                      </div>

                      <div className=" w-[70%] right-0">
                        <h1 className="truncate">{item?.employeeName}</h1>
                        <h1
                          data-tooltip-id={item?.employeeEmail}
                          data-tooltip-content={item?.employeeEmail}
                          className="truncate"
                        >
                          {item?.employeeEmail}
                        </h1>
                      </div>
                    </div>
                  )}
                    {keys.includes("propertyName") && (
                    <div className="w-32 flex items-center truncate  justify-start">
                      <div className="flex flex-col items-center w-full">
                        <p
                          className="font-bold text-center truncate w-full max-w-[150px] overflow-hidden whitespace-nowrap text-ellipsis"
                          data-tooltip-id={item?.propertyName}
                          data-tooltip-content={item?.propertyName}
                        >
                          {item?.propertyName}
                        </p>
                        <p className="text-xs">{item?.propertyType}</p>
                      </div>
                      <Tooltip
                        id={item?.propertyName}
                        style={{
                          borderRadius: "10px",
                          maxWidth: "350px",
                          fontSize: "12px",
                          whiteSpace: "normal",
                          wordWrap: "break-word",
                          overflow: "hidden",
                          zIndex: 20,
                        }}
                      />
                    </div>
                  )}
                  {keys.includes("isActive") && (
                    <div className="w-32 flex  items-center justify-center">
                      <button className=" ">
                        {item?.isActive ? "Active" : "In Active"}
                      </button>
                    </div>
                  )}
                  {keys.includes("budget") && (
                    <div className="w-32 flex  items-center justify-center">
                      <button className=" ">
                        {formatCurrency(item?.budget)}
                      </button>
                    </div>
                  )}
                  {keys.includes("totalProperty") && (
                    <div className="w-32 flex  items-center justify-center">
                      <button className=" ">
                        {item?.totalProperty}
                      </button>
                    </div>
                  )}
                
                  {keys?.includes("otherUser") && (
                    <OtherUserComponent
                      index={index}
                      setOtherUserSearchText={setOtherUserSearchText}
                      item={item}
                      handleAssignSave={handleAssignSave}
                      isLoadingAssigining={isLoadingAssigining}
                      setSelectedIndex={setSelectedIndex}
                      otherUsersearchText={otherUsersearchText}
                      selectedIndex={selectedIndex}
                      data={item?.otherUser}
                      handleAssignToggle={handleAssignToggle}
                    />
                  )}
                  {keys?.includes("revenue") && (
                    <div className="w-36 flex justify-center">
                      <ValueDisplay
                        amount={formatCurrency(item?.revenue)}
                        percentage={item?.revenueChangePercentage}
                      />
                    </div>
                  )}
                  {keys.includes("overallOutcome") && (
                    <div className="w-32 flex flex-col items-center justify-center">
                      <h1 className="mb-2">
                        {`${(item?.overallOutcome && item?.overallOutcome !=='N/A' )? `${item?.overallOutcome}%` : "N/A"}`}{" "}
                      </h1>
                      <ProgressBar progress={item?.overallOutcome} />
                    </div>
                  )}
                   {keys.includes("revenuePerChange") && (
                    <div className="w-32 flex flex-col items-center justify-center">
                      <h1 className="mb-2">
                        {`${(item?.revenuePerChange && item?.revenuePerChange !=='N/A' )? `${item?.revenuePerChange}%` : "N/A"}`}{" "}
                      </h1>
                      <ProgressBar progress={item?.revenuePerChange} />
                    </div>
                  )}
                  {keys.includes("completion") && (
                    <div className="w-32 flex  items-center justify-center">
                      <button className=" ">
                        {item?.completion}
                      </button>
                    </div>
                  )}
                  {keys.includes("link") && (
                    <div className="w-32 flex gap-2 items-center justify-start ">
                      <a href={item?.link} target="_blank">
                        {" "}
                        Link
                      </a>
                    </div>
                  )}
                  {(item?.fullName || item?.fullName == "") && (
                    <div className="w-32 flex gap-2 items-center justify-start ">
                      <div className="h-8 w-8 rounded-full">
                        {" "}
                        <img
                          src={item?.profileImg}
                          className="object-fit border  h-full w-full rounded-full"
                        />
                      </div>{" "}
                      {item?.fullName}
                    </div>
                  )}
                  {keys.includes("employeeN") && (
                    <div className="w-32 flex gap-2 items-center justify-start ">
                      <div className="h-8 w-8 rounded-full">
                        {" "}
                        <img
                          src={item?.profileImg}
                          className="object-fit border  h-full w-full rounded-full"
                        />
                      </div>{" "}
                      {item?.fullName}
                    </div>
                  )}
                  {keys.includes("designation") && (
                    <div className="w-32 flex  items-center justify-center">
                      <button className=" ">
                        {item?.designation}
                      </button>
                    </div>
                  )}
                  {keys.includes("roleInProperty") && (
                    <div className="w-32 flex  items-center justify-center">
                      <button className=" ">
                        {item?.roleInProperty || "-"}
                      </button>
                    </div>
                  )}
                  {(item?.hotelAssigned == 0 || item?.hotelAssigned) && (
                    <div className="w-32 flex items-center justify-center">
                      <div className="">{item?.hotelAssigned}</div>
                    </div>
                  )}
                  {(item?.occupancy === 0 ||
                    item?.occupancy ||
                    keys?.includes("occupancy")) && ( // Fixed the syntax error
                    <div className="w-32 flex items-center justify-center">
                      <ValueDisplay
                        amount={item?.occupancy}
                        percentage={item?.occupancyChangePercentage}
                        isOccupency={true}
                      />
                    </div>
                  )}
                  {keys?.includes("ADR") && (
                    <div className="w-32 flex items-center justify-center ">
                      {item?.ADR}
                    </div>
                  )}{" "}
                  {keys?.includes("email") && (
                    <div className="w-32 flex items-center truncate  justify-start">
                      <div className="flex flex-col items-start w-full">
                        {!showOnlyEmail && (
                          <p
                            className=" text-center truncate w-full max-w-[150px] overflow-hidden whitespace-nowrap text-ellipsis"
                            data-tooltip-id={item?.email}
                            data-tooltip-content={item?.email}
                          >
                            {item?.fullName}
                          </p>
                        )}
                        <p data-tooltip-id={item?.email}
                            data-tooltip-content={item?.email} className="text-xs">{item?.email}</p>
                      </div>
                      <Tooltip
                        id={item?.email}
                        style={{
                          borderRadius: "10px",
                          maxWidth: "350px",
                          fontSize: "12px",
                          whiteSpace: "normal",
                          wordWrap: "break-word",
                          overflow: "hidden",
                          zIndex: 20,
                        }}
                      />
                    </div>
                  )}
                  {keys?.includes("status") && (
                    <div className="w-32  flex items-center justify-center">
                      <button className=" hover:underline">
                        {item?.status}
                      </button>
                    </div>
                  )}{" "}
                  {keys?.includes("isOnboarded") && (
                    <div className="w-32  flex items-center justify-center">
                      <button className=" hover:underline">
                        {item?.isOnboarded ? "Onboarded" : "Not Onboarded"}
                      </button>
                    </div>
                  )}
                  {keys?.includes("type") && (
                    <div className="w-32 flex items-center justify-center">
                      <div className="">{item?.type}</div>
                    </div>
                  )}
                  {/* {keys?.includes("revenue") && (
                  <div className="w-36 flex justify-center">
                    <ValueDisplay
                      amount={formatCurrency(item?.revenue)}
                      percentage={item?.revenueChangePercentage}
                    />
                  </div>
                )} */}
                  {keys?.includes("category") && (
                    <div className="w-32 flex items-center justify-center">
                      <div className="">
                        {item?.category || "--"}
                      </div>
                    </div>
                  )}
                  {keys?.includes("companyId") && (
                    <div className="w-32 flex items-center justify-center">
                      <div className="">{item?.companyId}</div>
                    </div>
                  )}
                  {/* {item?.lastModified && (
                    <div className="w-32 flex items-center justify-center">
                      <div className="font-semibold">
                        {format(new Date(item?.lastModified), "dd-MMM-yyyy")}
                      </div>
                    </div>
                  )} */}
                  {/* {item?.lastModifiedBy && (
                    <div className="w-32 flex items-center justify-center">
                      <div className="font-semibold">
                        {item?.lastModifiedBy}
                      </div>
                    </div>
                  )} */}
                  {keys?.includes("lastSeen") && (
                    <div className="w-32 flex items-center justify-center">
                      <div className=" w-full text-center">
                        {item?.lastSeen}
                      </div>
                    </div>
                  )}
                  {showActions === "client" && (
                    <div className="w-32 flex flex-col items-center justify-center cursor-pointer ">
                      <div
                        // onClick={()=>{navigate(`/client/${item?.hotelCode}`)}}
                        onClick={() => handleNavigate(item?.hId)}
                        className=" hover:underline"
                      >
                        Visit
                      </div>
                    </div>
                  )}
                  {/* {item?.companyId && (
                    <div className="w-32 flex items-center justify-center">
                      <div className="font-semibold">{item?.companyId}</div>
                    </div>
                  )} */}
                  {keys?.includes("lastModified") && (
                    <div className="w-32 flex items-center justify-center">
                      <div className="">
                        {format(new Date(item?.lastModified), "dd-MMM-yyyy")}
                      </div>
                    </div>
                  )}
                  {keys?.includes("lastModifiedBy")   && (
                    <div className="w-32 flex items-center justify-center">
                      <div className="">
                        {item?.lastModifiedBy}
                      </div>
                    </div>
                  )}
                  {showActions === "client" && (
                    <div className="w-32 flex flex-col items-center justify-center cursor-pointer ">
                      <div
                        // onClick={()=>{navigate(`/client/${item?.hotelCode}`)}}
                        onClick={() => handleNavigate(item?.hId)}
                        className=" hover:underline"
                      >
                        Visit
                      </div>
                      <div
                        onClick={() => {
                          navigate(`/client/${item?.hId}`);
                        }}
                        className=" mt-2 hover:underline"
                      >
                        View
                      </div>
                    </div>
                  )}
                  {showActions === "user" && (
                    <div className="w-32 flex flex-col items-center justify-center cursor-pointer ">
                      <div
                        onClick={() => {
                          navigate(`/user/${item?.userId}`);
                        }}
                        className="hover:underline"
                      >
                        Visit
                      </div>
                      <div className="hover:underline">Deactivate</div>
                    </div>
                  )}
                  {showActions === "clientele" && (
                    <div className="relative w-40 flex flex-col items-center justify-center cursor-pointer ">
                      <Select
                        onChange={(x) => {
                          if (x?.value === "Visit") {
                            navigate(`/client/${item?.clientId}`);
                          } else if(x?.value === 'Deactivate') {
                            handleFunction(item?.clientId,'Deactivate')
                          }else if(x?.value === 'Activate'){
                            handleFunction(item?.clientId,'Activate')
                          }else if(x?.value === 'Delete'){
                            handleFunction(item?.clientId,'Delete')
                          }else if(x?.value === 'View_WA_Detail'){
                            handleFunction(item?.clientId,'View_WA_Detail')

                          }else if(x?.value === 'Create_WA_Group'){
                            handleFunction(item?.clientId,'Create_WA_Group')

                          }else if(x?.value === 'send_onboarding_link'){
                            handleFunction(item?.clientId,'send_onboarding_link')

                          }else if(x?.value === 'view_onboarding_link'){
                            handleFunction(item?.clientId,'view_onboarding_link')

                          }
                        }}
                        components={{
                          Indicator: () => (
                            <div className="custom-indicator">
                              <BsThreeDotsVertical className="check-icon" />
                            </div>
                          ),
                        }}
                        // components={{
                        //   Option: CustomOption,
                        // }}
                        maxMenuHeight={200}
                        className="w-[90%]"
                        menuPortalTarget={document.querySelector("body")}
                        options={[
                          { value: "Visit", label: "Visit" },
                        (item?.isActive ?   { value: "Deactivate", label: "Deactivate" }:{ value: "Activate", label: "Activate" }),
                        
                        {value:"Delete",label:'Delete'},
                        (item?.isWhatsAppGroupCreated ? { value: "View_WA_Detail", label: "View WA Detail" }:         {value:'Create_WA_Group', label:'Create WA Group'}),
                        {value:"view_onboarding_link",label:'View onb. link'},
               
                        ].filter(Boolean)}
                        styles={customStyles}
                      />
                    </div>
                  )}
                  {showActions === "employee" && (
                    <div className="w-32 flex flex-col items-center justify-center cursor-pointer ">
                      <div className="hover:underline">Visit</div>
                      <div className="hover:underline">Deactivate</div>
                    </div>
                  )}
                  {showActions === "properties" && (
                    <div className="w-32 flex flex-col items-center justify-center cursor-pointer ">
                      <div className="relative w-32 flex flex-col items-center justify-center cursor-pointer ">
                      <Select
  onChange={(x) => {
    if (x?.value === "Visit") {
      navigate(`/properties/${item.hId}`);
    } else if (x?.value === 'Delete') {
      handleFunction(item?.hId,'Delete');
    } else if(x?.value ==='Activate'){
      handleFunction(item?.hId,'Activate');
    } else if(x?.value === 'Deactivate'){
      handleFunction(item?.hId,'Deactivate');
    }
      else if (x?.value === 'Visit to Ratex') {
      switchToRatex(item?.hId, 'settings');
    }
  }}
  components={{
    Indicator: () => (
      <div className="custom-indicator">
        <BsThreeDotsVertical className="check-icon" />
      </div>
    ),
    Input: () => null, // This will hide the input field
  }}
  maxMenuHeight={200}
  className="w-[90%]"
  menuPortalTarget={document.querySelector("body")}
  options={[
    (item?.isOnboarded === true || item?.isOnboarded === undefined) && { value: "Visit", label: "View" },
    { value: "Visit to Ratex", label: "Visit to RateX" },
     { value: "Delete", label: "Delete" },
    // {value:'Delete', label:'Delete'}
    (item?.isActive=== true ? {value:'Deactivate', label:'Deactivate'} :{value:'Activate' ,label:'Activate'})


  ].filter(Boolean)}  // This will filter out `false` or `undefined` entries
  styles={customStyles}
/>

                      </div>
                    </div>
                  )}
                  {showActions === "employeeOverview" && (
                    <div
                      onClick={() => { if(item.userId==userId){navigate(`/profilePage`)}else{navigate(`/employeeOverview/${item?.userId	}`)}}}
                      className="w-32 flex flex-col items-center justify-center cursor-pointer "
                    >
                      <div className="hover:underline">View</div>
                    </div>
                  )}
                  {showActions === "compset" && (
                    <div className="w-32 flex flex-col items-center justify-center cursor-pointer ">
                      <div
                        onClick={() => handleFunction(item?.taskTypeId)}
                        className="hover:underline"
                      >
                        Edit
                      </div>
                    </div>
                  )}
                  {showActions === "assignedTo" && (
                    <div className="w-32 flex flex-col items-center justify-center cursor-pointer ">
                      <div
                        onClick={() => {
                          handleunAssign(item);
                        }}
                        className="hover:underline"
                      >
                        Unassign
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};
const ValueDisplay = ({ amount, percentage, isOccupency }) => {
  const isPositive = percentage > 0;
  return (
    <div className="flex items-center justify-center text-xs w-full gap-2">
      <p className={``}>{isOccupency ? `${amount}%` : amount}</p>
      {percentage && (
        <p className={isPositive ? "text-green-500" : "text-red-500"}>
          {isPositive ? `↑ ${percentage}%` : `↓ ${Math.abs(percentage)}%`}
        </p>
      )}
    </div>
  );
};

export default UniversalTable;
