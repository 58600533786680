import React, { useState } from "react";
import { usePatchTaskStatusMutation, usePostImageMutation, useRemmoveImageMutation } from "../redux/slices/canabel";
import { toast } from "react-toastify";
import { FaRegFileLines } from "react-icons/fa6";
import UniversalLoader from "./Loader/UniversalLoader";
import { useSwitchToRatexQuery } from "../redux/slices/onBoarding";

const TaskCompletionComponent = ({
    subTaskData,
    handleOK,
    handleClose,
    setSuggestion,
    suggestion,
    setChallanges,
    challanges,
    setKeyInsights,
    keyInsights,
    taskId
}) => {
    const [imageURL, setImageURL] = useState([])
    const [postImage ,{ isLoading: postingImages }] = usePostImageMutation();
    const [removeImage] = useRemmoveImageMutation();
    const [patchTaskStatus,{isLoading:patchTaskStatusLoading}] = usePatchTaskStatusMutation();
    const { data: switchToRate } = useSwitchToRatexQuery();
    const handleRemoveImage = (taskId, attachmentId) => {
        removeImage({ taskId, attachmentId })
            .unwrap()
            .then((res) => {
                setImageURL((prevImages) =>
                    prevImages.filter((img) => img.attachmentId !== attachmentId)
                ); // Assuming setImageURL is your state updater
                toast.success(res?.message, { position: "bottom-right" });
            })
            .catch((err) => {
                toast.error(err?.data?.message, { position: "bottom-right" });
            });
    };
    function formatDueTime(hours) {
        if (hours < 1) {
            // Convert to minutes
            const minutes = Math.round(hours * 60);
            return `${minutes} minute${minutes !== 1 ? 's' : ''}`;
        } else {
            // Return hours
            return `${hours} hour${hours !== 1 ? 's' : ''}`;
        }
    }

    const handleNavigate = (reportLink, hId=790755) => {
        const token = switchToRate?.data?.data?.token;
        if (token) {
            // window.open(
            //   `https://ratex.retvenslabs.com/ratex/token:${token}/hId:${parseInt(hId)}/report:${reportLink}`,
            //   "_blank"
            // );
            window.open(
                `http://localhost:3001/ratex/token:${token}/hId:${parseInt(hId)||790755}/report:${reportLink}`,
                "_blank"
            );
        } else {
            toast.error("Token not received from switch to rate.", {
                position: "bottom-right",
            });
        }
    };

    return (
        <div className="p-4">
            {/* Header */}
            <div className="flex justify-between items-center  mb-4">
                <h2 className="font-semibold text-xl">Task Completion Summary</h2>
                {/* <button className="text-sm underline">Mark as Completed</button> */}
            </div>
            <UniversalLoader loading={postingImages ||patchTaskStatusLoading} />

            {/* Task Details */}
            <div className=" w-full flex flex-wrap rounded-md p-4 mb-4">
                <div className="mb-2 w-1/2">
                    <strong>For Task:</strong>{" "}
                    <a href="#" className="underline">
                        Update Pricing Strategy
                    </a>
                </div>
                <div className="mb-2  w-1/2">
                    <strong>Priority:</strong> {subTaskData?.priority}
                </div>
                <div className="mb-2  w-1/2">
                    <strong>Due Time:</strong> {formatDueTime(subTaskData?.dueTime)}
                </div>
                <div className="mb-2  w-1/2">
                    <strong>Status:</strong> Teams in spaces
                </div>
                <div className="mb-2  w-1/2">
                    <strong>Created By:</strong> Auto Generated
                </div>
                <div>
                    <strong>Type:</strong>
                    <div className="flex flex-wrap gap-2">
                        {subTaskData?.type?.map((item, index) => (
                            <div
                                key={index}
                                className="bg-orange-400 rounded-lg px-2"
                            >
                                {item}
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Actions Section */}
            <div className="bg-lightThemeGradient dark:bg-darkThemeGradient  rounded-md p-4 mb-4">
                <h3 className="font-semibold mb-2">
                    Doublecheck all actions performed
                </h3>
                <ul>
                                        {subTaskData?.checkUpRedirect?.map((item)=>(<li className="flex justify-between items-center">
                                            <span>• Check {item?.name}</span>
                                            <div
                                                onClick={() =>
                                                    handleNavigate(item?.subStr,subTaskData?.hId)
                                                }
                                                className="text-white cursor-pointer"
                                            >
                                                Check here
                                            </div>
                                        </li>))}
                                     
                                    </ul>
            </div>

            {/* Key Insights */}
            <div className="mb-4">
                <label className="block mb-1">Key Insights</label>
                <input
                    type="text"
                    value={keyInsights}
                    onChange={(e) => setKeyInsights(e.target.value)}
                    placeholder="Enter any key findings or insights from this task..."
                    className="w-full placeholder:text-stone-500 p-2 bg-blue-300 text-white rounded-md"
                />
            </div>

            {/* Challenges Faced */}
            <div className="mb-4">
                <label className="block mb-1">Challenges Faced</label>
                <input
                    type="text"
                    value={challanges}
                    onChange={(e) => setChallanges(e.target.value)}
                    placeholder="Enter any difficulties or obstacles encountered..."
                    className="w-full p-2  placeholder:text-stone-500 bg-blue-300 text-white rounded-md"
                />
            </div>

            {/* Suggestions for Next Steps */}
            <div className="mb-4">
                <label className="block mb-1">Suggestions for Next Steps</label>
                <input
                    type="text"
                    value={suggestion}
                    onChange={(e) => setSuggestion(e.target.value)}
                    placeholder="Enter any suggested actions or strategies based on your findings..."
                    className="w-full  placeholder:text-stone-500 p-2 bg-blue-300 text-white rounded-md"
                />
            </div>

            {/* Attachments Section */}
            {/* <div className="mb-6">
                <h1 className="font-semibold">Attachment</h1>
                <div className="flex  gap-4">

                    {imageURL &&
                        imageURL.map((item) => (
                            <div className="relative w-24 h-24 rounded-full  border">
                                {item?.docType?.includes("image") ? (
                                    <>
                                        <div
                                            onClick={() =>
                                                handleRemoveImage(taskId, item?.attachmentId)
                                            }
                                            className="absolute cursor-pointer -top-2 bg-red-600 right-0 px-2 rounded-lg "
                                        >
                                            x
                                        </div>
                                        <img
                                            className="w-full h-full object-cover rounded-full"
                                            src={
                                                item?.doc ||
                                                "https://cdn.pixabay.com/photo/2021/08/30/22/33/warning-6587278_1280.png"
                                            } // Default to a dummy URL if the src is missing
                                            alt="Uploaded"
                                            onError={(e) => {
                                                e.target.onerror = null; // Prevents infinite loop if the dummy image fails
                                                e.target.src =
                                                    "https://cdn.pixabay.com/photo/2021/08/30/22/33/warning-6587278_1280.png"; // Fallback URL when the original fails
                                            }}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <div className="absolute -top-2 right-0 px-2 py-1 bg-red-600 text-white rounded-lg cursor-pointer">
                                            x
                                        </div>
                                        <div className="w-full h-full flex items-center justify-center">
                                            <FaRegFileLines size={40} className="text-gray-500" />
                                        </div>
                                    </>
                                )}
                            </div>
                        ))}
                    <div className="w-24 h-24 rounded-full overflow-hidden">
                        <label className="w-full h-full bg-gray-800 flex items-center justify-center cursor-pointer">
                            <div className="flex items-center justify-center w-full h-full text-white">
                                Upload
                            </div>

                            <input
                                type="file"
                                onChange={(e) => {
                                    const file = e.target.files[0];
                                    const reader = new FileReader();
                                    reader.onload = () => reader.readAsDataURL(file);
                                    console.log(file);
                                    const formData = new FormData();
                                    formData.append("taskAttachment", file);
                                    formData.append("docType", file.type);
                                    postImage({
                                        taskAttachment: file,
                                        docType: file.type,
                                        // taskId: taskId,
                                    })
                                        .unwrap()
                                        .then((res) => {
                                            patchTaskStatus({
                                                "attachments": [
                                                    { "attachmentId": res?.data?.attachmentId, "docType": res?.data?.docType, "doc": res?.data?.doc }

                                                ],
                                                taskId: taskId,
                                            });
                                            setImageURL((prev) => [
                                                ...prev,
                                                {
                                                    docType: res?.data?.docType || 'hello',
                                                    doc: res?.data?.doc,
                                                    attachmentId: res?.data?.attachmentId,
                                                },
                                            ]);
                                        })
                                        .catch((err) => {
                                            toast.error(err?.data?.message, {
                                                position: "bottom-right",
                                            });
                                        });
                                }}
                                accept="*"
                                className="hidden"
                            />
                        </label>
                    </div>
                </div>

            </div> */}

            {/* Footer Buttons */}
            <div className="flex justify-between">
                <button
                    onClick={handleClose}
                    className="bg-gray-300 text-blue-700 px-4 py-2 rounded-md"
                >
                    Skip for Now
                </button>
                <button onClick={handleOK} className="bg-blue-600 px-4 py-2 rounded-md">
                    Submit & Next Task
                </button>
            </div>
        </div>
    );
};

export default TaskCompletionComponent;
