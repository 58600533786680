import React, { useState } from "react";
import { useGetTeamAcitivyQuery } from "../redux/slices/dashboard";
import Skeleton from "./Skeleton";
import NegativeScreen from "./NegativeScreen";

const TeamActivity = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const { data: teamActivityData, isLoading } = useGetTeamAcitivyQuery({
    pageNumber: pageNumber,
  });

  const handlePrevious = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const handleNext = () => {
    if (pageNumber < teamActivityData?.data?.totalCount) {
      setPageNumber(pageNumber + 1);
    }
  };
  const totalPages = teamActivityData?.data?.totalPages;
  return (
    <div className="bg-lightThemeGradient relative min-h-[500px] dark:bg-darkThemeGradient p-4 rounded-lg">
      <h1>Team Activity</h1>


      <div className="h-[600px] overflow-y-auto ">
      {!isLoading
        ? teamActivityData?.data?.activityHistoryDetail?.length >0? teamActivityData?.data?.activityHistoryDetail?.map((item, index) => (
            <div
              key={index}
              className="bg-gradient-to-r from-blue-500 to-blue-600 p-4 rounded-md shadow-md text-white flex items-start space-x-4 my-2"
            >
              <div className="flex flex-col items-center">
                {/* Circle for the timeline */}
                <div className="w-3 h-3 bg-green-400 rounded-full mt-1.5"></div>
                {/* Dotted line for timeline */}
                <div className="h-12 w-0.5 bg-green-400 mt-1"></div>{" "}
                {/* Adjust height here */}
              </div>
              <div className="flex-1">
                {/* Main notification text */}
                <div>{item?.activity}</div>
                {/* Date and time */}
                <div className="text-sm text-gray-300 mt-2">{item?.time}</div>
              </div>
            </div>
          )):<div className="w-[98%] flex items-center h-[80%] p-4 justify-center"> <NegativeScreen /> </div>
        : Array(6)
            .fill("")
            .map((_, i) => (
              <div key={i} className="h-12 w-full mb-3">
                <Skeleton />
              </div>
            ))}
</div>
      <div className="w-full flex items-center justify-center mt-8">
        <div className="flex justify-center items-center space-x-4 ">
          <button
            onClick={handlePrevious}
            className={`px-4 py-2 text-white rounded ${
              pageNumber === 1
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-blue-500 hover:bg-blue-700"
            }`}
            disabled={pageNumber === 1}
          >
            Previous
          </button>

          <span className="text-lg text-white dark:text-black font-medium">
            Page {pageNumber} of {totalPages}
          </span>

          <button
            onClick={handleNext}
            className={`px-4 py-2 text-white rounded ${
              pageNumber === totalPages
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-blue-500 hover:bg-blue-700"
            }`}
            disabled={pageNumber === totalPages}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default TeamActivity;
