import React from 'react'
import dataNotFound from '../assets/icons/404/3.svg' 
const NegativeScreen = () => {
  return (
    <div className="w-full flex flex-col bg-[#7c8ba130]  items-center justify-center  h-[99%]    border-2 border-[#7C8BA1] rounded-lg ">
<img src={dataNotFound} className="h-60 w-60" alt="" />
<div className="w-full flex justify-center">
  <p className="text-xl font-medium">No recent Activities</p>
</div>
</div>

  )
}

export default NegativeScreen   

