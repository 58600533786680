import getAccessToken from "../../../utils/getAccessToken";
import getToken from "../../../utils/getToken";
import { emptySplitApi } from "../../injectEndpoint";

export const Finance = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        addNewCustomer: builder.mutation({
            query: (data) => ({
                url: `/finance/addNewCustomer`,
                method: "POST",
                headers: {
                    Authorization: getToken(),
                },
                body: data,
            }),
            providesTags: ["addNewCustomer"],
        }),
        addClientTransaction: builder.mutation({
            query: (data) => {
                const formData = new FormData();
                formData.append("clientId", data?.clientId);
                formData.append("transactionId", data?.transactionId);
                formData.append("clientTransactionImage", data?.img);
                formData.append("leadId", data?.leadId);

                return {
                    url: `/finance/addClientTransaction`,
                    method: "POST",
                    headers: {
                        Authorization: getToken(),
                    },
                    body: formData,
                };
            },
            providesTags: ["addClientTransaction"],
        }),
        getClientTransaction: builder.query({
            query: (data) => ({
                url: `/finance/getClientTransaction`,
                method: "GET",
                headers: {
                    Authorization: getToken(),
                },
                params: data
            }),
            providesTags: ["getClientTransaction"],
        }),
        editClientTransaction: builder.mutation({
            query: (data) => {
                const formData = new FormData();

                formData.append('leadId', data?.leadId)

                formData.append("transactionId", data?.transactionId);


                formData.append("clientTransactionImage", data?.transactionImage);



                return {
                    url: `/finance/editClientTransaction`,
                    method: "PATCH",
                    headers: {
                        Authorization: getToken(),
                    },
                    body: formData,
                };
            },
            providesTags: ["editClientTransaction"],
        })

    }),
});

export const { useAddNewCustomerMutation, useAddClientTransactionMutation, useGetClientTransactionQuery, useEditClientTransactionMutation } =
    Finance;
