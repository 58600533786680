import React from "react";
import { useNavigate } from "react-router-dom";
import { useIsIpad } from "../../utils/isIpad";
import ActivityManager from "../../component/ApexGraphComponent/ActivityManager/ActivityManager";
import SingleChainProperty from "../../component/ApexGraphComponent/SingleChainProperty";
import ChannelPerformance from "../../component/ApexGraphComponent/ChannelPerformance";
import TopFiveClients from "../../component/ApexGraphComponent/TopFiveClients";
import CriticalClients from "../../component/ApexGraphComponent/CriticalClients";
import RevenueGrowth from "../../component/ApexGraphComponent/RevenueGrowth";
import ClientByStarCategory from "../../component/ApexGraphComponent/ClientByStarCategory";
import OnboardingFunnel from "../../component/ApexGraphComponent/OnboardingFunnel";
import ClientWrapper from "../../component/WrapperComponents/ClientWrapper";
import Reminder from "../../component/Dashboard/Reminder";
import UpComingMettings from "../../component/Dashboard/upComingMettings";
import EmployeeOverview from "../../component/ApexGraphComponent/EmployeeOverview";
import PersonalProgress from "../../component/ApexGraphComponent/PersonalProgress";
import TeamActivity from "../../component/TeamActivity";
import UpComingTask from "../../component/Dashboard/UpComingTask";
import HotelPerformanceSummery from "../../component/ApexGraphComponent/HotelPerformanceSummery";
import {
    useDistressClientQuery,
    useGetClientCROQuery,
    useGetPerformanceDashboardQuery,
} from "../../redux/slices/dashboard";
import UpcomingRETask from "../../component/Dashboard/UpcomingRETask";
import YourActivityCard from "../../component/Dashboard/YourActivity";

const RevenueExecutivePerformanceDashboard = () => {
    const isIpad = useIsIpad();
    const {data:DistressClientData}=useDistressClientQuery()
    const { data: clientCro } = useGetPerformanceDashboardQuery();
    function getGreeting() {
        const now = new Date();
        const hour = now.getHours();

        if (hour >= 5 && hour < 12) {
            return "Good morning";
        } else if (hour >= 12 && hour < 17) {
            return "Good afternoon";
        } else if (hour >= 17 && hour < 20) {
            return "Good evening";
        } else {
            return "Good night";
        }
    }
    const navigate = useNavigate();

    return (
        <div className="p-4 mx-auto  mt-10  w-[90%] h-auto">
            <div className="flex justify-center  text-lightThemeFontColor dark:text-darkThemeFontColor flex-col p-4 items-center">
                <h2>
                    {new Date().toLocaleDateString("en-US", {
                        weekday: "long",
                        month: "long",
                        day: "numeric",
                    })}
                </h2>
                <h1 className="font-semibold  text-2xl">{getGreeting()}, Partner!</h1>
            </div>
            <div className="flex w-full justify-between gap-4">
                <div  onClick={() => navigate('/properties/distressClient', {
            state: {
              heading: 'Distress Client',
            },
          })}  className="w-1/5 cursor-pointer">
                    <ClientWrapper

                        name={"Distress Client"}
                        value={DistressClientData?.data?.userDetail.length}
                    />
                </div>
                <div  onClick={() => navigate('/task-management')}  className="w-1/5 cursor-pointer">
                    <ClientWrapper
                        name={"Pending Task"}
                        value={clientCro?.data?.pendingTask}
                    />
                </div>
                <div onClick={() => navigate('/task-management')}  className="w-1/5 cursor-pointer">
                    <ClientWrapper
                        name={"Assigned Task"}
                        value={clientCro?.data?.assignedTask}
                    />
                </div>
                {/* <div className="w-1/5 ">
          <ClientWrapper  name={'Current Onboarding'} value={clientCro?.data?.currentOnboarding} />
        </div> */}
            </div>
            <div className="flex my-4 h-[500px] gap-2 ">
                <div className="w-[51%] flex gap-2">
                    <div className=" h-full w-1/2">
                        <UpcomingRETask />
                    </div>
                    <div className="w-1/2">
                        <Reminder />
                    </div>
                </div>
                <div className="w-[49%] ">
                    <YourActivityCard />
                </div>
            </div>
            <div
                className={` ${isIpad ? "w-[100%] my-2" : "w-[51%] "}  my-4 h-[400px]`}
            >
                <PersonalProgress />
            </div>
            <div className="w-[51%] my-2">
                <HotelPerformanceSummery />
            </div>
            {/* <div
        className={`${isIpad ? "w-[49%] my-2" : "w-[51%] "}  min-h-[300px] `}
      >
        <EmployeeOverview />
      </div> */}
            {/* <div className="w-1/2">
        <UpComingTask />
      </div>
  */}
            {/* <div className="flex justify-between flex-wrap  w-full">
        <div
          className={`${isIpad ? "w-[100%]" : "w-[48%]"} min-h-[300px] bg-component-background `}
        >
          <ActivityManager />
        </div>
        <div
          className={` ${isIpad ? "w-[49%] my-2" : "w-[25%] "} min-h-[300px] `}
        >
          <SingleChainProperty />
        </div>
        <div
          className={`${isIpad ? "w-[49%] my-2" : "w-[25%] "}  min-h-[300px] `}
        >
          <ChannelPerformance />
        </div>

        <div
          className={`${isIpad ? "w-[100%] my-2" : "w-[48%] "}  mt-4 h-[400px]`}
        >
          <TopFiveClients />
        </div>
        <div
          className={` ${isIpad ? "w-[100%] my-2" : "w-[51%] "}   mt-4 h-[400px] `}
        >
          <CriticalClients />
        </div>
        <div
          className={` ${isIpad ? "w-[100%] my-2" : "w-[48%] "}  my-4 h-[400px]`}
        >
          <RevenueGrowth />
        </div>
     
        <div
          className={` ${isIpad ? "w-[49%] my-2" : "w-[25%] "} w-[25%] my-4 h-[400px]`}
        >
          <ClientByStarCategory />
        </div>
        <div
          className={` ${isIpad ? "w-[49%] my-2" : "w-[25%]"}  my-4 h-[400px]`}
        >
          <OnboardingFunnel />
        </div>
      </div> */}
        </div>
    );
};

export default RevenueExecutivePerformanceDashboard;
