import React, { useState } from "react";
import { CiFilter, CiGrid42 } from "react-icons/ci";
import { FaListUl } from "react-icons/fa";
import { useNavigate } from "react-router";
import NormalDropDown from "../component/DropDown/NormalDropDown";
import { IoIosSearch } from "react-icons/io";
import { BiSort } from "react-icons/bi";
import NotificationCondition from "../component/Notification/NotificationCondition";
import { useEditNotificationMutation, useEditNotificationQuery, useGetNotificationQuery } from "../redux/slices/dashboard";
import { toast } from "react-toastify";
import dataNotFound from "../assets/icons/404/3.svg";

function timeAgo(date) {
  const now = new Date();
  const seconds = Math.floor((now - new Date(date)) / 1000);

  let interval = Math.floor(seconds / 31536000); // Years
  if (interval > 1) return `${interval} years ago`;
  interval = Math.floor(seconds / 2592000); // Months
  if (interval > 1) return `${interval} months ago`;
  interval = Math.floor(seconds / 86400); // Days
  if (interval > 1) return `${interval} days ago`;
  interval = Math.floor(seconds / 3600); // Hours
  if (interval > 1) return `${interval} hours ago`;
  interval = Math.floor(seconds / 60); // Minutes
  if (interval > 1) return `${interval} minutes ago`;
  return `${seconds} seconds ago`;
}

const Notifications = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const navigate = useNavigate();
  const [editNotification]=useEditNotificationMutation();
  const [showFilter,setShowFilter]=useState(false)
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [unRead,setUnread]=useState(false)
  const {
    data: getNofication,
    isLoading,
    isFetching,
    refetch
  } = useGetNotificationQuery({unRead:unRead, type:selectedFilter});


  const handlePrevious = () => {
    if (pageNumber > 1) {
      setPageNumber((prev)=>prev - 1);
    }
  };

  const handleNext = () => {
    if (pageNumber < getNofication?.data?.totalCount) {
      setPageNumber((prev)=>prev + 1);
    }
  };

  const handleMarkAsRead =(noti)=>{
    editNotification({notificationId:noti?.notificationId}).unwrap().then((res)=>{
        toast.success("Notifications marked as read", { position: "bottom-right" });
        refetch();
    }).catch((err)=>{
        toast.error(err?.message, { position: "bottom-right" });
    })
  }
  const handleMarkAllAsRead = () => {
    editNotification({ markAsRead: true })
      .unwrap()
      .then((res) => {
        toast.success("All notifications marked as read.", { position: "bottom-right" });
        refetch()
      })
      .catch((err) => {
        toast.error("Failed to mark all as read.", { position: "bottom-right" });
      });
  };
  const totalPages = getNofication?.totalPages;
  return (
    <div className="flex w-[85%] mx-auto h-screen flex-col p-4">
      <div className=" w-full h-16 flex mt-20 items-center justify-between border-y-2 dark:border-white border-black">
        <div className="left flex gap-2 items-center">
          <button
            className={`flex px-2 py-1 text-sm cursor-pointer items-center text-black   dark:text-darkThemeFontColor  ${selectedFilter === "All" && "bg-white text-black  dark:text-lightThemeFontColor rounded-lg shadow-xl"} gap-2  rounded-lg`}
            onClick={() => {
              setSelectedFilter("All");
            }}
          >
            <CiGrid42 /> All
          </button>

          <div
            onClick={() => {
              setSelectedFilter("Client");
            }}
            className={`flex p-2 text-sm cursor-pointer items-center gap-2 text-lightThemeFontColor   dark:text-darkThemeFontColor  ${selectedFilter === "Client" && "bg-white  rounded-lg dark:text-lightThemeFontColor text-black shadow-xl"}`}
          >
            Client
          </div>
          <div
            onClick={() => {
              setSelectedFilter("Tasks");
            }}
            className={`flex p-2 text-sm cursor-pointer items-center gap-2 text-lightThemeFontColor    dark:text-darkThemeFontColor ${selectedFilter === "Tasks" && "bg-white rounded-lg  dark:text-lightThemeFontColor  text-black shadow-xl"}`}
          >
            Tasks
          </div>
          <div
            onClick={() => {
              setSelectedFilter("Accounts");
            }}
            className={`flex p-2 text-sm cursor-pointer items-center gap-2 text-lightThemeFontColor  dark:text-darkThemeFontColor ${selectedFilter === "Accounts" && "bg-white rounded-lg dark:text-lightThemeFontColor text-black shadow-xl"}`}
          >
            Accounts
          </div>
          <div className="flex  border-l-2 p-2 text-sm items-center gap-2 text-lightThemeFontColor dark:text-darkThemeFontColor">
            Total Notification : {getNofication?.data?.length}
          </div>
        </div>
        <div className="right  flex gap-2 items-center">
          <div className="flex p-2 items-center gap-2  text-lightThemeFontColor dark:text-darkThemeFontColor">
            <input
              type="checkbox"
              placeholder="Mark all as read"
              className="focus:outline-none  text-sm bg-transparent dark:placeholder:text-white"
              onChange={handleMarkAllAsRead} // Mark all as read when checked
            />
            Mark All As Read
          </div>
        

          <div onClick={()=>{setShowFilter((prev)=>!prev)}} className=" cursor-pointer relative flex p-2 text-sm h-fit items-center filter dark:invert-0 invert  text-white gap-2  rounded-lg">
            <CiFilter />
            Filter{" "}
          {showFilter && <div className="absolute top-10  rounded-lg   bg-white text-black">
            <h1 onClick={()=>{setUnread((prev)=>!prev)}} className={`w-full cursor-pointer  ${unRead ? 'bg-blue-500' :' bg-white '}  rounded-lg px-2  hover:bg-blue-500`}>Unread</h1>
            </div>}
          </div>
        </div>
      </div>
      <div className="overflow-y-auto h-[80vh]">
        {getNofication?.data?.length>0
          ? getNofication?.data?.map((noti) => {
              return (
                <div className="bg-lightThemeGradient dark:bg-darkThemeGradient mt-2 p-4 rounded-lg shadow-lg text-white w-full   mx-auto">
                  <div className="flex justify-between items-center">
                    {/* Profile Picture */}
                    <div className="flex items-center">
                      <img
                        src="https://via.placeholder.com/40" // Replace with actual image source
                        alt="Profile"
                        className="rounded-full w-10 h-10 mr-4"
                      />
                      <div>
                        <h4 className="font-semibold">
                          {noti?.notificationText}
                        </h4>
                        <div className="flex items-center">
                          <div className="text-sm text-gray-300">
                            {noti?.date ? timeAgo(noti?.date) : "N/A"}
                          </div>
                          <ul className="flex space-x-4 list-disc list-inside">
                            <li className="text-sm px-2 py-1 rounded">
                              {noti?.propertyName}
                            </li>
                            <li className="text-sm px-2 py-1 rounded">
                              {noti?.taskDetail?.priority}
                            </li>
                            <li className="text-sm px-2 py-1 rounded">
                              {noti?.notificationType}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    {/* Task Labels */}

                   {!noti?.markAsRead	&& <div className="relative group inline-flex items-center group-hover:border-2   rounded-full">
                      {/* Circle */}
                      <div className="bg-green-400 w-4 h-4 rounded-full  transition-transform duration-300 ease-in-out  "></div>

                      {/* Text (Hidden initially, revealed on hover) */}
                      <span onClick={()=>handleMarkAsRead(noti)} className="ml-2 opacity-0 cursor-pointer group-hover:opacity-100 group-hover:block hidden transition-opacity delay-300 duration-300 ease-in-out">
                        Mark as read
                      </span>
                    </div>}
                  </div>

                  {/* Task Details */}
                  <NotificationCondition noti={noti} condition={noti?.notificationType	} />
                </div>
              );
            })
          :        <div className="w-full flex flex-col bg-[#7c8ba130]  items-center justify-center  h-[99%]  p-4  border-2 border-[#7C8BA1] rounded-lg m-2 ">
          <img src={dataNotFound} className="h-60 w-60" alt="" />
          <div className="w-full flex justify-center">
            <p className="text-xl font-medium">No recent Activities</p>
          </div>
        </div>}
      </div>
      <div className="w-full  flex items-center justify-center">
      { totalPages>1 && <div className="flex absolute bottom-2 justify-center items-center space-x-4 mt-6">
          <button
            onClick={handlePrevious}
            className={`px-4 py-2 text-white rounded ${
              pageNumber === 1
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-blue-500 hover:bg-blue-700"
            }`}
            disabled={pageNumber === 1}
          >
            Previous
          </button>

          <span className="text-lg text-white dark:text-black font-medium">
            Page {pageNumber} of {totalPages}
          </span>

          <button
            onClick={handleNext}
            className={`px-4 py-2 text-white rounded ${
              pageNumber === totalPages
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-blue-500 hover:bg-blue-700"
            }`}
            disabled={pageNumber === totalPages}
          >
            Next
          </button>
        </div>}
      </div>
    </div>
  );
};

export default Notifications;
