
// import { emptySplitApi } from "../../injectEndpoint";
import { get } from "react-hook-form";
import getToken from "../../utils/getToken";
import { emptySplitApi } from "../injectEndpoint";

export const Canabel = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({

    getTaskFilter: builder.query({
      query: () => ({
        url: `task/taskFilter`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
      }),
      invalidatesTags: ["getTaskFilter"],
    }),
    getTask: builder.query({
        query: (data) => ({
          url: `task/task`,
          method: "GET",
          headers: {
            Authorization: getToken(),
          },
          params:data,
          
        }),
        invalidatesTags: ["getTask"],
      }),
      getTaskDetail: builder.query({
        query: (data) => ({
          url: `task/getTaskDetail`,
          method: "GET",
          params:data,
          headers: {
            Authorization: getToken(),
          },
        }),
        invalidatesTags: ["getTaskDetail"],
      }),
      getUtils: builder.query({
        query: () => ({
          url: `utils/utils`,
          method: "GET",
          headers: {
            Authorization: getToken(),
          },
        }),
        invalidatesTags: ["getUtils"],
      }),
      getTaskType: builder.query({
        query: () => ({
          url: `task/getTaskType`,
          method: "GET",
          headers: {
            Authorization: getToken(),
          },
        }),
        invalidatesTags: ["getTaskType"],
      }),
      postTaskType: builder.mutation({
        query: (data) => ({
          url: `task/addTaskType`,
          method: "POST",
          headers: {
            Authorization: getToken(),
          },
          body:data
        }),
        invalidatesTags: ["postTaskType"],
      }),
      postImage: builder.mutation({
        query: (data) => {
          const formData = new FormData();
          formData.append('taskAttachment', data.taskAttachment);
          formData.append('docType', data.docType);
      
          return {
            url: `task/addImages`,
            method: "PATCH",
            headers: {
              Authorization: getToken(), // Assuming getToken() returns the token string
            },
            body: formData,
            params:{taskId:data.taskId}
          };
        },
        invalidatesTags: ["postImage"],
      }),
      patchTaskStatus: builder.mutation({
        query: (data) => {
          
          return {
            url: `task/Task`,
            method: "PATCH",
            headers: {
              Authorization: getToken(), // Assuming getToken() returns the token string
            },
            body: data,
            params:{taskId:data?.taskId}
          };
        },
        invalidatesTags: ["patchTaskStatus"],
      }),
      remmoveImage:builder.mutation({
        query: (data) => ({
          url: `task/deleteImage`,
          method: "DELETE",
          headers: {
            Authorization: getToken(), // Assuming getToken() returns the token string
          },
          params: data
        }),
        invalidatesTags: ["remmoveImage"],
      }),
      addComment:builder.mutation({
        query: (data) => ({
          url: `task/addComment`,
          method: "POST",
          headers: {
            Authorization: getToken(), // Assuming getToken() returns the token string
          },
          params:{ taskId:data?.taskId},
          body:data,
        }),
        invalidatesTags: ["addComment"],
      }),
      getTaskTypeDetail:builder.query({
        query:(data)=>({
          url:`task/getTaskTypeDetail`,
          method:'GET',
          headers:{
            Authorization:getToken()
          },
          params:data,
          
        }),
        invalidatesTags:['getTaskTypeDetail']
      }),
      editTaskTypeDetail:builder.mutation({
        query:(data)=>({
          url:'task/editTaskType',
          method:'PATCH',
          headers:{
            Authorization:getToken()
          },
          params:{taskTypeId:data?.taskTypeId},
          body:data
        }),
        invalidatesTags:['editTaskTypeDetail']
      }),
      getParentTask:builder.query({
        query: () => ({
          url: `utils/parentTaskList`,
          method: "GET",
          headers: {
            Authorization: getToken(),
          },
        }),
        invalidatesTags: ["getParentTask"],
      }),
      deleteTask :builder.mutation({
        query: (data) => ({
          url: `task/deleteTask`,
          method: "PATCH",
          headers: {
            Authorization: getToken(),
          },
          params:data,
        }),
        invalidatesTags: ["deleteTask"],
      })
      

}),
    overrideExisting: false, // Add
  });
  export const {useGetTaskFilterQuery ,useGetTaskQuery, useGetTaskDetailQuery ,useGetUtilsQuery,useGetTaskTypeQuery ,usePostTaskTypeMutation,usePostImageMutation,usePatchTaskStatusMutation,useRemmoveImageMutation ,useAddCommentMutation,useGetTaskTypeDetailQuery,useEditTaskTypeDetailMutation,useGetParentTaskQuery,useDeleteTaskMutation} =
  Canabel; // Corrected export
  
